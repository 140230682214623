import React, { useContext } from "react";
import { Radar } from "react-chartjs-2";
import { StoreContext } from "../store/store";
export default function Spider(props) {
  const store = useContext(StoreContext);

  const data1 = {
    labels: [
      "Senior Leadership Commitment",
      "Governance Oversight",
      "Management and Responsibilities",
      "Privacy Officer",
      "Privacy Strategy",
      "Accountability",
    ],
    datasets: [
      {
        label: "Current State Assessment",
        data: [
          store.CS1A < 10 ? parseFloat(store.CS1A) : 0,
          // store.bp1A < 10 ? parseFloat(store.bp1A) : 0,
          // store.tr1A < 10 ? parseFloat(store.tr1A) : 0,
          store.CS2A < 10 ? parseFloat(store.CS2A) : 0,
          store.CS3A < 10 ? parseFloat(store.CS3A) : 0,
          store.CS4A < 10 ? parseFloat(store.CS4A) : 0,
          store.CS5A < 10 ? parseFloat(store.CS5A) : 0,
          store.CS6A < 10 ? parseFloat(store.CS6A) : 0,
        ],
        fill: true,
        backgroundColor: "rgba(3,171,87, 0.2)",
        borderColor: "rgb(3,171,87)", //"black",
        pointBackgroundColor: "rgb(3,171,87)",
        pointBorderColor: "rgb(3,171,87)", //"#fff",
        pointHoverBackgroundColor: "rgb(3,171,87)", //"#fff",
        pointHoverBorderColor: "rgb(3,171,87)",
      },
      {
        label: "Better Practice",
        data: [
          //  store.CS2A < 10 ? parseFloat(store.CS2A) : 0,
          // store.bp2A < 10 ? parseFloat(store.bp2A) : 0,
          // store.tr2A < 10 ? parseFloat(store.tr2A) : 0,

          store.bp1A < 10 ? parseFloat(store.bp1A) : 0,
          store.bp2A < 10 ? parseFloat(store.bp2A) : 0,
          store.bp3A < 10 ? parseFloat(store.bp3A) : 0,
          store.bp4A < 10 ? parseFloat(store.bp4A) : 0,
          store.bp5A < 10 ? parseFloat(store.bp5A) : 0,
          store.bp6A < 10 ? parseFloat(store.bp6A) : 0,
        ],
        fill: true,
        backgroundColor: "rgba(25,144,254, 0.2)",
        borderColor: "rgb(25,144,254)", //"black",
        pointBackgroundColor: "rgb(25,144,254)",
        pointBorderColor: "rgb(25,144,254)", //"#fff",
        pointHoverBackgroundColor: "rgb(25,144,254)", //"#fff",
        pointHoverBorderColor: "rgb(25,144,254)",
      },
      {
        label: "Target",
        data: [
          // store.CS3A < 10 ? parseFloat(store.CS3A) : 0,
          // store.bp3A < 10 ? parseFloat(store.bp3A) : 0,
          // store.tr3A < 10 ? parseFloat(store.tr3A) : 0,
          store.tr1A < 10 ? parseFloat(store.tr1A) : 0,
          store.tr2A < 10 ? parseFloat(store.tr2A) : 0,
          store.tr3A < 10 ? parseFloat(store.tr3A) : 0,
          store.tr4A < 10 ? parseFloat(store.tr4A) : 0,
          store.tr5A < 10 ? parseFloat(store.tr5A) : 0,
          store.tr6A < 10 ? parseFloat(store.tr6A) : 0,
        ],
        fill: true,
        backgroundColor: "rgba(252,74,69, 0.2)",
        borderColor: "rgb(252,74,69)", //"black",
        pointBackgroundColor: "rgb(252,74,69)",
        pointBorderColor: "rgb(252,74,69)", //"#fff",
        pointHoverBackgroundColor: "rgb(252,74,69)", //"#fff",
        pointHoverBorderColor: "rgb(252,74,69)",
      },
      // {
      //   label: "Privacy Officer",
      //   data: [
      //     store.CS4A < 10 ? parseFloat(store.CS4A) : 0,
      //     store.bp4A < 10 ? parseFloat(store.bp4A) : 0,
      //     store.tr4A < 10 ? parseFloat(store.tr4A) : 0,
      //     // store.CS2A < 10 ? parseFloat(store.CS2A) : 0,
      //     // store.CS3A < 10 ? parseFloat(store.CS3A) : 0,
      //     // store.CS4A < 10 ? parseFloat(store.CS4A) : 0,
      //     // store.CS5A < 10 ? parseFloat(store.CS5A) : 0,
      //     // store.CS6A < 10 ? parseFloat(store.CS6A) : 0,
      //   ],
      //   fill: true,
      //   backgroundColor: "rgba(255, 99, 132, 0.2)",
      //   borderColor: "black",
      //   pointBackgroundColor: "rgb(255, 99, 132)",
      //   pointBorderColor: "#fff",
      //   pointHoverBackgroundColor: "#fff",
      //   pointHoverBorderColor: "rgb(255, 99, 132)",
      // },
      // {
      //   label: "Privacy Strategy",
      //   data: [
      //     store.CS5A < 10 ? parseFloat(store.CS5A) : 0,
      //     store.bp5A < 10 ? parseFloat(store.bp5A) : 0,
      //     store.tr5A < 10 ? parseFloat(store.tr5A) : 0,

      //     // store.bp1A < 10 ? parseFloat(store.bp1A) : 0,
      //     // store.bp2A < 10 ? parseFloat(store.bp2A) : 0,
      //     // store.bp3A < 10 ? parseFloat(store.bp3A) : 0,
      //     // store.bp4A < 10 ? parseFloat(store.bp4A) : 0,
      //     // store.bp5A < 10 ? parseFloat(store.bp5A) : 0,
      //     // store.bp6A < 10 ? parseFloat(store.bp6A) : 0,
      //   ],
      //   fill: true,
      //   backgroundColor: "rgba(25, 99, 132, 0.2)",
      //   borderColor: "green",
      //   pointBackgroundColor: "rgb(255, 99, 132)",
      //   pointBorderColor: "#fff",
      //   pointHoverBackgroundColor: "#fff",
      //   pointHoverBorderColor: "rgb(255, 99, 132)",
      // },
      // {
      //   label: "Accountability",
      //   data: [
      //     store.CS6A < 10 ? parseFloat(store.CS6A) : 0,
      //     store.bp6A < 10 ? parseFloat(store.bp6A) : 0,
      //     store.tr6A < 10 ? parseFloat(store.tr6A) : 0,
      //   ],
      //   fill: true,
      //   backgroundColor: "rgba(25, 99, 132, 0.2)",
      //   borderColor: "blue",
      //   pointBackgroundColor: "grey",
      //   pointBorderColor: "#fff",
      //   pointHoverBackgroundColor: "#fff",
      //   pointHoverBorderColor: "black",
      // },
    ],
  };

  const data = {
    labels: ["Current State Assessment", "Better Practice", "Target"],
    datasets: [
      {
        // label: ["# of Votes"],
        data: [
          (
            (store.CS1A < 10 ? parseFloat(store.CS1A) : 0) +
            (store.CS2A < 10 ? parseFloat(store.CS2A) : 0) +
            (store.CS3A < 10 ? parseFloat(store.CS3A) : 0) +
            (store.CS4A < 10 ? parseFloat(store.CS4A) : 0) +
            (store.CS5A < 10 ? parseFloat(store.CS5A) : 0) +
            (store.CS6A < 10 ? parseFloat(store.CS6A) : 0)
          ).toFixed(2),
          (
            (store.bp1A < 10 ? parseFloat(store.bp1A) : 0) +
            (store.bp2A < 10 ? parseFloat(store.bp2A) : 0) +
            (store.bp3A < 10 ? parseFloat(store.bp3A) : 0) +
            (store.bp4A < 10 ? parseFloat(store.bp4A) : 0) +
            (store.bp5A < 10 ? parseFloat(store.bp5A) : 0) +
            (store.bp6A < 10 ? parseFloat(store.bp6A) : 0)
          ).toFixed(2),
          (
            (store.tr1A < 10 ? parseFloat(store.tr1A) : 0) +
            (store.tr2A < 10 ? parseFloat(store.tr2A) : 0) +
            (store.tr3A < 10 ? parseFloat(store.tr3A) : 0) +
            (store.tr4A < 10 ? parseFloat(store.tr4A) : 0) +
            (store.tr5A < 10 ? parseFloat(store.tr5A) : 0) +
            (store.tr6A < 10 ? parseFloat(store.tr6A) : 0)
          ).toFixed(2),
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ marginTop: "8%" }}>
      <Radar data={data1} height={150} width={300} />
    </div>
  );
}
