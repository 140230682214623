import React, { useContext, useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { StoreContext } from "../store/store";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import firebase from "firebase";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import SuccessSnack from "./SuccessSnack";
import postData from "../functions/postData";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Spider8 from "./spider8";
import Header from "./Header";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    marginLeft: "11%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
}));

const AccordionSummary = withStyles({
  content: {
    flexGrow: 0,
  },
})(MuiAccordionSummary);

export default function Element8(props) {
  const classes = useStyles();
  const store = useContext(StoreContext);
  if (store.userKey === "") {
    // alert(localStorage.getItem("user"));

    props.history.push("/");
  }
  function getData() {
    var Config = {
      apiKey: "AIzaSyADTVCa2hUtYT1iYe0W8U-NZY6NyFQJacQ",
      authDomain: "pma-survey.firebaseapp.com",
      databaseURL: "https://pma-survey-default-rtdb.firebaseio.com",
      projectId: "pma-survey",
      storageBucket: "pma-survey.appspot.com",
      messagingSenderId: "531631815606",
      appId: "1:531631815606:web:ae74c86ce905bddc95df17",
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(Config);
    } else {
      firebase.app(); // if already initialized, use that one
    }

    console.log(localStorage.getItem("uid"));
    let user_data = {};
    let user_data1 = {};
    let user;
    if (store.decide === 1) {
      user = store.userToBeApproved;
      store.setReadOnly(false);
      store.setReadOnlyUser(true);
    } else {
      user = store.userKey;
      store.setReadOnly(false);
      store.setReadOnlyUser(true);
    }
    firebase
      .database()
      .ref()
      .child("users")
      .orderByChild("userKey")
      .equalTo(user)
      .on("value", function (snapshot) {
        user_data = snapshot.val();

        for (let i in user_data) {
          user_data1 = user_data[i];
          break;
        }
        console.log(user_data1);

        if (user_data1.user_email !== store.user) {
          console.log("inside if");
        } else {
          // --- element 1---//
          store.setApprove(user_data1.isApproved);
          store.setAppr(user_data1.appr);

          store.setCS1A(user_data1.CS1A);
          store.setCS2A(user_data1.CS2A);
          store.setCS3A(user_data1.CS3A);
          store.setCS4A(user_data1.CS4A);
          store.setCS5A(user_data1.CS5A);
          store.setCS6A(user_data1.CS6A);

          // ------------------- //

          store.setTr1A(user_data1.tr1A);
          store.setTr2A(user_data1.tr2A);
          store.setTr3A(user_data1.tr3A);
          store.setTr4A(user_data1.tr4A);
          store.setTr5A(user_data1.tr5A);
          store.setTr6A(user_data1.tr6A);

          //------------------------------------//

          store.setBp1A(user_data1.bp1A);
          store.setBp2A(user_data1.bp2A);
          store.setBp3A(user_data1.bp3A);
          store.setBp4A(user_data1.bp4A);
          store.setBp5A(user_data1.bp5A);
          store.setBp6A(user_data1.bp6A);

          // ----------------------------- //

          store.setComment1A(user_data1.comment1A);
          store.setComment2A(user_data1.comment2A);
          store.setComment3A(user_data1.comment3A);
          store.setComment4A(user_data1.comment4A);
          store.setComment5A(user_data1.comment5A);
          store.setComment6A(user_data1.comment6A);

          /// --------------------------------//
          store.setApComment1A(user_data1.Apcomment1A);
          store.setApComment2A(user_data1.Apcomment2A);
          store.setApComment3A(user_data1.Apcomment3A);
          store.setApComment4A(user_data1.Apcomment4A);
          store.setApComment5A(user_data1.Apcomment5A);
          store.setApComment6A(user_data1.Apcomment6A);

          // -------- element 2 ------//

          store.setCS1B(user_data1.CS1B);
          store.setCS2B(user_data1.CS2B);
          // ------------------- //

          store.setTr1B(user_data1.tr1B);
          store.setTr2B(user_data1.tr2B);

          //------------------------------------//

          store.setBp1B(user_data1.bp1B);
          store.setBp2B(user_data1.bp2B);
          // ----------------------------- //

          store.setComment1B(user_data1.comment1B);
          store.setComment2B(user_data1.comment2B);

          //---------------------------///

          store.setApComment1B(user_data1.Apcomment1B);
          store.setApComment2B(user_data1.Apcomment2B);

          // ------element 3 ----//

          store.setCS1C(user_data1.CS1C);
          store.setCS2C(user_data1.CS2C);
          // ------------------- //

          store.setTr1C(user_data1.tr1C);
          store.setTr2C(user_data1.tr2C);

          //------------------------------------//

          store.setBp1C(user_data1.bp1C);
          store.setBp2C(user_data1.bp2C);
          // ----------------------------- //

          store.setComment1C(user_data1.comment1C);
          store.setComment2C(user_data1.comment2C);

          //----------------------------'//
          store.setApComment1C(user_data1.Apcomment1C);
          store.setApComment2C(user_data1.Apcomment2C);

          // --------- element 4 ------ //

          store.setCS1D(user_data1.CS1D);
          store.setCS2D(user_data1.CS2D);
          store.setCS3D(user_data1.CS3D);
          // ------------------- //

          store.setTr1D(user_data1.tr1D);
          store.setTr2D(user_data1.tr2D);
          store.setTr3D(user_data1.tr3D);

          //------------------------------------//

          store.setBp1D(user_data1.bp1D);
          store.setBp2D(user_data1.bp2D);
          store.setBp3D(user_data1.bp3D);
          // ----------------------------- //

          store.setComment1D(user_data1.comment1D);
          store.setComment2D(user_data1.comment2D);
          store.setComment3D(user_data1.comment3D);

          //---------------//
          store.setApComment1D(user_data1.Apcomment1D);
          store.setApComment2D(user_data1.Apcomment2D);
          store.setApComment3D(user_data1.Apcomment3D);

          //---- ELEMENT 5 ----//

          store.setCS1E(user_data1.CS1E);
          store.setCS2E(user_data1.CS2E);
          store.setCS3E(user_data1.CS3E);
          // ------------------- //

          store.setTr1E(user_data1.tr1E);
          store.setTr2E(user_data1.tr2E);
          store.setTr3E(user_data1.tr3E);

          //------------------------------------//

          store.setBp1E(user_data1.bp1E);
          store.setBp2E(user_data1.bp2E);
          store.setBp3E(user_data1.bp3E);
          // ----------------------------- //

          store.setComment1E(user_data1.comment1E);
          store.setComment2E(user_data1.comment2E);
          store.setComment3E(user_data1.comment3E);

          store.setApComment1E(user_data1.Apcomment1E);
          store.setApComment2E(user_data1.Apcomment2E);
          store.setApComment3E(user_data1.Apcomment3E);

          // ------- Element 6-----//

          store.setCS1F(user_data1.CS1F);
          store.setCS2F(user_data1.CS2F);
          store.setCS3F(user_data1.CS3F);
          store.setCS4F(user_data1.CS4F);
          // ------------------- //

          store.setTr1F(user_data1.tr1F);
          store.setTr2F(user_data1.tr2F);
          store.setTr3F(user_data1.tr3F);
          store.setTr4F(user_data1.tr4F);
          //------------------------------------//

          store.setBp1F(user_data1.bp1F);
          store.setBp2F(user_data1.bp2F);
          store.setBp3F(user_data1.bp3F);
          store.setBp4F(user_data1.bp4F);
          // ----------------------------- //

          store.setComment1F(user_data1.comment1F);
          store.setComment2F(user_data1.comment2F);
          store.setComment3F(user_data1.comment3F);
          store.setComment4F(user_data1.comment4F);

          /// ------------------ ///
          store.setApComment1F(user_data1.Apcomment1F);
          store.setApComment2F(user_data1.Apcomment2F);
          store.setApComment3F(user_data1.Apcomment3F);
          store.setApComment4F(user_data1.Apcomment4F);
          // ---- Element 7 ---- //

          store.setCS1G(user_data1.CS1G);
          store.setCS2G(user_data1.CS2G);
          store.setCS3G(user_data1.CS3G);
          store.setCS4G(user_data1.CS4G);
          store.setCS5G(user_data1.CS5G);
          store.setCS6G(user_data1.CS6G);

          // ------------------- //

          store.setTr1G(user_data1.tr1G);
          store.setTr2G(user_data1.tr2G);
          store.setTr3G(user_data1.tr3G);
          store.setTr4G(user_data1.tr4G);
          store.setTr5G(user_data1.tr5G);
          store.setTr6G(user_data1.tr6G);
          //------------------------------------//

          store.setBp1G(user_data1.bp1G);
          store.setBp2G(user_data1.bp2G);
          store.setBp3G(user_data1.bp3G);
          store.setBp4G(user_data1.bp4G);
          store.setBp5G(user_data1.bp5G);
          store.setBp6G(user_data1.bp6G);
          // ----------------------------- //

          store.setComment1G(user_data1.comment1G);
          store.setComment2G(user_data1.comment2G);
          store.setComment3G(user_data1.comment3G);
          store.setComment4G(user_data1.comment4G);
          store.setComment5G(user_data1.comment5G);
          store.setComment6G(user_data1.comment6G);

          // ----------------------//
          store.setApComment1G(user_data1.Apcomment1G);
          store.setApComment2G(user_data1.Apcomment2G);
          store.setApComment3G(user_data1.Apcomment3G);
          store.setApComment4G(user_data1.Apcomment4G);
          store.setApComment5G(user_data1.Apcomment5G);
          store.setApComment6G(user_data1.Apcomment6G);
          //------- element 8 -----//

          store.setCS1H(user_data1.CS1H);
          store.setCS2H(user_data1.CS2H);
          store.setCS3H(user_data1.CS3H);
          store.setCS4H(user_data1.CS4H);
          store.setCS5H(user_data1.CS5H);
          store.setCS6H(user_data1.CS6H);
          store.setCS7H(user_data1.CS7H);
          // ------------------- //

          store.setTr1H(user_data1.tr1H);
          store.setTr2H(user_data1.tr2H);
          store.setTr3H(user_data1.tr3H);
          store.setTr4H(user_data1.tr4H);
          store.setTr5H(user_data1.tr5H);
          store.setTr6H(user_data1.tr6H);
          store.setTr7H(user_data1.tr7H);
          //------------------------------------//

          store.setBp1H(user_data1.bp1H);
          store.setBp2H(user_data1.bp2H);
          store.setBp3H(user_data1.bp3H);
          store.setBp4H(user_data1.bp4H);
          store.setBp5H(user_data1.bp5H);
          store.setBp6H(user_data1.bp6H);
          store.setBp7H(user_data1.bp7H);
          // ----------------------------- //

          store.setComment1H(user_data1.comment1H);
          store.setComment2H(user_data1.comment2H);
          store.setComment3H(user_data1.comment3H);
          store.setComment4H(user_data1.comment4H);
          store.setComment5H(user_data1.comment5H);
          store.setComment6H(user_data1.comment6H);
          store.setComment7H(user_data1.comment7H);

          // -----------------------------//
          store.setApComment1H(user_data1.Apcomment1H);
          store.setApComment2H(user_data1.Apcomment2H);
          store.setApComment3H(user_data1.Apcomment3H);
          store.setApComment4H(user_data1.Apcomment4H);
          store.setApComment5H(user_data1.Apcomment5H);
          store.setApComment6H(user_data1.Apcomment6H);
          store.setApComment7H(user_data1.Apcomment7H);

          ///------ element 9 -----///
          store.setCS1I(user_data1.CS1I);
          store.setCS2I(user_data1.CS2I);
          store.setTr1I(user_data1.tr1I);
          store.setTr2I(user_data1.tr2I);
          //------------------------------------//

          store.setBp1I(user_data1.bp1I);
          store.setBp2I(user_data1.bp2I);
          // ----------------------------- //

          store.setComment1I(user_data1.comment1I);

          store.setComment2I(user_data1.comment2I);

          //------//

          store.setApComment1G(user_data1.Apcomment1I);
          store.setApComment2G(user_data1.Apcomment2I);
        }
      }); //console.log("abcd"+messagesRef)
  }
  // ---- Current State -----//
  // ---- Current State -----//

  const [CS1, setCS1] = React.useState("");
  const [OpenCS1, setOpenCS1] = React.useState(false);

  const handleChangeCS1 = (event) => {
    setCS1(event.target.value);
    store.setCS1H(event.target.value);
  };
  const handleCloseCS1 = () => {
    setOpenCS1(false);
  };
  const handleOpenCS1 = () => {
    setOpenCS1(true);
  };

  const [CS2, setCS2] = React.useState("");
  const [OpenCS2, setOpenCS2] = React.useState(false);

  const handleChangeCS2 = (event) => {
    setCS2(event.target.value);
    store.setCS2H(event.target.value);
  };
  const handleCloseCS2 = () => {
    setOpenCS2(false);
  };
  const handleOpenCS2 = () => {
    setOpenCS2(true);
  };

  const [CS3, setCS3] = React.useState("");
  const [OpenCS3, setOpenCS3] = React.useState(false);

  const handleChangeCS3 = (event) => {
    setCS3(event.target.value);
    store.setCS3H(event.target.value);
  };
  const handleCloseCS3 = () => {
    setOpenCS3(false);
  };
  const handleOpenCS3 = () => {
    setOpenCS3(true);
  };

  const [CS4, setCS4] = React.useState("");
  const [OpenCS4, setOpenCS4] = React.useState(false);

  const handleChangeCS4 = (event) => {
    setCS4(event.target.value);
    store.setCS4H(event.target.value);
  };
  const handleCloseCS4 = () => {
    setOpenCS4(false);
  };
  const handleOpenCS4 = () => {
    setOpenCS4(true);
  };

  const [CS5, setCS5] = React.useState("");
  const [OpenCS5, setOpenCS5] = React.useState(false);

  const handleChangeCS5 = (event) => {
    setCS5(event.target.value);
    store.setCS5H(event.target.value);
  };
  const handleCloseCS5 = () => {
    setOpenCS5(false);
  };
  const handleOpenCS5 = () => {
    setOpenCS5(true);
  };

  const [CS6, setCS6] = React.useState("");
  const [OpenCS6, setOpenCS6] = React.useState(false);

  const handleChangeCS6 = (event) => {
    setCS6(event.target.value);
    store.setCS6H(event.target.value);
  };
  const handleCloseCS6 = () => {
    setOpenCS6(false);
  };
  const handleOpenCS6 = () => {
    setOpenCS6(true);
  };

  const [CS7, setCS7] = React.useState("");
  const [OpenCS7, setOpenCS7] = React.useState(false);

  const handleChangeCS7 = (event) => {
    setCS7(event.target.value);
    store.setCS7H(event.target.value);
  };
  const handleCloseCS7 = () => {
    setOpenCS7(false);
  };
  const handleOpenCS7 = () => {
    setOpenCS7(true);
  };

  // ------ Target ------//

  const [tr1, setTr1] = React.useState("");
  const [OpenTr1, setOpenTr1] = React.useState(false);

  const handleOpenTr1 = () => {
    setOpenTr1(true);
  };
  const handleCloseTr1 = () => {
    setOpenTr1(false);
  };
  const handleChangeTr1 = (event) => {
    setTr1(event.target.value);
    store.setTr1H(event.target.value);
  };

  const [tr2, setTr2] = React.useState("");
  const [OpenTr2, setOpenTr2] = React.useState(false);

  const handleOpenTr2 = () => {
    setOpenTr2(true);
  };
  const handleCloseTr2 = () => {
    setOpenTr2(false);
  };
  const handleChangeTr2 = (event) => {
    setTr2(event.target.value);
    store.setTr2H(event.target.value);
  };

  const [tr3, setTr3] = React.useState("");
  const [OpenTr3, setOpenTr3] = React.useState(false);

  const handleOpenTr3 = () => {
    setOpenTr3(true);
  };
  const handleCloseTr3 = () => {
    setOpenTr3(false);
  };
  const handleChangeTr3 = (event) => {
    setTr3(event.target.value);
    store.setTr3H(event.target.value);
  };

  const [tr4, setTr4] = React.useState("");
  const [OpenTr4, setOpenTr4] = React.useState(false);

  const handleOpenTr4 = () => {
    setOpenTr4(true);
  };
  const handleCloseTr4 = () => {
    setOpenTr4(false);
  };
  const handleChangeTr4 = (event) => {
    setTr4(event.target.value);
    store.setTr4H(event.target.value);
  };

  const [tr5, setTr5] = React.useState("");
  const [OpenTr5, setOpenTr5] = React.useState(false);

  const handleOpenTr5 = () => {
    setOpenTr5(true);
  };
  const handleCloseTr5 = () => {
    setOpenTr5(false);
  };
  const handleChangeTr5 = (event) => {
    setTr5(event.target.value);
    store.setTr5H(event.target.value);
  };

  const [tr6, setTr6] = React.useState("");
  const [OpenTr6, setOpenTr6] = React.useState(false);

  const handleOpenTr6 = () => {
    setOpenTr6(true);
  };
  const handleCloseTr6 = () => {
    setOpenTr6(false);
  };
  const handleChangeTr6 = (event) => {
    setTr6(event.target.value);
    store.setTr6H(event.target.value);
  };

  const [tr7, setTr7] = React.useState("");
  const [OpenTr7, setOpenTr7] = React.useState(false);

  const handleOpenTr7 = () => {
    setOpenTr7(true);
  };
  const handleCloseTr7 = () => {
    setOpenTr7(false);
  };
  const handleChangeTr7 = (event) => {
    setTr7(event.target.value);
    store.setTr7H(event.target.value);
  };
  // ----- Better practice -----//

  const [bp1, setBp1] = React.useState("");
  const [OpenBp1, setOpenBp1] = React.useState(false);

  const handleOpenBp1 = () => {
    setOpenBp1(true);
  };
  const handleCloseBp1 = () => {
    setOpenBp1(false);
  };
  const handleChangeBp1 = (event) => {
    setBp1(event.target.value);
    store.setBp1H(event.target.value);
  };

  const [bp2, setBp2] = React.useState("");
  const [OpenBp2, setOpenBp2] = React.useState(false);

  const handleOpenBp2 = () => {
    setOpenBp2(true);
  };
  const handleCloseBp2 = () => {
    setOpenBp2(false);
  };
  const handleChangeBp2 = (event) => {
    setBp2(event.target.value);
    store.setBp2H(event.target.value);
  };

  const [bp3, setBp3] = React.useState("");
  const [OpenBp3, setOpenBp3] = React.useState(false);

  const handleOpenBp3 = () => {
    setOpenBp3(true);
  };
  const handleCloseBp3 = () => {
    setOpenBp3(false);
  };
  const handleChangeBp3 = (event) => {
    setBp3(event.target.value);
    store.setBp3H(event.target.value);
  };

  const [bp4, setBp4] = React.useState("");
  const [OpenBp4, setOpenBp4] = React.useState(false);

  const handleOpenBp4 = () => {
    setOpenBp4(true);
  };
  const handleCloseBp4 = () => {
    setOpenBp4(false);
  };
  const handleChangeBp4 = (event) => {
    setBp4(event.target.value);
    store.setBp4H(event.target.value);
  };

  const [bp5, setBp5] = React.useState("");
  const [OpenBp5, setOpenBp5] = React.useState(false);

  const handleOpenBp5 = () => {
    setOpenBp5(true);
  };
  const handleCloseBp5 = () => {
    setOpenBp2(false);
  };
  const handleChangeBp5 = (event) => {
    setBp5(event.target.value);
    store.setBp5H(event.target.value);
  };

  const [bp6, setBp6] = React.useState("");
  const [OpenBp6, setOpenBp6] = React.useState(false);

  const handleOpenBp6 = () => {
    setOpenBp6(true);
  };
  const handleCloseBp6 = () => {
    setOpenBp6(false);
  };
  const handleChangeBp6 = (event) => {
    setBp6(event.target.value);
    store.setBp6H(event.target.value);
  };

  const [bp7, setBp7] = React.useState("");
  const [OpenBp7, setOpenBp7] = React.useState(false);

  const handleOpenBp7 = () => {
    setOpenBp7(true);
  };
  const handleCloseBp7 = () => {
    setOpenBp7(false);
  };
  const handleChangeBp7 = (event) => {
    setBp7(event.target.value);
    store.setBp7H(event.target.value);
  };

  // ------ Comments------//
  const [comment1, setComment1] = React.useState("");
  const [OpenComment1, setOpenComment1] = React.useState(false);
  const handleOpenComment1 = () => {
    setOpenComment1(true);
  };
  const handleCloseComment1 = () => {
    setOpenComment1(false);
  };
  const handleChangeComment1 = (event) => {
    setComment1(event.target.value);
    store.setComment1H(event.target.value);
  };

  const [comment2, setComment2] = React.useState("");
  const [OpenComment2, setOpenComment2] = React.useState(false);

  const handleOpenComment2 = () => {
    setOpenComment2(true);
  };
  const handleCloseComment2 = () => {
    setOpenComment2(false);
  };
  const handleChangeComment2 = (event) => {
    setComment2(event.target.value);
    store.setComment2H(event.target.value);
  };

  const [comment3, setComment3] = React.useState("");
  const [OpenComment3, setOpenComment3] = React.useState(false);

  const handleOpenComment3 = () => {
    setOpenComment3(true);
  };
  const handleCloseComment3 = () => {
    setOpenComment3(false);
  };
  const handleChangeComment3 = (event) => {
    setComment3(event.target.value);
    store.setComment3H(event.target.value);
  };

  const [comment4, setComment4] = React.useState("");
  const [OpenComment4, setOpenComment4] = React.useState(false);

  const handleOpenComment4 = () => {
    setOpenComment4(true);
  };
  const handleCloseComment4 = () => {
    setOpenComment4(false);
  };
  const handleChangeComment4 = (event) => {
    setComment4(event.target.value);
    store.setComment4H(event.target.value);
  };

  const [comment5, setComment5] = React.useState("");
  const [OpenComment5, setOpenComment5] = React.useState(false);

  const handleOpenComment5 = () => {
    setOpenComment5(true);
  };
  const handleCloseComment5 = () => {
    setOpenComment5(false);
  };
  const handleChangeComment5 = (event) => {
    setComment5(event.target.value);
    store.setComment5H(event.target.value);
  };

  const [comment6, setComment6] = React.useState("");
  const [OpenComment6, setOpenComment6] = React.useState(false);

  const handleOpenComment6 = () => {
    setOpenComment6(true);
  };
  const handleCloseComment6 = () => {
    setOpenComment6(false);
  };
  const handleChangeComment6 = (event) => {
    setComment6(event.target.value);
    store.setComment6H(event.target.value);
  };

  const [comment7, setComment7] = React.useState("");
  const [OpenComment7, setOpenComment7] = React.useState(false);

  const handleOpenComment7 = () => {
    setOpenComment7(true);
  };
  const handleCloseComment7 = () => {
    setOpenComment7(false);
  };
  const handleChangeComment7 = (event) => {
    setComment7(event.target.value);
    store.setComment7H(event.target.value);
  };

  /// approver's comments ///

  // ------ Comments------//
  const [Apcomment1, setApComment1] = React.useState("");

  const handleChangeApComment1 = (event) => {
    setApComment1(event.target.value);
    store.setApComment1H(event.target.value);
  };

  const [Apcomment2, setApComment2] = React.useState("");

  const handleChangeApComment2 = (event) => {
    setApComment2(event.target.value);
    store.setApComment2H(event.target.value);
  };

  const [Apcomment3, setApComment3] = React.useState("");

  const handleChangeApComment3 = (event) => {
    setApComment3(event.target.value);
    store.setApComment3H(event.target.value);
  };

  const [Apcomment4, setApComment4] = React.useState("");

  const handleChangeApComment4 = (event) => {
    setApComment4(event.target.value);
    store.setApComment4H(event.target.value);
  };

  const [Apcomment5, setApComment5] = React.useState("");

  const handleChangeApComment5 = (event) => {
    setApComment5(event.target.value);
    store.setApComment5H(event.target.value);
  };

  const [Apcomment6, setApComment6] = React.useState("");

  const handleChangeApComment6 = (event) => {
    setApComment6(event.target.value);
    store.setApComment6H(event.target.value);
  };

  const [Apcomment7, setApComment7] = React.useState("");

  const handleChangeApComment7 = (event) => {
    setApComment7(event.target.value);
    store.setApComment7H(event.target.value);
  };

  useEffect(() => window.scrollTo(0, 0), []);
  return useObserver(() => (
    <div id="Element8" className={classes.root} style={{ marginTop: "28%" }}>
      <div
        style={{ marginTop: store.header === "block" ? "120%" : "0%" }}
      ></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Implementation of Information Privacy Policies" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <div style={{ marginBottom: "3%" }}>
        <h3 className="text-center">
          {" "}
          Implementation of Information Privacy Policies
        </h3>
      </div>{" "}
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center"> Collection </h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                No formal, documented, or consistent processes to ensure that
                information is only collected as necessary for the purposes
                identified. No formal, consistent controls for ensuring
                individuals are notified when their personal information is
                collected.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Some documented procedures are in place for identifying personal
                information, but the agency does not obtain evidence to show
                they have identified all of it. Agency has basic understanding
                of what personal information is being (or has been) collected,
                why it is collected, and where it is kept. (i.e. a formal &
                comprehensive personal information inventory has not been
                undertaken)
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Documented and complete policies and processes in place to: •
                distinguish the personal information that is necessary for the
                purposes identified • differentiate the personal information
                necessary for the purposes identified from optional information
                • limit collection to information necessary for the purposes
                identified • notify individuals about all relevant privacy
                policies at or before collection • provide information about the
                consequences of refusing to provide personal information or
                denying or withdrawing consent. Assurance/evidence is obtained
                to demonstrate compliance with/effectiveness of these policies
                and processes, or there is a plan in place to obtain this
                assurance/evidence.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Documented and complete policies and processes are in place to:
                • regularly review the need for personal information to be held
                • clarify the consequences, if any, to individuals of refusing
                to provide requested information and indicate that certain
                information may be developed about individuals • regularly
                review these consequences to ensure they are complete, accurate,
                and relevant. Assurance/evidence is obtained to demonstrate
                compliance with/effectiveness of these policies and principles
                and to identify exceptions.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Documented and complete policies and processes are in place to
                ensure: • changes in the need for personal information are
                proactively identified, leading to changes to the relevant
                business processes and policies • unnecessary collection of
                information is proactively identified • corrective action is
                taken when the information collected is not necessary for the
                purposes identified • process updates and improvements are made
                when feedback is provided • the consequences of denying consent
                are reduced. Assurance/evidence is obtained to demonstrate
                compliance with/effectiveness of these policies and principles.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS1}
            onClose={handleCloseCS1}
            onOpen={handleOpenCS1}
            value={store.CS1H}
            onChange={handleChangeCS1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr1}
            onClose={handleCloseTr1}
            onOpen={handleOpenTr1}
            value={store.tr1H}
            onChange={handleChangeTr1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            OpenCS1={OpenBp1}
            onClose={handleCloseBp1}
            onOpenCS1={handleOpenBp1}
            value={store.bp1H}
            onChange={handleChangeBp1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.comment1H}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.Apcomment1H}</p>
        </Box>
      </Box>
      {/*  Q2  */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Implementation of Information Privacy Policies" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center"> Security</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                No formal, consistent, privacy-focused controls over systems and
                physical storage mechanisms containing personal information.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Standard security procedures exist, but these are not specific
                to the control of personal information and so may not be
                appropriate. Security measures are informally linked to
                sensitivity of personal information held or to business need, as
                perceived by staff (ie level of sensitivity not formally
                approved by management).
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Documented and complete processes in place to controlaccess to
                personal information Security levels are formally linked to a
                demonstrated business need to access personal information.
                Assurance/evidence is obtained to demonstrate compliance with
                the principles around security, or there is a plan in place to
                obtain this assurance/evidence.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Processes are in place to detect and monitor inappropriate
                access to hard-copy files, databases, and other resources
                containing personal information. Access rights are updated when
                staff change jobs, leave the agency, or when systems change. A
                review of security measures is included as a requirement in
                change processes and in processes implemented when new types of
                personal information are obtained or used by the agency.
                Assurance/evidence is obtained to demonstrate compliance with
                security principles and to identify exceptions.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Automated processes are in place to continuously detect and
                monitor irregular access of authorised personnel and logical
                access controls. Ongoing assessment of security measures over
                personal information exists.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS2}
            onClose={handleCloseCS2}
            onOpen={handleOpenCS2}
            value={store.CS2H}
            onChange={handleChangeCS2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr2}
            onClose={handleCloseTr2}
            onOpen={handleOpenTr2}
            value={store.tr2H}
            onChange={handleChangeTr2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp2}
            onClose={handleCloseBp2}
            onOpen={handleOpenBp2}
            value={store.bp2H}
            onChange={handleChangeBp2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.comment2H}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.Apcomment2H}</p>
        </Box>
      </Box>
      {/*  Q3  */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Implementation of Information Privacy Policies" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center"> Access</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                Informal, undocumented, and inconsistent processes for: •
                ensuring an individual is able to request access to their
                personal information • ensuring their identity is verified •
                monitoring requests for access • recording requests and
                timeliness of responses • reporting on requests.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Some documented procedures on how people can access their
                personal information and verify their identity, but the agency
                does not obtain evidence to show these are always applied and
                are effective.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Documented and complete policies and processes in place to: •
                ensure an individual can request confirmation that personal
                information is held and have access to it • monitor information
                and access requests to ensure appropriate access is provided •
                ensure individuals are authenticated before granting them access
                to personal information. Processes to verify identity are
                documented, understood, and consistently applied.
                Assurance/evidence is obtained to demonstrate compliance with
                the principles around access (ie sufficient controls are in
                place to ensure all the personal information held on an
                individual is identified and provided when requested, and to
                verify that this is the case) or a plan is in place to obtain
                this assurance/evidence.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Processes are in place to: • identify ways of improving
                efficiency and completeness of searching for personal
                information • review the validity of granting requested access.
                Assurance/evidence is obtained to demonstrate compliance with
                access principles and to identify exceptions.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                All complaints and/or concerns relating to access of information
                are reviewed and improvement opportunities identified and
                implemented as a result. Access to personal information is
                through self-service when possible and appropriate.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS3}
            onClose={handleCloseCS3}
            onOpen={handleOpenCS3}
            value={store.CS3H}
            onChange={handleChangeCS3}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr3}
            onClose={handleCloseTr3}
            onOpen={handleOpenTr3}
            value={store.tr3H}
            onChange={handleChangeTr3}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp3}
            onClose={handleCloseBp3}
            onOpen={handleOpenBp3}
            value={store.bp3H}
            onChange={handleChangeBp3}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.comment3H}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.Apcomment3H}</p>
        </Box>
      </Box>
      {/*  Q4  */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Implementation of Information Privacy Policies" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center"> Correction</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                Informal, undocumented, inconsistent processes and controls for:
                • ensuring an individual is able to correct personal information
                • ensuring the statement of correction is attached to the
                personal information when appropriate • monitoring requests for
                correction • recording requests and timeliness of responses •
                reporting on requests.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Some documented procedures for updating or correcting personal
                information, for validating requests, and for informing third
                parties where relevant, but the agency does not obtain evidence
                to demonstrate these are always applied and are effective.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Documented and complete processes in place to ensure individuals
                know how to correct their personal information, and to ensure
                corrections are made when requested. Processes ensure that the
                corrected information is provided to relevant third parties.
                Requests for correction of personal information are recorded,
                monitored, and reported. Assurance/evidence is obtained to show
                compliance with the principles on correcting personal
                information, or there is a plan in place to obtain this
                assurance/evidence.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Documented and complete policies and processes are in place to:
                • track correction requests • validate the accuracy and
                completeness of such data • ensure appropriate justification is
                documented when data is not updated to relevant third parties.
                Assurance/evidence is obtained to demonstrate compliance
                with/effectiveness of these policies and principles and to
                identify exceptions.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                There are documented and complete policies and processes in
                place to monitor and assess: • timelines and responses to
                correction requests • justification for not providing
                information updates to relevant third parties. Updating is
                automated and self-service where possible and appropriate.
                Distribution of updated information to third parties is also
                automated where possible and appropriate. Assurance/evidence is
                obtained to demonstrate compliance with/effectiveness of these
                policies and principles and to identify exceptions. All
                complaints and/or concerns relating to access of information are
                reviewed and improvement opportunities identified and
                implemented as a result.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS4}
            onClose={handleCloseCS4}
            onOpen={handleOpenCS4}
            value={store.CS4H}
            onChange={handleChangeCS4}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr4}
            onClose={handleCloseTr4}
            onOpen={handleOpenTr4}
            value={store.tr4H}
            onChange={handleChangeTr4}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp4}
            onClose={handleCloseBp4}
            onOpen={handleOpenBp4}
            value={store.bp4H}
            onChange={handleChangeBp4}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.comment4H}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.Apcomment4H}</p>
        </Box>
      </Box>
      {/* Q5 */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Implementation of Information Privacy Policies" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center"> Accuracy</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                Informal, undocumented, or inconsistent processes and controls
                for ensuring the accuracy of personal information.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Some documented procedures to ensure personal information is not
                used or disclosed without taking reasonable steps to check it is
                accurate, complete, relevant, up to date, and not misleading,
                but the agency does not obtain evidence to show these are always
                applied and are effective.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Documented and complete processes in place to ensure that
                personal information is not used or disclosed without taking
                reasonable steps to check that it is accurate, complete,
                relevant, up to date, and not misleading. ‘Reasonable steps’ is
                defined in the context of the agency's operations
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Assurance/evidence is obtained to demonstrate compliance
                with/effectiveness of these policies and principles and to
                identify exceptions.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                All complaints and/or concerns relating to accuracy of
                information are reviewed and improvement opportunities
                identified and implemented as a result.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS5}
            onClose={handleCloseCS5}
            onOpen={handleOpenCS5}
            value={store.CS5H}
            onChange={handleChangeCS5}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr5}
            onClose={handleCloseTr5}
            onOpen={handleOpenTr5}
            value={store.tr5H}
            onChange={handleChangeTr5}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp5}
            onClose={handleCloseBp5}
            onOpen={handleOpenBp5}
            value={store.bp5H}
            onChange={handleChangeBp5}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.comment5H}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.Apcomment5H}</p>
        </Box>
      </Box>
      {/* Q6 */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Implementation of Information Privacy Policies" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">Use and Disclosure</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                Informal, undocumented, or inconsistent processes and controls
                on using and disclosing personal information.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Some documented procedures to ensure the use and disclosure of
                personal information to third parties is only for the purposes
                for which it was collected and for which the individual has
                provided consent, unless laws or regulations allow otherwise,
                but the agency does not obtain evidence to show these are always
                applied and are effective.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Documented and complete processes in place on the use,
                redaction, and disclosure of personal information, both
                internally and to third parties. Policies cover: • who takes
                decisions about disclosures and new uses, and what process is
                required to ensure any disclosures or new uses comply with the
                law and are in the interests of the agency • documentation of
                any disclosures or new uses of information • factors that need
                to be considered when deciding whether to disclose • integration
                of use and disclosure policies into the wider privacy strategy.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Assurance/evidence is obtained to demonstrate compliance
                with/effectiveness of these policies and principles and to
                identify exceptions.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                All complaints and/or concerns relating to access of information
                are reviewed and improvement opportunities identified and
                implemented as a result.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS6}
            onClose={handleCloseCS6}
            onOpen={handleOpenCS6}
            value={store.CS6H}
            onChange={handleChangeCS6}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr6}
            onClose={handleCloseTr6}
            onOpen={handleOpenTr6}
            value={store.tr6H}
            onChange={handleChangeTr6}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp6}
            onClose={handleCloseBp6}
            onOpen={handleOpenBp6}
            value={store.bp6H}
            onChange={handleChangeBp6}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.comment6H}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.Apcomment6H}</p>
        </Box>
      </Box>
      {/* Q7 */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Implementation of Information Privacy Policies" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">Retention</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                Informal, undocumented, or inconsistent processes and controls
                for disposing of and destroying personal information.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Some documented procedures for appropriately disposing and
                destroying personal information, but the agency does not obtain
                evidence to show these are always applied and are effective.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Documented and complete processes are in place to ensure the
                appropriate disposal and destruction of personal information
                align with the Privacy Act 1993 and the Public Records Act 2005,
                and any other relevant regulations specific to the agency.
                Disposal schedule for the various types of personal information
                has been verified as complete against the agency’s personal
                information inventory. Assurance/evidence is obtained to
                demonstrate compliance with the principles around retention and
                to identify exceptions.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Policies and procedures for disposal and destruction of personal
                information are reviewed regularly, and also reviewed in
                response to new types of personal information identified on the
                agency’s personal information inventory.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Assurance/evidence on third parties' processes for disposing of
                and destroying the agency’s personal data (eg archiving
                companies) is obtained. All complaints and/or concerns relating
                to access of information are reviewed and improvement
                opportunities identified and implemented as a result.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS7}
            onClose={handleCloseCS7}
            onOpen={handleOpenCS7}
            value={store.CS7H}
            onChange={handleChangeCS7}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr7}
            onClose={handleCloseTr7}
            onOpen={handleOpenTr7}
            value={store.tr7H}
            onChange={handleChangeTr7}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp7}
            onClose={handleCloseBp7}
            onOpen={handleOpenBp7}
            value={store.bp7H}
            onChange={handleChangeBp7}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.comment7H}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.Apcomment7H}</p>
        </Box>
      </Box>
      <div className="float-left" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          onClick={() => {
            props.history.push("/e7");
          }}
          style={{ backgroundColor: "#3f51b5", width: "100px" }}
          className="btn btn-primary"
        >
          Previous
        </button>
      </div>
      <div className="float-right" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          onClick={() => {
            props.history.push("/e9");
          }}
          style={{ backgroundColor: "#3f51b5", width: "100px" }}
          className="btn btn-primary"
        >
          Next
        </button>
      </div>
      <div className="float-right" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          style={{ backgroundColor: "#3f51b5", width: "100px" }}
          className="btn btn-primary"
          onClick={() => {
            postData(store, 0, 0);
            store.setSnackOpen(true);
            store.setApprove(0);
          }}
        >
          Save
        </button>
      </div>
    </div>
  ));
}
