import React, { useContext } from "react";
import { useObserver, useLocalStore } from "mobx-react";

import ListSubheader from "@material-ui/core/ListSubheader";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import icon_access from "../icons/access.svg";
import content_management from "../icons/content-management.svg";
import corporate_culture from "../icons/corporate-culture.svg";
import government from "../icons/government.svg";
import iteration from "../icons/iteration.svg";
import assurance from "../icons/assurance.svg";
import password from "../icons/password.svg";
import privacy from "../icons/privacy.svg";
import privacy_policy from "../icons/privacy-policy.svg";
import risk from "../icons/risk.svg";
import security_breach from "../icons/security-breach.svg";
import { StoreContext } from "../store/store";
import login from "../icons/login.svg";
import summary from "../icons/summary.svg";
import logo from "../icons/logoGrey.svg";
import logout from "../icons/logout.svg";
import PrevAssess from "../icons/previous_assessments.svg";
import approvals from "../icons/approval.svg";
import current_assessment from "../icons/current_assessment.svg";
import Status from "./Status";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useHistory } from "react-router-dom";

//import Table from "./Table";

const drawerWidth = 265;

const useStyles = makeStyles((theme) => ({
  root: {
    //display: "flex",
    width: "100%",
    maxWidth: 260,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  toolbarButtons: {
    marginLeft: "auto",
  },
  customizeToolbar: {
    minHeight: 0,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    //minHeight: "1000px",

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
const reload = () => {
  // windows.location.reload();
};

export default function MiniDrawer(props) {
  const store = useContext(StoreContext);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(true);

  const history = useHistory();

  const routeChange = (newPath) => {
    let path = newPath;
    history.push(path);
  };

  const handleClick = () => {
    setOpen1(!open1);
    // props.history.push("/index");
  };

  const [visibility_auth, setVisiblityAuth] = React.useState(
    store.user !== "" ? "none" : "block"
  );
  const [visibility_element, setVisiblityElements] = React.useState(
    store.user !== "" ? "block" : "none"
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const auth = {
    register: {
      name: "Register",
      link: "/register",
      icon: password,
      style: store.user !== "" ? "none" : "block",
    },
    login: {
      name: "Login",
      link: "/",
      icon: login,
      style: store.user !== "" ? "none" : "block",
    },
  };

  return useObserver(() => (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar
          style={{ height: "70px" }}
          // className={classes.customizeToolbar}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <ListItemIcon>
            <img
              width="80"
              style={{ marginLeft: "-10%", marginRight: "5%" }}
              height="90"
              src={logo}
            />
          </ListItemIcon>
          {/* <ListItemText
            style={{ marginLeft: "0%", fontSize: "250px", color: "white" }}
            primary="Privacy Maturity Assessment"
          /> */}
          <h4 style={{ marginTop: "6px" }} className="text-center">
            Privacy Maturity Assessment
          </h4>
          <div style={{ flex: 1 }}></div>
          {/* <Grid
            justify="space-between" // Add it here :)
            container
            spacing={4}
          > */}
          <Grid item>
            <Link
              to={auth.register.link}
              // color="inherit"
              style={{ display: auth.register.style, marginBottom: "5px" }}
            >
              <ListItem button key={auth.register.name}>
                <ListItemIcon>
                  <img
                    width="50"
                    style={{ marginLeft: "0px" }}
                    height="20"
                    src={auth.register.icon}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-18px", color: "white" }}
                  primary={auth.register.name}
                />
              </ListItem>
            </Link>
          </Grid>
          <Grid item>
            <Link
              to={auth.login.link}
              // color="inherit"
              style={{ display: auth.login.style, marginBottom: "5px" }}
            >
              {" "}
              <ListItem button key={auth.login.name}>
                <ListItemIcon>
                  <img
                    width="50"
                    style={{ marginLeft: "0px" }}
                    height="20"
                    src={auth.login.icon}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-18px", color: "white" }}
                  primary={auth.login.name}
                />
              </ListItem>
            </Link>
            <Status />
          </Grid>
          {/* </Grid> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <PerfectScrollbar>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            // subheader={
            //   <ListSubheader component="div" id="nested-list-subheader">
            //     PMA
            //   </ListSubheader>
            // }
            className={classes.root}
          >
            <div
              style={{
                display:
                  store.user !== "" && store.isAdmin === false
                    ? "block"
                    : "none",
              }}
            >
              {/* <Link
                button
                  to="/index"
                color="inherit"
                style={{
                  display:
                    store.user !== "" && store.isAdmin === false
                      ? "block"
                      : "none",
                }}
                data-tip="Home"
                onClick={() => {
                  store.setDecide(0);
                }}
              > */}
              <ListItem
                onClick={() => {
                  store.setDecide(0);
                  routeChange("/e1");
                }}
                //to="/e1"
                button
                key="Home"
                data-tip="Home"
              >
                <ListItemIcon>
                  <img
                    width="60"
                    style={{ marginLeft: "-15%" }}
                    height="30"
                    src={icon_access}
                  />
                </ListItemIcon>
                <ListItemText style={{ marginLeft: "0px" }} primary="Home" />
              </ListItem>
              {/* </Link> */}

              <ListItem button onClick={handleClick}>
                <ListItemIcon>
                  <img
                    width="60"
                    style={{ marginLeft: "-15%" }}
                    height="30"
                    src={current_assessment}
                  />
                </ListItemIcon>
                <ListItemText primary="Current Assessment" />
                {open1 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </div>

            <Collapse in={open1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {[
                  {
                    name: "Governance",
                    link: "/e1",
                    icon: government,
                    style:
                      store.user !== "" && store.isAdmin === false
                        ? "block"
                        : "none",
                  },
                  {
                    name: "Culture",
                    link: "/e2",
                    icon: corporate_culture,
                    style:
                      store.user !== "" && store.isAdmin === false
                        ? "block"
                        : "none",
                  },

                  {
                    name: "Assurance",
                    link: "/e3",
                    icon: assurance,
                    style:
                      store.user !== "" && store.isAdmin === false
                        ? "block"
                        : "none",
                  },
                  {
                    name: "Management",
                    link: "/e4",
                    icon: content_management,
                    style:
                      store.user !== "" && store.isAdmin === false
                        ? "block"
                        : "none",
                  },
                  {
                    name: "Privacy",
                    link: "/e5",
                    icon: risk,
                    style:
                      store.user !== "" && store.isAdmin === false
                        ? "block"
                        : "none",
                  },
                  {
                    name: "Programme",
                    link: "/e6",
                    icon: privacy_policy,
                    style:
                      store.user !== "" && store.isAdmin === false
                        ? "block"
                        : "none",
                  },

                  {
                    name: "Processes",
                    link: "/e7",
                    icon: iteration,
                    style:
                      store.user !== "" && store.isAdmin === false
                        ? "block"
                        : "none",
                  },
                  {
                    name: "Implementation",
                    link: "/e8",
                    icon: privacy,
                    style:
                      store.user !== "" && store.isAdmin === false
                        ? "block"
                        : "none",
                  },
                  {
                    name: "Breaches",
                    link: "/e9",
                    icon: security_breach,
                    style:
                      store.user !== "" && store.isAdmin === false
                        ? "block"
                        : "none",
                  },

                  {
                    name: "Summary",
                    link: "/summary",
                    icon: summary,
                    style:
                      store.user !== "" && store.isAdmin === false
                        ? "block"
                        : "none",
                  },
                ].map((text, index) => (
                  <div
                    button
                    to={text.link}
                    color="inherit"
                    style={{ display: text.style }}

                    // onClick={() => {
                    //   store.setDecide(0);
                    // }}
                  >
                    <ListItem
                      onClick={() => {
                        store.setDecide(0);
                        routeChange(text.link);
                      }}
                      button
                      key={text.name}
                      data-tip={text.name}
                    >
                      <ListItemIcon>
                        <img
                          width="60"
                          style={{ marginLeft: "-15%" }}
                          height="30"
                          src={text.icon}
                        />
                      </ListItemIcon>
                      <ListItemText
                        style={{ marginLeft: "0px" }}
                        primary={text.name}
                      />
                    </ListItem>
                  </div>
                ))}
              </List>
            </Collapse>
            <ReactTooltip />
            {[
              {
                name: "Approvals",
                link: "/approvals",
                icon: approvals,
                style:
                  store.user !== "" && store.isAdmin === false
                    ? "block"
                    : "none",
              },
              {
                name: "Previous Assessments",
                link: "/prev",
                icon: PrevAssess,
                style:
                  store.user !== "" && store.isAdmin === false
                    ? "block"
                    : "none",
              },
            ].map((text, index) => (
              <div
                //button
                // to={text.link}
                color="inherit"
                style={{ display: text.style }}
                onClick={() => {
                  store.setDecide(0);
                }}
              >
                <ListItem
                  onClick={() => {
                    store.setDecide(0);
                    routeChange(text.link);
                  }}
                  data-tip={text.name}
                  button
                  key={text.name}
                >
                  <ListItemIcon>
                    <img
                      width="60"
                      style={{ marginLeft: "-15%" }}
                      height="30"
                      src={text.icon}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{ marginLeft: "0px" }}
                    primary={text.name}
                  />
                </ListItem>
              </div>
            ))}

            <div
              //to="/"
              color="inherit"
              style={{ display: store.user !== "" ? "block" : "none" }}
            >
              <ListItem
                onClick={() => {
                  store.setUser("");
                  localStorage.setItem("uid", "");
                  localStorage.setItem("userKey", "");
                  localStorage.setItem("user", "");
                  localStorage.setItem("year", "");
                  localStorage.setItem("quarter", "");
                  store.setReadOnly(false);
                  store.setReadOnlyUser(true);
                  store.setApprove(-1);
                  window.location.href = "/";
                }}
                button
                data-tip="logout"
                key="logout"
              >
                <ListItemIcon>
                  <img
                    width="60"
                    style={{ marginLeft: "-15%" }}
                    height="30"
                    src={logout}
                  />
                </ListItemIcon>
                <ListItemText style={{ marginLeft: "0px" }} primary="Logout" />
              </ListItem>
            </div>
          </List>
        </PerfectScrollbar>
        <Divider />
      </Drawer>
    </div>
  ));
}
