import React, { useContext, useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { StoreContext } from "../store/store";
import firebase from "firebase";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
//import AccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import SuccessSnack from "./SuccessSnack";
import postData from "../functions/postData";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Spider9 from "./spider9";
import Header from "./Header";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    marginLeft: "11%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
}));

const AccordionSummary = withStyles({
  content: {
    flexGrow: 0,
  },
})(MuiAccordionSummary);

export default function Element9(props) {
  const classes = useStyles();

  const store = useContext(StoreContext);
  if (store.userKey === "" && store.decide === 0) {
    // alert(localStorage.getItem("user"));

    props.history.push("/");
  }
  function getData() {
    var Config = {
      apiKey: "AIzaSyADTVCa2hUtYT1iYe0W8U-NZY6NyFQJacQ",
      authDomain: "pma-survey.firebaseapp.com",
      databaseURL: "https://pma-survey-default-rtdb.firebaseio.com",
      projectId: "pma-survey",
      storageBucket: "pma-survey.appspot.com",
      messagingSenderId: "531631815606",
      appId: "1:531631815606:web:ae74c86ce905bddc95df17",
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(Config);
    } else {
      firebase.app(); // if already initialized, use that one
    }

    console.log(localStorage.getItem("uid"));
    let user_data = {};
    let user_data1 = {};
    let user;
    if (store.decide === 1) {
      user = store.userToBeApproved;
      store.setReadOnly(false);
      store.setReadOnlyUser(true);
    } else {
      user = store.userKey;
      store.setReadOnly(false);
      store.setReadOnlyUser(true);
    }
    firebase
      .database()
      .ref()
      .child("users")
      .orderByChild("userKey")
      .equalTo(user)
      .on("value", function (snapshot) {
        user_data = snapshot.val();

        for (let i in user_data) {
          user_data1 = user_data[i];
          break;
        }
        console.log(user_data1);

        if (user_data1.user_email !== store.user) {
          console.log("inside if");
        } else {
          // --- element 1---//
          store.setApprove(user_data1.isApproved);
          store.setAppr(user_data1.appr);

          store.setCS1A(user_data1.CS1A);
          store.setCS2A(user_data1.CS2A);
          store.setCS3A(user_data1.CS3A);
          store.setCS4A(user_data1.CS4A);
          store.setCS5A(user_data1.CS5A);
          store.setCS6A(user_data1.CS6A);

          // ------------------- //

          store.setTr1A(user_data1.tr1A);
          store.setTr2A(user_data1.tr2A);
          store.setTr3A(user_data1.tr3A);
          store.setTr4A(user_data1.tr4A);
          store.setTr5A(user_data1.tr5A);
          store.setTr6A(user_data1.tr6A);

          //------------------------------------//

          store.setBp1A(user_data1.bp1A);
          store.setBp2A(user_data1.bp2A);
          store.setBp3A(user_data1.bp3A);
          store.setBp4A(user_data1.bp4A);
          store.setBp5A(user_data1.bp5A);
          store.setBp6A(user_data1.bp6A);

          // ----------------------------- //

          store.setComment1A(user_data1.comment1A);
          store.setComment2A(user_data1.comment2A);
          store.setComment3A(user_data1.comment3A);
          store.setComment4A(user_data1.comment4A);
          store.setComment5A(user_data1.comment5A);
          store.setComment6A(user_data1.comment6A);

          /// --------------------------------//
          store.setApComment1A(user_data1.Apcomment1A);
          store.setApComment2A(user_data1.Apcomment2A);
          store.setApComment3A(user_data1.Apcomment3A);
          store.setApComment4A(user_data1.Apcomment4A);
          store.setApComment5A(user_data1.Apcomment5A);
          store.setApComment6A(user_data1.Apcomment6A);

          // -------- element 2 ------//

          store.setCS1B(user_data1.CS1B);
          store.setCS2B(user_data1.CS2B);
          // ------------------- //

          store.setTr1B(user_data1.tr1B);
          store.setTr2B(user_data1.tr2B);

          //------------------------------------//

          store.setBp1B(user_data1.bp1B);
          store.setBp2B(user_data1.bp2B);
          // ----------------------------- //

          store.setComment1B(user_data1.comment1B);
          store.setComment2B(user_data1.comment2B);

          //---------------------------///

          store.setApComment1B(user_data1.Apcomment1B);
          store.setApComment2B(user_data1.Apcomment2B);

          // ------element 3 ----//

          store.setCS1C(user_data1.CS1C);
          store.setCS2C(user_data1.CS2C);
          // ------------------- //

          store.setTr1C(user_data1.tr1C);
          store.setTr2C(user_data1.tr2C);

          //------------------------------------//

          store.setBp1C(user_data1.bp1C);
          store.setBp2C(user_data1.bp2C);
          // ----------------------------- //

          store.setComment1C(user_data1.comment1C);
          store.setComment2C(user_data1.comment2C);

          //----------------------------'//
          store.setApComment1C(user_data1.Apcomment1C);
          store.setApComment2C(user_data1.Apcomment2C);

          // --------- element 4 ------ //

          store.setCS1D(user_data1.CS1D);
          store.setCS2D(user_data1.CS2D);
          store.setCS3D(user_data1.CS3D);
          // ------------------- //

          store.setTr1D(user_data1.tr1D);
          store.setTr2D(user_data1.tr2D);
          store.setTr3D(user_data1.tr3D);

          //------------------------------------//

          store.setBp1D(user_data1.bp1D);
          store.setBp2D(user_data1.bp2D);
          store.setBp3D(user_data1.bp3D);
          // ----------------------------- //

          store.setComment1D(user_data1.comment1D);
          store.setComment2D(user_data1.comment2D);
          store.setComment3D(user_data1.comment3D);

          //---------------//
          store.setApComment1D(user_data1.Apcomment1D);
          store.setApComment2D(user_data1.Apcomment2D);
          store.setApComment3D(user_data1.Apcomment3D);

          //---- ELEMENT 5 ----//

          store.setCS1E(user_data1.CS1E);
          store.setCS2E(user_data1.CS2E);
          store.setCS3E(user_data1.CS3E);
          // ------------------- //

          store.setTr1E(user_data1.tr1E);
          store.setTr2E(user_data1.tr2E);
          store.setTr3E(user_data1.tr3E);

          //------------------------------------//

          store.setBp1E(user_data1.bp1E);
          store.setBp2E(user_data1.bp2E);
          store.setBp3E(user_data1.bp3E);
          // ----------------------------- //

          store.setComment1E(user_data1.comment1E);
          store.setComment2E(user_data1.comment2E);
          store.setComment3E(user_data1.comment3E);

          store.setApComment1E(user_data1.Apcomment1E);
          store.setApComment2E(user_data1.Apcomment2E);
          store.setApComment3E(user_data1.Apcomment3E);

          // ------- Element 6-----//

          store.setCS1F(user_data1.CS1F);
          store.setCS2F(user_data1.CS2F);
          store.setCS3F(user_data1.CS3F);
          store.setCS4F(user_data1.CS4F);
          // ------------------- //

          store.setTr1F(user_data1.tr1F);
          store.setTr2F(user_data1.tr2F);
          store.setTr3F(user_data1.tr3F);
          store.setTr4F(user_data1.tr4F);
          //------------------------------------//

          store.setBp1F(user_data1.bp1F);
          store.setBp2F(user_data1.bp2F);
          store.setBp3F(user_data1.bp3F);
          store.setBp4F(user_data1.bp4F);
          // ----------------------------- //

          store.setComment1F(user_data1.comment1F);
          store.setComment2F(user_data1.comment2F);
          store.setComment3F(user_data1.comment3F);
          store.setComment4F(user_data1.comment4F);

          /// ------------------ ///
          store.setApComment1F(user_data1.Apcomment1F);
          store.setApComment2F(user_data1.Apcomment2F);
          store.setApComment3F(user_data1.Apcomment3F);
          store.setApComment4F(user_data1.Apcomment4F);
          // ---- Element 7 ---- //

          store.setCS1G(user_data1.CS1G);
          store.setCS2G(user_data1.CS2G);
          store.setCS3G(user_data1.CS3G);
          store.setCS4G(user_data1.CS4G);
          store.setCS5G(user_data1.CS5G);
          store.setCS6G(user_data1.CS6G);

          // ------------------- //

          store.setTr1G(user_data1.tr1G);
          store.setTr2G(user_data1.tr2G);
          store.setTr3G(user_data1.tr3G);
          store.setTr4G(user_data1.tr4G);
          store.setTr5G(user_data1.tr5G);
          store.setTr6G(user_data1.tr6G);
          //------------------------------------//

          store.setBp1G(user_data1.bp1G);
          store.setBp2G(user_data1.bp2G);
          store.setBp3G(user_data1.bp3G);
          store.setBp4G(user_data1.bp4G);
          store.setBp5G(user_data1.bp5G);
          store.setBp6G(user_data1.bp6G);
          // ----------------------------- //

          store.setComment1G(user_data1.comment1G);
          store.setComment2G(user_data1.comment2G);
          store.setComment3G(user_data1.comment3G);
          store.setComment4G(user_data1.comment4G);
          store.setComment5G(user_data1.comment5G);
          store.setComment6G(user_data1.comment6G);

          // ----------------------//
          store.setApComment1G(user_data1.Apcomment1G);
          store.setApComment2G(user_data1.Apcomment2G);
          store.setApComment3G(user_data1.Apcomment3G);
          store.setApComment4G(user_data1.Apcomment4G);
          store.setApComment5G(user_data1.Apcomment5G);
          store.setApComment6G(user_data1.Apcomment6G);
          //------- element 8 -----//

          store.setCS1H(user_data1.CS1H);
          store.setCS2H(user_data1.CS2H);
          store.setCS3H(user_data1.CS3H);
          store.setCS4H(user_data1.CS4H);
          store.setCS5H(user_data1.CS5H);
          store.setCS6H(user_data1.CS6H);
          store.setCS7H(user_data1.CS7H);
          // ------------------- //

          store.setTr1H(user_data1.tr1H);
          store.setTr2H(user_data1.tr2H);
          store.setTr3H(user_data1.tr3H);
          store.setTr4H(user_data1.tr4H);
          store.setTr5H(user_data1.tr5H);
          store.setTr6H(user_data1.tr6H);
          store.setTr7H(user_data1.tr7H);
          //------------------------------------//

          store.setBp1H(user_data1.bp1H);
          store.setBp2H(user_data1.bp2H);
          store.setBp3H(user_data1.bp3H);
          store.setBp4H(user_data1.bp4H);
          store.setBp5H(user_data1.bp5H);
          store.setBp6H(user_data1.bp6H);
          store.setBp7H(user_data1.bp7H);
          // ----------------------------- //

          store.setComment1H(user_data1.comment1H);
          store.setComment2H(user_data1.comment2H);
          store.setComment3H(user_data1.comment3H);
          store.setComment4H(user_data1.comment4H);
          store.setComment5H(user_data1.comment5H);
          store.setComment6H(user_data1.comment6H);
          store.setComment7H(user_data1.comment7H);

          // -----------------------------//
          store.setApComment1H(user_data1.Apcomment1H);
          store.setApComment2H(user_data1.Apcomment2H);
          store.setApComment3H(user_data1.Apcomment3H);
          store.setApComment4H(user_data1.Apcomment4H);
          store.setApComment5H(user_data1.Apcomment5H);
          store.setApComment6H(user_data1.Apcomment6H);
          store.setApComment7H(user_data1.Apcomment7H);

          ///------ element 9 -----///
          store.setCS1I(user_data1.CS1I);
          store.setCS2I(user_data1.CS2I);
          store.setTr1I(user_data1.tr1I);
          store.setTr2I(user_data1.tr2I);
          //------------------------------------//

          store.setBp1I(user_data1.bp1I);
          store.setBp2I(user_data1.bp2I);
          // ----------------------------- //

          store.setComment1I(user_data1.comment1I);

          store.setComment2I(user_data1.comment2I);

          //------//

          store.setApComment1G(user_data1.Apcomment1I);
          store.setApComment2G(user_data1.Apcomment2I);
        }
      }); //console.log("abcd"+messagesRef)
  }
  // ---- Current State -----//

  const [CS1, setCS1] = React.useState("");
  const [OpenCS1, setOpenCS1] = React.useState(false);

  const handleChangeCS1 = (event) => {
    setCS1(event.target.value);
    store.setCS1I(event.target.value);
  };
  const handleCloseCS1 = () => {
    setOpenCS1(false);
  };
  const handleOpenCS1 = () => {
    setOpenCS1(true);
  };

  const [CS2, setCS2] = React.useState("");
  const [OpenCS2, setOpenCS2] = React.useState(false);

  const handleChangeCS2 = (event) => {
    setCS2(event.target.value);
    store.setCS2I(event.target.value);
  };
  const handleCloseCS2 = () => {
    setOpenCS2(false);
  };
  const handleOpenCS2 = () => {
    setOpenCS2(true);
  };

  // ------ Target ------//

  const [tr1, setTr1] = React.useState("");
  const [OpenTr1, setOpenTr1] = React.useState(false);

  const handleOpenTr1 = () => {
    setOpenTr1(true);
  };
  const handleCloseTr1 = () => {
    setOpenTr1(false);
  };
  const handleChangeTr1 = (event) => {
    setTr1(event.target.value);
    store.setTr1I(event.target.value);
  };

  const [tr2, setTr2] = React.useState("");
  const [OpenTr2, setOpenTr2] = React.useState(false);

  const handleOpenTr2 = () => {
    setOpenTr2(true);
  };
  const handleCloseTr2 = () => {
    setOpenTr2(false);
  };
  const handleChangeTr2 = (event) => {
    setTr2(event.target.value);
    store.setTr2I(event.target.value);
  };

  // ----- Better practice -----//

  const [bp1, setBp1] = React.useState("");
  const [OpenBp1, setOpenBp1] = React.useState(false);

  const handleOpenBp1 = () => {
    setOpenBp1(true);
  };
  const handleCloseBp1 = () => {
    setOpenBp1(false);
  };
  const handleChangeBp1 = (event) => {
    setBp1(event.target.value);
    store.setBp1I(event.target.value);
  };

  const [bp2, setBp2] = React.useState("");
  const [OpenBp2, setOpenBp2] = React.useState(false);

  const handleOpenBp2 = () => {
    setOpenBp2(true);
  };
  const handleCloseBp2 = () => {
    setOpenBp2(false);
  };
  const handleChangeBp2 = (event) => {
    setBp2(event.target.value);
    store.setBp2I(event.target.value);
  };

  // ------ Comments------//
  const [comment1, setComment1] = React.useState("");
  const [OpenComment1, setOpenComment1] = React.useState(false);
  const handleOpenComment1 = () => {
    setOpenComment1(true);
  };
  const handleCloseComment1 = () => {
    setOpenComment1(false);
  };
  const handleChangeComment1 = (event) => {
    setComment1(event.target.value);
    store.setComment1I(event.target.value);
  };

  const [comment2, setComment2] = React.useState("");
  const [OpenComment2, setOpenComment2] = React.useState(false);

  const handleOpenComment2 = () => {
    setOpenComment2(true);
  };
  const handleCloseComment2 = () => {
    setOpenComment2(false);
  };
  const handleChangeComment2 = (event) => {
    setComment2(event.target.value);
    store.setComment2I(event.target.value);
  };

  /// approver's comments ///

  // ------ Comments------//
  const [Apcomment1, setApComment1] = React.useState("");

  const handleChangeApComment1 = (event) => {
    setApComment1(event.target.value);
    store.setApComment1I(event.target.value);
  };

  const [Apcomment2, setApComment2] = React.useState("");

  const handleChangeApComment2 = (event) => {
    setApComment2(event.target.value);
    store.setApComment2I(event.target.value);
  };

  useEffect(() => window.scrollTo(0, 0), []);
  return useObserver(() => (
    <div id="Element2" className={classes.root} style={{ marginTop: "8%" }}>
      <div style={{ marginTop: store.header === "block" ? "50%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="Breach and Incident Management" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <div style={{ marginBottom: "3%" }}>
        <h3 className="text-center"> Breach and Incident Management </h3>
      </div>{" "}
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">
            Incident Handling and Escalation Process
          </h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                No structured approach to incident management and little
                documentation or support from privacy specialists.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Staff awareness of the possibility of a privacy breach is
                limited to inadvertent disclosure to external parties. Existing
                informal incident response processes, which are managed within
                business units with limited central oversight.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Documented incident response and escalation procedures in place,
                of which staff are aware. Agency-wide understanding of what a
                privacy incident is (including complaints, 'near misses', and
                breaches). All staff and management know how to respond to an
                incident; particularly who to inform, how they should be
                informed, and the timeframe for communication.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Privacy complaints, ‘near-misses’ and breaches are recorded and
                root-cause analysis is undertaken to inform subsequent changes
                and improvements to processes. The agency has a comprehensive
                and consistent approach to incident management, which covers
                incidents relating to all of the Information Privacy Principles.
                A hierarchy of ‘trigger points’ for escalation to appropriate
                levels of management exists.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Internal and external privacy environments are monitored for
                issues affecting the appropriate response to a breach.
                Improvements to processes are proactively made as a result. Any
                large-scale incidents are managed in accordance with the
                agency’s crisis management approach. All incidents are subject
                to a post-incident review to assess the incident response. Any
                resulting improvements to processes are implemented in a timely
                manner.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS1}
            onClose={handleCloseCS1}
            onOpen={handleOpenCS1}
            value={store.CS1I}
            onChange={handleChangeCS1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem selected value={0.17}>
              Ad Hoc
            </MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr1}
            onClose={handleCloseTr1}
            onOpen={handleOpenTr1}
            value={store.tr1I}
            onChange={handleChangeTr1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            OpenCS1={OpenBp1}
            onClose={handleCloseBp1}
            onOpenCS1={handleOpenBp1}
            value={store.bp1I}
            onChange={handleChangeBp1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>
          <textarea
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenComment1}
            onClose={handleCloseComment1}
            onOpen={handleOpenComment1}
            value={store.comment1I}
            onChange={handleChangeComment1}
            className={classes.formControl + " form-control"}
            readOnly={store.readOnly}
          ></textarea>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>
          <textarea
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenComment1}
            value={store.Apcomment1I}
            onChange={handleChangeApComment1}
            className={classes.formControl + " form-control"}
            readOnly={store.readOnlyUser}
          ></textarea>
        </Box>
      </Box>
      {/*  Q2  */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="Breach and Incident Management" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">Breach/Incident Reporting</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>Undefined reporting requirements on privacy breaches.</p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Breaches recorded and reported to management relate mainly to
                inadvertent disclosure of personal information to third parties
                (and do not focus on the other IPPs).
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                An approved process for recording and reporting on personal
                information breaches and near misses relating to all of the IPPs
                is in place. Regular reports on breaches, including actions
                taken to remedy these, are made to executive management.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                An approved process for recording and reporting on personal
                information breaches, near misses, trends, risks, and other
                relevant information to the appropriate levels of management is
                in place.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Systematic/systemic analysis is used to inform changes to
                processes/procedures.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS2}
            onClose={handleCloseCS2}
            onOpen={handleOpenCS2}
            value={store.CS2I}
            onChange={handleChangeCS2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr2}
            onClose={handleCloseTr2}
            onOpen={handleOpenTr2}
            value={store.tr2I}
            onChange={handleChangeTr2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp2}
            onClose={handleCloseBp2}
            onOpen={handleOpenBp2}
            value={store.bp2I}
            onChange={handleChangeBp2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>
          <textarea
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenComment2}
            onClose={handleCloseComment2}
            onOpen={handleOpenComment2}
            value={store.comment2I}
            onChange={handleChangeComment2}
            className={classes.formControl + " form-control"}
            readOnly={store.readOnly}
          ></textarea>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>
          <textarea
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenComment1}
            value={store.Apcomment2I}
            onChange={handleChangeApComment1}
            className={classes.formControl + " form-control"}
            readOnly={store.readOnlyUser}
          ></textarea>
        </Box>
      </Box>
      <div className="float-left" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          onClick={() => {
            props.history.push("/e8");
          }}
          style={{ backgroundColor: "#3f51b5", width: "100px" }}
          className="btn btn-primary"
        >
          Previous
        </button>
      </div>
      <div className="float-right" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          onClick={() => {
            props.history.push("/summary");
          }}
          style={{ backgroundColor: "#3f51b5", width: "100px" }}
          className="btn btn-primary"
        >
          Next
        </button>
      </div>
      <div className="float-right" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          onClick={() => {
            postData(store, 0, 0);
            store.setSnackOpen(true);
            store.setApprove(0);
            props.history.push("/e9");
          }}
          style={{ backgroundColor: "#3f51b5", width: "100px" }}
          className="btn btn-primary"
        >
          Save
        </button>
      </div>
      {/* <div className="float-right" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          onClick={() => {
            props.history.push("/e1");
          }}
          style={{ backgroundColor: "#3f51b5", width: "100px" }}
          className="btn btn-primary"
        >
          Next
        </button>
      </div> */}
      <SuccessSnack content=" Saved" type="success" />
      <Spider9 />
    </div>
  ));
}
