import React, { Component, useState, useContext, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { StoreContext } from "../store/store";
import { useObserver, useLocalStore } from "mobx-react";
import Box from "@material-ui/core/Box";
import firebase from "firebase";

import MuiAlert from "@material-ui/lab/Alert";
import Progress from "./progress";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//let productz = {};

export default function Admin(props) {
  const store = useContext(StoreContext);
  if (store.user === "") {
    // alert(localStorage.getItem("user"));

    props.history.push("/");
  }
  const [progress, setProgress] = useState("none");
  const [productz, setPro] = React.useState([
    {
      user_email: "",
      sub_date: "",

      isApproved: "",
      link: "",
    },
  ]);
  const columns = [
    {
      dataField: "user_email",
      text: "User",
    },
    {
      dataField: "quarter",
      text: "Quarter",
    },
    {
      dataField: "submitDate",
      text: "Submission Date",
    },

    {
      dataField: "isApproved",
      text: "Status",
    },
    {
      dataField: "view_user",
      text: "View",
    },
  ];
  function getSecondPart(str) {
    return str.split("-")[1] + "-Q" + str.split("-")[2];
  }
  //   let user_data1;
  let user_data2 = [];
  const fetchData = () => {
    setProgress("block");
    let x;
    var Config = {
      apiKey: "AIzaSyADTVCa2hUtYT1iYe0W8U-NZY6NyFQJacQ",
      authDomain: "pma-survey.firebaseapp.com",
      databaseURL: "https://pma-survey-default-rtdb.firebaseio.com",
      projectId: "pma-survey",
      storageBucket: "pma-survey.appspot.com",
      messagingSenderId: "531631815606",
      appId: "1:531631815606:web:ae74c86ce905bddc95df17",
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(Config);
    } else {
      firebase.app(); // if already initialized, use that one
    }

    console.log(store.user + localStorage.getItem("uid"));
    let user_data = {};
    let user_data1 = {};
    firebase
      .database()
      .ref()
      .child("users")
      .orderByChild("appr")
      .equalTo(store.user)
      .on("value", function (snapshot) {
        user_data = snapshot.val();
        // for (let i in user_data) {
        //   user_data1 = user_data[i];
        //   // break;
        // }
        // console.log(user_data1);
        for (let i in user_data) {
          user_data1 = user_data[i];
          user_data1.user_key = i;
          user_data1.quarter = getSecondPart(i);
          console.log(user_data1.user_key);
          user_data1.isApproved =
            user_data1.isApproved == 0 ? (
              <Alert severity="warning"> Incomplete</Alert>
            ) : user_data1.isApproved == 1 ? (
              <Alert severity="info"> Pending Approval</Alert>
            ) : user_data1.isApproved == 2 ? (
              <Alert severity="Success"> Approved</Alert>
            ) : user_data1.isApproved == 3 ? (
              <Alert severity="error"> Disapproved</Alert>
            ) : (
              ""
            );
          user_data1.view_user = (
            <button
              style={{ backgroundColor: "#3f51b5" }}
              className="btn btn-primary"
              onClick={() => {
                // localStorage.setItem("TempKey", user_data[i].user_key);
                // store.setReadOnly(true);
                // store.setReadOnlyUser(false);
                store.setDecide(1);
                store.setUserToBeApproved(user_data[i].user_key);
                store.setUserToBeApprovedMail(user_data[i].user_email);
                store.setUserToBeApprovedAppr(user_data[i].appr);
                //store.setUser(user_data[i].user_email);

                store.setViewUser(user_data[i].user_email);

                props.history.push("/pdf");
              }}
            >
              View Assessment
            </button>
          );

          user_data2.push(user_data1);
          user_data1 = {};
        }
        setPro(user_data2);
        setProgress("none");
      });
    //   .then(() => {

    //   });

    // var recentPostsRef = firebase.database().ref("/users");
    // recentPostsRef
    //   .once("value")
    //   .then((snapshot) => {
    //     // snapshot.val() is the dictionary with all your keys/values from the '/users' path
    //     x = snapshot.val();
    //   })

    // console.log(user_data2);
  };
  //fetchData();

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box width="75%" ml="15%" pt={10}>
      <h3 className="text-center"> Approvals </h3>

      <ToolkitProvider keyField="id" data={productz} columns={columns}>
        {(props) => (
          <div>
            <hr />
            <BootstrapTable {...props.baseProps} />
            {/* <ExportCSVButton {...props.csvProps}>Export CSV!!</ExportCSVButton> */}
          </div>
        )}
      </ToolkitProvider>
      <div style={{ display: progress, marginLeft: "50%" }}>
        {" "}
        <Progress />
      </div>
    </Box>
  );
}
