//import  { useContext } from "react";
import firebase from "firebase";
//import { StoreContext } from "../store/store";

const handleMail=(to,cc,userMessage)=>{
   var myHeaders = new Headers();
   myHeaders.append("Content-Type", "application/json");
   
 
   const link1="https://pma.octure.io/"
   
var requestOptions = {
  method: 'GET',
 
  redirect: 'follow'
};

fetch("https://sendmailz.herokuapp.com?receiver="+to+"&cc="+to+"&link="+userMessage+"&link1="+link1, requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
}

Date.prototype.monthNames = [
  "January", "February", "March",
  "April", "May", "June",
  "July", "August", "September",
  "October", "November", "December"
];

Date.prototype.getMonthName = function() {
  return this.monthNames[this.getMonth()];
};

const date = new Date();  // 2009-11-10
const month = date.getMonthName();
const day= date.getDay();
const year= date.getFullYear();
const subDate=month+" "+day+", "+year;
//console.log(month);

 export default function PostData(store,email,isApproved,userMessage,approverMessage)
{ 
  //alert(store.userKey)
  //alert(store.user)
if(email===1)
{
   handleMail(store.user,store.user,userMessage);
   handleMail(store.appr,store.appr,approverMessage);

}
console.log(store.appr," ",store.comment1A);

   var Config = {
      apiKey: "AIzaSyADTVCa2hUtYT1iYe0W8U-NZY6NyFQJacQ",
      authDomain: "pma-survey.firebaseapp.com",
      databaseURL: "https://pma-survey-default-rtdb.firebaseio.com",
      projectId: "pma-survey",
      storageBucket: "pma-survey.appspot.com",
      messagingSenderId: "531631815606",
      appId: "1:531631815606:web:ae74c86ce905bddc95df17"
    };
  if (!firebase.apps.length) {
    firebase.initializeApp(Config);
 }else {
    firebase.app(); // if already initialized, use that one
 }

   // const store = useContext(StoreContext);
//alert(localStorage.getItem('uid'));
    let messagesRef = firebase.database().ref("users");
    //console.log("abcd"+messagesRef)
  // alert(store.userKey)
  let user;
    if (store.decide === 1) {
      user = store.userToBeApproved;
      store.setReadOnly(false);
      store.setReadOnlyUser(true);
    } else {
      user = store.userKey;
      store.setReadOnly(false);
      store.setReadOnlyUser(true);
    }
    //alert (user);
    
    messagesRef.child(user).set({
     user_email:    store.decide===1?store.userToBeApprovedMail: store.user,
     appr: store.decide === 1?store.userToBeApprovedAppr: store.appr,
     userKey: store.decide===1?store.userToBeApproved:store.userKey,
     isApproved:isApproved,
     year:store.year,
     quarter:store.quarter,
     submitDate:subDate,
     CS1A:store.CS1A,
     
     CS2A:store.CS2A,
    
     CS3A:store.CS3A,
    
     CS4A:store.CS4A,
    
     CS5A:store.CS5A,
    
     CS6A:store.CS6A,
    
  // ------------------- //
  
     tr1A:store.tr1A,
    
     tr2A:store.tr2A,
   
     tr3A:store.tr3A,
    
     tr4A:store.tr4A,
    
     tr5A:store.tr5A,
    
     tr6A:store.tr6A,
    
     //------------------------------------//
  
     bp1A:store.bp1A,
    
     bp2A:store.bp2A,
    
     bp3A:store.bp3A,
    
     bp4A:store.bp4A,
    
     bp5A:store.bp5A,
    
     bp6A:store.bp5A,
    
     // ----------------------------- //
  
     comment1A:store.comment1A,
    
     comment2A:store.comment2A,
    
     comment3A:store.comment3A,
    
     comment4A:store.comment4A,
    
     comment5A:store.comment5A,
    
     comment6A:store.comment6A,

// --------------------------------///

Apcomment1A:store.Apcomment1A,
Apcomment2A:store.Apcomment2A,
Apcomment3A:store.Apcomment3A,
Apcomment4A:store.Apcomment4A,
Apcomment5A:store.Apcomment5A,
Apcomment6A:store.Apcomment6A,


     // -------- element 2 ------//




CS1B:store.CS1B,

CS2B:store.CS2B,
// ------------------- //

tr1B:store.tr1B,

tr2B:store.tr2B,



//------------------------------------//

bp1B:store.bp1B,

bp2B:store.bp2B,
// ----------------------------- //

comment1B:store.comment1B,

comment2B:store.comment2B,

// ---------------------------//


Apcomment1B:store.Apcomment1B,
Apcomment2B:store.Apcomment2B,



// ------element 3 ----//

CS1C:store.CS1C ,

CS2C:store.CS2C ,
// ------------------- //

tr1C:store.tr1C,

tr2C:store.tr2C,



//------------------------------------//

bp1C:store.bp1C,

bp2C:store.bp2C,
// ----------------------------- //

comment1C:store.comment1C,

comment2C:store.comment2C,

//-------------------------///

Apcomment1C:store.Apcomment1C,
Apcomment2C:store.Apcomment2C,




// --------- element 4 ------ //

CS1D:store.CS1D,

CS2D:store.CS2D,
CS3D:store.CS3D,
// ------------------- //

tr1D:store.tr1D,

tr2D:store.tr2D,

tr3D:store.tr3D,



//------------------------------------//

bp1D:store.bp1D,

bp2D:store.bp2D,

bp3D:store.bp3D,
// ----------------------------- //

comment1D:store.comment1D ,

comment2D:store.comment2D ,

comment3D:store.comment3D ,
// ---------------------------///


Apcomment1D:store.Apcomment1D,
Apcomment2D:store.Apcomment2D,
Apcomment3D:store.Apcomment3D,



//---- ELEMENT 5 ----//


CS1E:store.CS1E,

CS2E:store.CS2E,

CS3E:store.CS3E,
// ------------------- //

tr1E:store.tr3E,

tr2E:store.tr3E,

tr3E:store.tr3E,



//------------------------------------//

bp1E:store.bp1E,

bp2E:store.bp2E,

bp3E:store.bp3E,
// ----------------------------- //

comment1E:store.comment1E,

comment2E:store.comment2E,

comment3E:store.comment3E,

// ---------------------------///


Apcomment1E:store.Apcomment1E,
Apcomment2E:store.Apcomment2E,
Apcomment3E:store.Apcomment3E,


// ------- Element 6-----//



CS1F:store.CS1F,

CS2F:store.CS2F,

CS3F:store.CS3F,

CS4F:store.CS4F,
// ------------------- //

tr1F:store.tr1F,

tr2F:store.tr2F,

tr3F:store.tr3F,

tr4F:store.tr4F,

//------------------------------------//

bp1F:store.bp1F,

bp2F:store.bp2F,

bp3F:store.bp3F,

bp4F:store.bp4F,
// ----------------------------- //

comment1F:store.comment1F,

comment2F:store.comment2F,

comment3F:store.comment3F,

comment4F:store.comment4F,

//------------------------//


Apcomment1F:store.Apcomment1F,
Apcomment2F:store.Apcomment2F,
Apcomment3F:store.Apcomment3F,
Apcomment4F:store.Apcomment4F,


// ---- Element 7 ---- //


CS1G:store.CS1G,

  CS2G:store.CS2G,

  CS3G:store.CS3G,

  CS4G:store.CS4G,

  CS5G:store.CS5G,

  CS6G:store.CS6G,

// ------------------- //

  tr1G:store.tr1G,

  tr2G:store.tr2G,

  
  tr3G:store.tr3G,

  tr4G:store.tr4G,

  tr5G:store.tr5G,

  tr6G:store.tr6G,

  //------------------------------------//

  bp1G:store.bp1G,

  bp2G:store.bp2G,

  bp3G:store.bp3G,

  bp4G:store.bp4G,

  bp5G:store.bp5G,

  bp6G:store.bp6G,

  // ----------------------------- //

  comment1G:store.comment1G ,
 
  comment2G:store.comment2G ,

  comment3G:store.comment3G ,

  comment4G:store.comment3G ,
  comment5G:store.comment6G ,

  comment6G:store.comment6G ,
///-----------------------------///


Apcomment1G:store.Apcomment1G,
Apcomment2G:store.Apcomment2G,
Apcomment3G:store.Apcomment3G,
Apcomment4G:store.Apcomment4G,
Apcomment5G:store.Apcomment5G,
Apcomment6G:store.Apcomment6G,



//------- element 8 -----//

CS1H:store.CS1H,

  CS2H:store.CS2H,

  CS3H:store.CS3H,

  CS4H:store.CS4H,

  CS5H:store.CS5H,

  CS6H:store.CS6H,

  CS7H:store.CS7H,

// ------------------- //

  tr1H:store.tr1H,

  tr2H:store.tr2H,

  
  tr3H:store.tr3H,

  tr4H:store.tr4H,

  tr5H:store.tr5H,

  tr6H:store.tr6H,

  tr7H:store.tr7H ,

  //------------------------------------//

  bp1H:store.bp1H ,

  bp2H:store.bp2H ,

  bp3H:store.bp3H ,

  bp4H:store.bp4H ,

  bp5H:store.bp5H ,

  bp6H:store.bp6H ,

  bp7H:store.bp7H ,

  // ----------------------------- //

  comment1H:store.comment1H,
 
  comment2H:store.comment2H,

  comment3H:store.comment3H,

  comment4H:store.comment4H,

  comment5H:store.comment5H,

  comment6H:store.comment6H,

  comment7H:store.comment7H,

  ///--------------------///

  
Apcomment1H:store.Apcomment1H,
Apcomment2H:store.Apcomment2H,
Apcomment3H:store.Apcomment3H,
Apcomment4H:store.Apcomment4H,
Apcomment5H:store.Apcomment5H,
Apcomment6H:store.Apcomment6H,
Apcomment7H:store.Apcomment7H,
///------ element 9 -----///
CS1I:store.CS1I,

CS2I:store.CS2I,
// ------------------- //

tr1I:store.tr1I,

tr2I:store.tr2I,


//------------------------------------//

bp1I:store.bp1I,

bp2I:store.bp2I,
// ----------------------------- //

comment1I:store.comment1I,

comment2I:store.comment2I,

/// ------------------- ///

Apcomment1I:store.Apcomment1I,
Apcomment2I:store.Apcomment2I,
        });

   // console.log("xyz"+x)
}