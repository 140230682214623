import React, { useContext } from "react";
import { Radar } from "react-chartjs-2";
import { StoreContext } from "../store/store";
export default function Spider(props) {
  const store = useContext(StoreContext);

  const data1 = {
    labels: [
      "Governance",
      "Culture",
      "Assurance",
      "Information Management",
      "Privacy Risk",
      "Privacy Programme",
      "Business Process",
      "Implementation of IPPs",
      "Breach Management",
    ],
    datasets: [
      {
        label: "Current State Assessment",
        data: [
          (
            (store.CS1A < 10 ? parseFloat(store.CS1A) : 0) +
            (store.CS2A < 10 ? parseFloat(store.CS2A) : 0) +
            (store.CS3A < 10 ? parseFloat(store.CS3A) : 0) +
            (store.CS4A < 10 ? parseFloat(store.CS4A) : 0) +
            (store.CS5A < 10 ? parseFloat(store.CS5A) : 0) +
            (store.CS6A < 10 ? parseFloat(store.CS6A) : 0)
          ).toFixed(2),
          // (store.CS1B < 10 ? parseFloat(store.CS1B) : 0) +
          // (store.CS2B < 10 ? parseFloat(store.CS2B) : 0)
          (
            (store.CS1B < 10 ? parseFloat(store.CS1B) : 0) +
            (store.CS2B < 10 ? parseFloat(store.CS2B) : 0)
          ).toFixed(2),
          (
            (store.CS1C < 10 ? parseFloat(store.CS1C) : 0) +
            (store.CS2C < 10 ? parseFloat(store.CS2C) : 0)
          ).toFixed(2),
          (
            (store.CS1D < 10 ? parseFloat(store.CS1D) : 0) +
            (store.CS2D < 10 ? parseFloat(store.CS2D) : 0) +
            (store.CS3D < 10 ? parseFloat(store.CS3D) : 0)
          ).toFixed(2),
          (
            (store.CS1E < 10 ? parseFloat(store.CS1E) : 0) +
            (store.CS2E < 10 ? parseFloat(store.CS2E) : 0) +
            (store.CS3E < 10 ? parseFloat(store.CS3E) : 0)
          ).toFixed(2),
          (
            (store.CS1F < 10 ? parseFloat(store.CS1F) : 0) +
            (store.CS2F < 10 ? parseFloat(store.CS2F) : 0) +
            (store.CS3F < 10 ? parseFloat(store.CS3F) : 0) +
            (store.CS3F < 10 ? parseFloat(store.CS3F) : 0)
          ).toFixed(2),
          (
            (store.CS1G < 10 ? parseFloat(store.CS1G) : 0) +
            (store.CS2G < 10 ? parseFloat(store.CS2G) : 0) +
            (store.CS3G < 10 ? parseFloat(store.CS3G) : 0) +
            (store.CS4G < 10 ? parseFloat(store.CS4G) : 0) +
            (store.CS5G < 10 ? parseFloat(store.CS5G) : 0) +
            (store.CS6G < 10 ? parseFloat(store.CS6G) : 0)
          ).toFixed(2),
          (
            (store.CS1H < 10 ? parseFloat(store.CS1H) : 0) +
            (store.CS2H < 10 ? parseFloat(store.CS2H) : 0) +
            (store.CS3H < 10 ? parseFloat(store.CS3H) : 0) +
            (store.CS4H < 10 ? parseFloat(store.CS4H) : 0) +
            (store.CS5H < 10 ? parseFloat(store.CS5H) : 0) +
            (store.CS6H < 10 ? parseFloat(store.CS6H) : 0) +
            (store.CS7H < 10 ? parseFloat(store.CS7H) : 0)
          ).toFixed(2),
          (
            (store.CS1I < 10 ? parseFloat(store.CS1I) : 0) +
            (store.CS2I < 10 ? parseFloat(store.CS2I) : 0)
          ).toFixed(2),
        ],
        fill: true,
        backgroundColor: "rgba(3,171,87, 0.2)",
        borderColor: "rgb(3,171,87)", //"black",
        pointBackgroundColor: "rgb(3,171,87)",
        pointBorderColor: "rgb(3,171,87)", //"#fff",
        pointHoverBackgroundColor: "rgb(3,171,87)", //"#fff",
        pointHoverBorderColor: "rgb(3,171,87)",
      },
      {
        label: "Better Practice",
        data: [
          //  store.CS2A < 10 ? parseFloat(store.CS2A) : 0,
          // store.bp2A < 10 ? parseFloat(store.bp2A) : 0,
          // store.tr2A < 10 ? parseFloat(store.tr2A) : 0,

          (
            (store.bp1A < 10 ? parseFloat(store.bp1A) : 0) +
            (store.bp2A < 10 ? parseFloat(store.bp2A) : 0) +
            (store.bp3A < 10 ? parseFloat(store.bp3A) : 0) +
            (store.bp4A < 10 ? parseFloat(store.bp4A) : 0) +
            (store.bp5A < 10 ? parseFloat(store.bp5A) : 0) +
            (store.bp6A < 10 ? parseFloat(store.bp6A) : 0)
          ).toFixed(2),
          (
            (store.bp1B < 10 ? parseFloat(store.bp1B) : 0) +
            (store.bp2B < 10 ? parseFloat(store.bp2B) : 0)
          ).toFixed(2),
          (
            (store.bp1C < 10 ? parseFloat(store.bp1C) : 0) +
            (store.bp2C < 10 ? parseFloat(store.bp2C) : 0)
          ).toFixed(2),
          (
            (store.bp1D < 10 ? parseFloat(store.bp1D) : 0) +
            (store.bp2D < 10 ? parseFloat(store.bp2D) : 0) +
            (store.bp3D < 10 ? parseFloat(store.bp3D) : 0)
          ).toFixed(2),
          (
            (store.bp1E < 10 ? parseFloat(store.bp1E) : 0) +
            (store.bp2E < 10 ? parseFloat(store.bp2E) : 0) +
            (store.bp3E < 10 ? parseFloat(store.bp3E) : 0)
          ).toFixed(2),
          (
            (store.bp1F < 10 ? parseFloat(store.bp1F) : 0) +
            (store.bp2F < 10 ? parseFloat(store.bp2F) : 0) +
            (store.bp3F < 10 ? parseFloat(store.bp3F) : 0) +
            (store.bp3F < 10 ? parseFloat(store.bp3F) : 0)
          ).toFixed(2),
          (store.bp1G < 10 ? parseFloat(store.bp1G) : 0) +
            (store.bp2G < 10 ? parseFloat(store.bp2G) : 0) +
            (store.bp3G < 10 ? parseFloat(store.bp3G) : 0) +
            (store.bp4G < 10 ? parseFloat(store.bp4G) : 0) +
            (store.bp5G < 10 ? parseFloat(store.bp5G) : 0) +
            (store.bp6G < 10 ? parseFloat(store.bp6G) : 0),
          (store.bp1H < 10 ? parseFloat(store.bp1H) : 0) +
            (store.bp2H < 10 ? parseFloat(store.bp2H) : 0) +
            (store.bp3H < 10 ? parseFloat(store.bp3H) : 0) +
            (store.bp4H < 10 ? parseFloat(store.bp4H) : 0) +
            (store.bp5H < 10 ? parseFloat(store.bp5H) : 0) +
            (store.bp6H < 10 ? parseFloat(store.bp6H) : 0) +
            (store.bp7H < 10 ? parseFloat(store.bp7H) : 0),
          (store.bp1I < 10 ? parseFloat(store.bp1I) : 0) +
            (store.bp2I < 10 ? parseFloat(store.bp2I) : 0),
        ],
        fill: true,
        backgroundColor: "rgba(25,144,254, 0.2)",
        borderColor: "rgb(25,144,254)", //"black",
        pointBackgroundColor: "rgb(25,144,254)",
        pointBorderColor: "rgb(25,144,254)", //"#fff",
        pointHoverBackgroundColor: "rgb(25,144,254)", //"#fff",
        pointHoverBorderColor: "rgb(25,144,254)",
      },
      {
        label: "Target",
        data: [
          (
            (store.tr1A < 10 ? parseFloat(store.tr1A) : 0) +
            (store.tr2A < 10 ? parseFloat(store.tr2A) : 0) +
            (store.tr3A < 10 ? parseFloat(store.tr3A) : 0) +
            (store.tr4A < 10 ? parseFloat(store.tr4A) : 0) +
            (store.tr5A < 10 ? parseFloat(store.tr5A) : 0) +
            (store.tr6A < 10 ? parseFloat(store.tr6A) : 0)
          ).toFixed(2),
          (
            (store.tr1B < 10 ? parseFloat(store.tr1B) : 0) +
            (store.tr2B < 10 ? parseFloat(store.tr2B) : 0)
          ).toFixed(2),
          (
            (store.tr1C < 10 ? parseFloat(store.tr1C) : 0) +
            (store.tr2C < 10 ? parseFloat(store.tr2C) : 0)
          ).toFixed(2),

          (
            (store.tr1D < 10 ? parseFloat(store.tr1D) : 0) +
            (store.tr2D < 10 ? parseFloat(store.tr2D) : 0) +
            (store.tr3D < 10 ? parseFloat(store.tr3D) : 0)
          ).toFixed(2),

          (
            (store.tr1E < 10 ? parseFloat(store.tr1E) : 0) +
            (store.tr2E < 10 ? parseFloat(store.tr2E) : 0) +
            (store.tr3E < 10 ? parseFloat(store.tr3E) : 0)
          ).toFixed(2),

          (
            (store.tr1F < 10 ? parseFloat(store.tr1F) : 0) +
            (store.tr2F < 10 ? parseFloat(store.tr2F) : 0) +
            (store.tr3F < 10 ? parseFloat(store.tr3F) : 0) +
            (store.tr4F < 10 ? parseFloat(store.tr4F) : 0)
          ).toFixed(2),

          (
            (store.tr1G < 10 ? parseFloat(store.tr1G) : 0) +
            (store.tr2G < 10 ? parseFloat(store.tr2G) : 0) +
            (store.tr3G < 10 ? parseFloat(store.tr3G) : 0) +
            (store.tr4G < 10 ? parseFloat(store.tr4G) : 0) +
            (store.tr5G < 10 ? parseFloat(store.tr5G) : 0) +
            (store.tr6G < 10 ? parseFloat(store.tr6G) : 0)
          ).toFixed(2),

          (
            (store.tr1H < 10 ? parseFloat(store.tr1H) : 0) +
            (store.tr2H < 10 ? parseFloat(store.tr2H) : 0) +
            (store.tr3H < 10 ? parseFloat(store.tr3H) : 0) +
            (store.tr4H < 10 ? parseFloat(store.tr4H) : 0) +
            (store.tr5H < 10 ? parseFloat(store.tr5H) : 0) +
            (store.tr6H < 10 ? parseFloat(store.tr6H) : 0) +
            (store.tr7H < 10 ? parseFloat(store.tr7H) : 0)
          ).toFixed(2),

          (
            (store.tr1I < 10 ? parseFloat(store.tr1I) : 0) +
            (store.tr2I < 10 ? parseFloat(store.tr2I) : 0)
          ).toFixed(2),
        ],
        fill: true,
        backgroundColor: "rgba(252,74,69, 0.2)",
        borderColor: "rgb(252,74,69)", //"black",
        pointBackgroundColor: "rgb(252,74,69)",
        pointBorderColor: "rgb(252,74,69)", //"#fff",
        pointHoverBackgroundColor: "rgb(252,74,69)", //"#fff",
        pointHoverBorderColor: "rgb(252,74,69)",
      },
      // {
      //   label: "Privacy Officer",
      //   data: [
      //     store.CS4A < 10 ? parseFloat(store.CS4A) : 0,
      //     store.bp4A < 10 ? parseFloat(store.bp4A) : 0,
      //     store.tr4A < 10 ? parseFloat(store.tr4A) : 0,
      //     // store.CS2A < 10 ? parseFloat(store.CS2A) : 0,
      //     // store.CS3A < 10 ? parseFloat(store.CS3A) : 0,
      //     // store.CS4A < 10 ? parseFloat(store.CS4A) : 0,
      //     // store.CS5A < 10 ? parseFloat(store.CS5A) : 0,
      //     // store.CS6A < 10 ? parseFloat(store.CS6A) : 0,
      //   ],
      //   fill: true,
      //   backgroundColor: "rgba(255, 99, 132, 0.2)",
      //   borderColor: "black",
      //   pointBackgroundColor: "rgb(255, 99, 132)",
      //   pointBorderColor: "#fff",
      //   pointHoverBackgroundColor: "#fff",
      //   pointHoverBorderColor: "rgb(255, 99, 132)",
      // },
      // {
      //   label: "Privacy Strategy",
      //   data: [
      //     store.CS5A < 10 ? parseFloat(store.CS5A) : 0,
      //     store.bp5A < 10 ? parseFloat(store.bp5A) : 0,
      //     store.tr5A < 10 ? parseFloat(store.tr5A) : 0,

      //     // store.bp1A < 10 ? parseFloat(store.bp1A) : 0,
      //     // store.bp2A < 10 ? parseFloat(store.bp2A) : 0,
      //     // store.bp3A < 10 ? parseFloat(store.bp3A) : 0,
      //     // store.bp4A < 10 ? parseFloat(store.bp4A) : 0,
      //     // store.bp5A < 10 ? parseFloat(store.bp5A) : 0,
      //     // store.bp6A < 10 ? parseFloat(store.bp6A) : 0,
      //   ],
      //   fill: true,
      //   backgroundColor: "rgba(25, 99, 132, 0.2)",
      //   borderColor: "green",
      //   pointBackgroundColor: "rgb(255, 99, 132)",
      //   pointBorderColor: "#fff",
      //   pointHoverBackgroundColor: "#fff",
      //   pointHoverBorderColor: "rgb(255, 99, 132)",
      // },
      // {
      //   label: "Accountability",
      //   data: [
      //     store.CS6A < 10 ? parseFloat(store.CS6A) : 0,
      //     store.bp6A < 10 ? parseFloat(store.bp6A) : 0,
      //     store.tr6A < 10 ? parseFloat(store.tr6A) : 0,
      //   ],
      //   fill: true,
      //   backgroundColor: "rgba(25, 99, 132, 0.2)",
      //   borderColor: "blue",
      //   pointBackgroundColor: "grey",
      //   pointBorderColor: "#fff",
      //   pointHoverBackgroundColor: "#fff",
      //   pointHoverBorderColor: "black",
      // },
    ],
  };

  const data = {
    labels: ["Current State Assessment", "Better Practice", "Target"],
    datasets: [
      {
        // label: ["# of Votes"],
        data: [
          (store.CS1A < 10 ? parseFloat(store.CS1A) : 0) +
            (store.CS2A < 10 ? parseFloat(store.CS2A) : 0) +
            (store.CS3A < 10 ? parseFloat(store.CS3A) : 0) +
            (store.CS4A < 10 ? parseFloat(store.CS4A) : 0) +
            (store.CS5A < 10 ? parseFloat(store.CS5A) : 0) +
            (store.CS6A < 10 ? parseFloat(store.CS6A) : 0) +
            (store.CS1B < 10 ? parseFloat(store.CS1B) : 0) +
            (store.CS2B < 10 ? parseFloat(store.CS2B) : 0),
          (store.bp1A < 10 ? parseFloat(store.bp1A) : 0) +
            (store.bp2A < 10 ? parseFloat(store.bp2A) : 0) +
            (store.bp3A < 10 ? parseFloat(store.bp3A) : 0) +
            (store.bp4A < 10 ? parseFloat(store.bp4A) : 0) +
            (store.bp5A < 10 ? parseFloat(store.bp5A) : 0) +
            (store.bp6A < 10 ? parseFloat(store.bp6A) : 0),
          (store.tr1A < 10 ? parseFloat(store.tr1A) : 0) +
            (store.tr2A < 10 ? parseFloat(store.tr2A) : 0) +
            (store.tr3A < 10 ? parseFloat(store.tr3A) : 0) +
            (store.tr4A < 10 ? parseFloat(store.tr4A) : 0) +
            (store.tr5A < 10 ? parseFloat(store.tr5A) : 0) +
            (store.tr6A < 10 ? parseFloat(store.tr6A) : 0),
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ marginTop: "8%" }}>
      <Radar data={data1} height={150} width={300} />
    </div>
  );
}
