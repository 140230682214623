import React, { useState, useRef, useEffect, useContext } from "react";
import E1 from "./Element1";
import E2 from "./Element2";
import E3 from "./Element3";
import E4 from "./Element4";
import E5 from "./Element5";
import E6 from "./Element6";
import E7 from "./Element7";
import E8 from "./Element8";
import E9 from "./Element9";
import Summary from "./Summary";
import { useReactToPrint } from "react-to-print";
import { StoreContext } from "../store/store";

export default function FullPDF(props) {
  const componentRef = useRef();
  const store = useContext(StoreContext);
  if (store.user === "") {
    // alert(localStorage.getItem("user"));

    props.history.push("/");
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      let divsToHide1 = document.getElementsByClassName("float-left");
      let divsToHide2 = document.getElementsByClassName("float-right");
      for (var i = 0; i < divsToHide1.length; i++) {
        divsToHide1[i].style.display = "block"; // depending on what you're doing
      }
      for (var i = 0; i < divsToHide2.length; i++) {
        divsToHide2[i].style.display = "block"; // depending on what you're doing
      }
      props.history.push("/summary");
    },
  });

  const hideButton = () => {
    let divsToHide1 = document.getElementsByClassName("float-left");
    let divsToHide2 = document.getElementsByClassName("float-right");
    for (var i = 0; i < divsToHide1.length; i++) {
      divsToHide1[i].style.display = "none"; // depending on what you're doing
    }
    for (var i = 0; i < divsToHide2.length; i++) {
      divsToHide2[i].style.display = "none"; // depending on what you're doing
    }

    // document.getElementById("down").style.display = "block";
  };

  const hide = () => {
    let divsToHide1 = document.getElementsByClassName("float-left");
    let divsToHide2 = document.getElementsByClassName("float-right");
    for (var i = 0; i < divsToHide1.length; i++) {
      divsToHide1[i].style.display = "none"; // depending on what you're doing
    }
    for (var i = 0; i < divsToHide2.length; i++) {
      divsToHide2[i].style.display = "none"; // depending on what you're doing
    }

    handlePrint();
    // document.getElementById("down").style.display = "block";
  };
  const [margin, setMargin] = useState(0);

  useEffect(() => {
    if (store.decide === 1) {
      store.setReadOnly(true);
      store.setReadOnlyUser(false);
    }
    // if (store.viewUser === undefined) {
    //   hide();
    //   setMargin(-10);
    // } else
    hideButton();
    // store.setUserToBeApproved("");
  }, []);
  return (
    <div ref={componentRef} style={{ marginLeft: margin }}>
      <div>
        <Summary />
      </div>
      <div>
        <E1 />
      </div>
      <div>
        <E2 />
      </div>
      <div>
        <E3 />
      </div>
      <div>
        <E4 />
      </div>
      <div>
        <E5 />
      </div>
      <div>
        <E6 />
      </div>
      <div>
        <E7 />
      </div>
      <div>
        {" "}
        <E8 />
      </div>
      <div>
        <E9 />
      </div>
    </div>
  );
}
