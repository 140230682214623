import React, { useState, useRef, useContext, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Box from "@material-ui/core/Box";
import SuccessSnack from "./SuccessSnack";
import Table from "react-bootstrap/Table";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { StoreContext } from "../store/store";
import { useObserver, useLocalStore } from "mobx-react";
import Spider from "./spiderSummary";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import postData from "../functions/postData";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Header from "./Header";
import Modal from "react-bootstrap/Modal";
import logo from "../icons/logoGrey.svg";
import { useHistory } from "react-router-dom";
//import "../css/media.css";
const ref = React.createRef();
//const { SearchBar, ClearSearchButton } = Search;
//const { ExportCSVButton } = CSVExport;
// function printDocument() {
//   const input = document.getElementById("divToPrint");
//   html2canvas(input).then((canvas) => {
//     const imgData = canvas.toDataURL("image/png");
//     const pdf = new jsPDF();
//     pdf.addImage(imgData, "JPEG", 0, 0);
//     // pdf.output('dataurlnewwindow');
//     pdf.save("download.pdf");
//   });
// }
// const cellStyle = (row, rowIndex) => {
//   const colorz = [
//     { backgroundColor: "rgb(149,206,255)" },
//     { backgroundColor: "rgb(92,92,97)" },
//     { backgroundColor: "rgb(169,255,159)" },
//     { backgroundColor: "rgb(255,188,117)" },
//     { backgroundColor: "rgb(153,158,255)" },
//     { backgroundColor: "rgb(255,117,153)" },
//     { backgroundColor: "rgb(253,236,109)" },
//     { backgroundColor: "rgb(68,169,168)" },
//     { backgroundColor: "rgb(255,116,116)" },
//   ];
//   console.table(colorz);
//   return colorz[rowIndex];
// };
const colorz = [
  { backgroundColor: "rgb(149,206,255)" },
  { backgroundColor: "grey" },
  { backgroundColor: "rgb(169,255,159)" },
  { backgroundColor: "rgb(255,188,117)" },
  { backgroundColor: "rgb(153,158,255)" },
  { backgroundColor: "rgb(255,117,153)" },
  { backgroundColor: "rgb(253,236,109)" },
  { backgroundColor: "rgb(68,169,168)" },
  { backgroundColor: "rgb(255,116,116)" },
];
const columns = [
  {
    dataField: "id",
    text: "Reference",
    headerStyle: { backgroundColor: "#3f51b5", color: "white" },
    style: function callback(cell, row, rowIndex, colIndex) {
      return colorz[rowIndex];
    },
  },
  {
    dataField: "name",
    text: "Element",
    headerStyle: { backgroundColor: "#3f51b5", color: "white" },
    style: function callback(cell, row, rowIndex, colIndex) {
      return colorz[rowIndex];
    },
  },

  {
    dataField: "cs",
    text: "Current State Assessment",
    // style: { backgroundColor: "rgb(3,171,87)" },
    headerStyle: { backgroundColor: "#3f51b5", color: "white" },
  },
  {
    dataField: "tr",
    text: "Target",
    // style: { backgroundColor: "rgb(252,74,69)" },
    headerStyle: { backgroundColor: "#3f51b5", color: "white" },
  },
  {
    dataField: "bp",
    text: "Better Practice",
    //style: { backgroundColor: "rgb(25,144,254)" },
    headerStyle: { backgroundColor: "#3f51b5", color: "white" },
  },
];
// const a = 10;
// const b = 10;

// const sum = (a > 9 ? a : 0) + (b > 9 ? b : 0);
// console.log(sum);
export default function Summary(props) {
  const store = useContext(StoreContext);

  if (store.userKey === "" && store.decide === 0) {
    // alert(localStorage.getItem("user"));

    props.history.push("/");
  }
  const history = useHistory();
  if (store.user === "") {
    // alert(localStorage.getItem("user"));

    props.history.push("/");
  }

  const userMessage = `Thank you for submitting the privacy maturity assessment.
                   Your approver has been notified.
                   You will receive an email notification once your assessment is approved.`;

  const approverMessage = `An assessment was submitted for approval using Octure’s Privacy Maturity Assessment Tool.
                       Your email address was provided by ${store.user} 
                       for approval. Please sign up using ${store.appr} or
                       if you already have an account with this email address ${(
                         <a href="https://pma.octure.io/">please log in here</a>
                       )}.
                       You will find the assessment waiting for your approval in the “Approvals” 
                       section of the Privacy Maturity Assessment Tool.`;

  const approved = `Your assessment has been approved by ${store.user}.`;

  const disapproved = `The assessment you submitted was not approved by your approver. 
                     Please log in to your account to view the approver’s comments.
                     You can make the necessary changes and resubmit the assessment.`;
  // const claculateSum = () => {};
  // console.log(printFibonacciSeries(4));

  const [products, setPro] = useState([
    {
      idz1: "gov1",
      idz2: "gov2",
      id: "1",
      name: "Governance, Leadership and Accountability",

      cs: (
        (store.CS1A < 10 ? parseFloat(store.CS1A) : 0) +
        (store.CS2A < 10 ? parseFloat(store.CS2A) : 0) +
        (store.CS3A < 10 ? parseFloat(store.CS3A) : 0) +
        (store.CS4A < 10 ? parseFloat(store.CS4A) : 0) +
        (store.CS5A < 10 ? parseFloat(store.CS5A) : 0) +
        (store.CS6A < 10 ? parseFloat(store.CS6A) : 0)
      ).toFixed(2),

      tr: (
        (store.tr1A < 10 ? parseFloat(store.tr1A) : 0) +
        (store.tr2A < 10 ? parseFloat(store.tr2A) : 0) +
        (store.tr3A < 10 ? parseFloat(store.tr3A) : 0) +
        (store.tr4A < 10 ? parseFloat(store.tr4A) : 0) +
        (store.tr5A < 10 ? parseFloat(store.tr5A) : 0) +
        (store.tr6A < 10 ? parseFloat(store.tr6A) : 0)
      ).toFixed(2),
      bp: (
        (store.bp1A < 10 ? parseFloat(store.bp1A) : 0) +
        (store.bp2A < 10 ? parseFloat(store.bp2A) : 0) +
        (store.bp3A < 10 ? parseFloat(store.bp3A) : 0) +
        (store.bp4A < 10 ? parseFloat(store.bp4A) : 0) +
        (store.bp5A < 10 ? parseFloat(store.bp5A) : 0) +
        (store.bp6A < 10 ? parseFloat(store.bp6A) : 0)
      ).toFixed(2),
    },
    {
      idz1: "cul1",
      idz2: "cul2",
      id: "2",
      name: "Culture",

      cs: (
        (store.CS1B < 10 ? parseFloat(store.CS1B) : 0) +
        (store.CS2B < 10 ? parseFloat(store.CS2B) : 0)
      ).toFixed(2),

      tr: (
        (store.tr1B < 10 ? parseFloat(store.tr1B) : 0) +
        (store.tr2B < 10 ? parseFloat(store.tr2B) : 0)
      ).toFixed(2),
      bp: (
        (store.bp1B < 10 ? parseFloat(store.bp1B) : 0) +
        (store.bp2B < 10 ? parseFloat(store.bp2B) : 0)
      ).toFixed(2),
      Style: { backgroundColor: "red" },
    },
    {
      idz1: "assur1",
      idz2: "assur2",

      id: "3",
      name: "Assurance",

      cs: (
        (store.CS1C < 10 ? parseFloat(store.CS1C) : 0) +
        (store.CS2C < 10 ? parseFloat(store.CS2C) : 0)
      ).toFixed(2),

      tr: (
        (store.tr1C < 10 ? parseFloat(store.tr1C) : 0) +
        (store.tr2C < 10 ? parseFloat(store.tr2C) : 0)
      ).toFixed(2),
      bp: (
        (store.bp1C < 10 ? parseFloat(store.bp1C) : 0) +
        (store.bp2C < 10 ? parseFloat(store.bp2C) : 0)
      ).toFixed(2),
    },

    {
      idz1: "info1",
      idz2: "info2",

      id: "4",
      name: "Information Management",

      cs: (
        (store.CS1D < 10 ? parseFloat(store.CS1D) : 0) +
        (store.CS2D < 10 ? parseFloat(store.CS2D) : 0) +
        (store.CS3D < 10 ? parseFloat(store.CS3D) : 0)
      ).toFixed(2),

      tr: (
        (store.tr1D < 10 ? parseFloat(store.tr1D) : 0) +
        (store.tr2D < 10 ? parseFloat(store.tr2D) : 0) +
        (store.tr3D < 10 ? parseFloat(store.tr3D) : 0)
      ).toFixed(2),
      bp: (
        (store.bp1D < 10 ? parseFloat(store.bp1D) : 0) +
        (store.bp2D < 10 ? parseFloat(store.bp2D) : 0) +
        (store.bp3D < 10 ? parseFloat(store.bp3D) : 0)
      ).toFixed(2),
    },
    {
      idz1: "privacy1",
      idz2: "privacy2",

      id: "5",
      name: "Privacy Risk Assessment",

      cs: (
        (store.CS1E < 10 ? parseFloat(store.CS1E) : 0) +
        (store.CS2E < 10 ? parseFloat(store.CS2E) : 0) +
        (store.CS3E < 10 ? parseFloat(store.CS3E) : 0)
      ).toFixed(2),

      tr: (
        (store.tr1E < 10 ? parseFloat(store.tr1E) : 0) +
        (store.tr2E < 10 ? parseFloat(store.tr2E) : 0) +
        (store.tr3E < 10 ? parseFloat(store.tr3E) : 0)
      ).toFixed(2),

      bp: (
        (store.bp1E < 10 ? parseFloat(store.bp1E) : 0) +
        (store.bp2E < 10 ? parseFloat(store.bp2E) : 0) +
        (store.bp3E < 10 ? parseFloat(store.bp3E) : 0)
      ).toFixed(2),
    },
    {
      idz1: "prog1",
      idz2: "prog2",
      id: "6",
      name: "Privacy Programme",

      cs: (
        (store.CS1F < 10 ? parseFloat(store.CS1F) : 0) +
        (store.CS2F < 10 ? parseFloat(store.CS2F) : 0) +
        (store.CS3F < 10 ? parseFloat(store.CS3F) : 0) +
        (store.CS3F < 10 ? parseFloat(store.CS3F) : 0)
      ).toFixed(2),

      tr: (
        (store.tr1F < 10 ? parseFloat(store.tr1F) : 0) +
        (store.tr2F < 10 ? parseFloat(store.tr2F) : 0) +
        (store.tr3F < 10 ? parseFloat(store.tr3F) : 0) +
        (store.tr4F < 10 ? parseFloat(store.tr4F) : 0)
      ).toFixed(2),

      bp: (
        (store.bp1F < 10 ? parseFloat(store.bp1F) : 0) +
        (store.bp2F < 10 ? parseFloat(store.bp2F) : 0) +
        (store.bp3F < 10 ? parseFloat(store.bp3F) : 0) +
        (store.bp3F < 10 ? parseFloat(store.bp3F) : 0)
      ).toFixed(2),
    },
    {
      idz1: "business1",
      idz2: "business2",
      id: "7",
      name: "Business Processes",

      cs: (
        (store.CS1G < 10 ? parseFloat(store.CS1G) : 0) +
        (store.CS2G < 10 ? parseFloat(store.CS2G) : 0) +
        (store.CS3G < 10 ? parseFloat(store.CS3G) : 0) +
        (store.CS4G < 10 ? parseFloat(store.CS4G) : 0) +
        (store.CS5G < 10 ? parseFloat(store.CS5G) : 0) +
        (store.CS6G < 10 ? parseFloat(store.CS6G) : 0)
      ).toFixed(2),

      tr: (
        (store.tr1G < 10 ? parseFloat(store.tr1G) : 0) +
        (store.tr2G < 10 ? parseFloat(store.tr2G) : 0) +
        (store.tr3G < 10 ? parseFloat(store.tr3G) : 0) +
        (store.tr4G < 10 ? parseFloat(store.tr4G) : 0) +
        (store.tr5G < 10 ? parseFloat(store.tr5G) : 0) +
        (store.tr6G < 10 ? parseFloat(store.tr6G) : 0)
      ).toFixed(2),
      bp: (
        (store.bp1G < 10 ? parseFloat(store.bp1G) : 0) +
        (store.bp2G < 10 ? parseFloat(store.bp2G) : 0) +
        (store.bp3G < 10 ? parseFloat(store.bp3G) : 0) +
        (store.bp4G < 10 ? parseFloat(store.bp4G) : 0) +
        (store.bp5G < 10 ? parseFloat(store.bp5G) : 0) +
        (store.bp6G < 10 ? parseFloat(store.bp6G) : 0)
      ).toFixed(2),
    },
    {
      idz1: "ipp1",
      idz2: "ipp2",
      id: "8",
      name: "Implementation of the IPPs",

      cs: (
        (store.CS1H < 10 ? parseFloat(store.CS1H) : 0) +
        (store.CS2H < 10 ? parseFloat(store.CS2H) : 0) +
        (store.CS3H < 10 ? parseFloat(store.CS3H) : 0) +
        (store.CS4H < 10 ? parseFloat(store.CS4H) : 0) +
        (store.CS5H < 10 ? parseFloat(store.CS5H) : 0) +
        (store.CS6H < 10 ? parseFloat(store.CS6H) : 0) +
        (store.CS7H < 10 ? parseFloat(store.CS7H) : 0)
      ).toFixed(2),

      tr: (
        (store.tr1H < 10 ? parseFloat(store.tr1H) : 0) +
        (store.tr2H < 10 ? parseFloat(store.tr2H) : 0) +
        (store.tr3H < 10 ? parseFloat(store.tr3H) : 0) +
        (store.tr4H < 10 ? parseFloat(store.tr4H) : 0) +
        (store.tr5H < 10 ? parseFloat(store.tr5H) : 0) +
        (store.tr6H < 10 ? parseFloat(store.tr6H) : 0) +
        (store.tr7H < 10 ? parseFloat(store.tr7H) : 0)
      ).toFixed(2),
      bp: (
        (store.bp1H < 10 ? parseFloat(store.bp1H) : 0) +
        (store.bp2H < 10 ? parseFloat(store.bp2H) : 0) +
        (store.bp3H < 10 ? parseFloat(store.bp3H) : 0) +
        (store.bp4H < 10 ? parseFloat(store.bp4H) : 0) +
        (store.bp5H < 10 ? parseFloat(store.bp5H) : 0) +
        (store.bp6H < 10 ? parseFloat(store.bp6H) : 0) +
        (store.bp7H < 10 ? parseFloat(store.bp7H) : 0)
      ).toFixed(2),
    },
    {
      idz1: "breach1",
      idz2: "breach2",
      id: "9",
      name: "Breach and Incident Management",

      cs: (
        (store.CS1I < 10 ? parseFloat(store.CS1I) : 0) +
        (store.CS2I < 10 ? parseFloat(store.CS2I) : 0)
      ).toFixed(2),

      tr: (
        (store.tr1I < 10 ? parseFloat(store.tr1I) : 0) +
        (store.tr2I < 10 ? parseFloat(store.tr2I) : 0)
      ).toFixed(2),
      bp: (
        (store.bp1I < 10 ? parseFloat(store.bp1I) : 0) +
        (store.bp2I < 10 ? parseFloat(store.bp2I) : 0)
      ).toFixed(2),
    },
  ]);
  const [appr, setAppr] = React.useState("");
  const [Text, setText] = React.useState("Summary");

  const handleApprover = (event) => {
    setAppr(event.target.value);
    store.setAppr(event.target.value);
  };
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      store.setHeader("none");
      document.getElementById("down").style.display = "block";
      document.getElementById("sbmt").style.display = "block";
      document.getElementById("lbl").style.display = "block";
      document.getElementById("full_report").style.display = "block";
      setText("Summary");
    },
  });
  const hide = () => {
    document.getElementById("down").style.display = "none";
    document.getElementById("sbmt").style.display = "none";
    document.getElementById("lbl").style.display = "none";
    document.getElementById("full_report").style.display = "none";
    setText("Summary Report");
    store.setHeader("block");
    handlePrint();
    //
  };

  //
  const [modalShow, setModalShow] = React.useState(false);
  // const [Data, setData] = React.useState(products);
  // const [Col, setCol] = React.useState(columns);
  //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [products]);

  return useObserver(() => (
    <div>
      {/* <ReactToPrint
        trigger={() => (
          <button style={{ marginLeft: "25%", marginTop: "10%" }}>
            Print this out!
          </button>
        )}
        content={() => componentRef.current}
      /> */}
      <Box width="75%" ref={componentRef} ml="15%" pt={10}>
        <div style={{ display: store.header, marginBottom: "5%" }}>
          {" "}
          <Header content="Privacy Maturity Assessment" img={true} />
        </div>
        <h3 className="text-center"> {Text}</h3>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Reference</th>
              <th>Element</th>
              <th>Current State Assessment</th>
              <th>Target</th>
              <th>Better Practice</th>
            </tr>
          </thead>
          <tbody>
            {[
              {
                idz1: "gov1",
                idz2: "gov2",
                id: "1",
                name: "Governance, Leadership and Accountability",

                cs: (
                  (store.CS1A < 10 ? parseFloat(store.CS1A) : 0) +
                  (store.CS2A < 10 ? parseFloat(store.CS2A) : 0) +
                  (store.CS3A < 10 ? parseFloat(store.CS3A) : 0) +
                  (store.CS4A < 10 ? parseFloat(store.CS4A) : 0) +
                  (store.CS5A < 10 ? parseFloat(store.CS5A) : 0) +
                  (store.CS6A < 10 ? parseFloat(store.CS6A) : 0)
                ).toFixed(2),

                tr: (
                  (store.tr1A < 10 ? parseFloat(store.tr1A) : 0) +
                  (store.tr2A < 10 ? parseFloat(store.tr2A) : 0) +
                  (store.tr3A < 10 ? parseFloat(store.tr3A) : 0) +
                  (store.tr4A < 10 ? parseFloat(store.tr4A) : 0) +
                  (store.tr5A < 10 ? parseFloat(store.tr5A) : 0) +
                  (store.tr6A < 10 ? parseFloat(store.tr6A) : 0)
                ).toFixed(2),
                bp: (
                  (store.bp1A < 10 ? parseFloat(store.bp1A) : 0) +
                  (store.bp2A < 10 ? parseFloat(store.bp2A) : 0) +
                  (store.bp3A < 10 ? parseFloat(store.bp3A) : 0) +
                  (store.bp4A < 10 ? parseFloat(store.bp4A) : 0) +
                  (store.bp5A < 10 ? parseFloat(store.bp5A) : 0) +
                  (store.bp6A < 10 ? parseFloat(store.bp6A) : 0)
                ).toFixed(2),
              },
              {
                idz1: "cul1",
                idz2: "cul2",
                id: "2",
                name: "Culture",

                cs: (
                  (store.CS1B < 10 ? parseFloat(store.CS1B) : 0) +
                  (store.CS2B < 10 ? parseFloat(store.CS2B) : 0)
                ).toFixed(2),

                tr: (
                  (store.tr1B < 10 ? parseFloat(store.tr1B) : 0) +
                  (store.tr2B < 10 ? parseFloat(store.tr2B) : 0)
                ).toFixed(2),
                bp: (
                  (store.bp1B < 10 ? parseFloat(store.bp1B) : 0) +
                  (store.bp2B < 10 ? parseFloat(store.bp2B) : 0)
                ).toFixed(2),
                Style: { backgroundColor: "red" },
              },
              {
                idz1: "assur1",
                idz2: "assur2",

                id: "3",
                name: "Assurance",

                cs: (
                  (store.CS1C < 10 ? parseFloat(store.CS1C) : 0) +
                  (store.CS2C < 10 ? parseFloat(store.CS2C) : 0)
                ).toFixed(2),

                tr: (
                  (store.tr1C < 10 ? parseFloat(store.tr1C) : 0) +
                  (store.tr2C < 10 ? parseFloat(store.tr2C) : 0)
                ).toFixed(2),
                bp: (
                  (store.bp1C < 10 ? parseFloat(store.bp1C) : 0) +
                  (store.bp2C < 10 ? parseFloat(store.bp2C) : 0)
                ).toFixed(2),
              },

              {
                idz1: "info1",
                idz2: "info2",

                id: "4",
                name: "Information Management",

                cs: (
                  (store.CS1D < 10 ? parseFloat(store.CS1D) : 0) +
                  (store.CS2D < 10 ? parseFloat(store.CS2D) : 0) +
                  (store.CS3D < 10 ? parseFloat(store.CS3D) : 0)
                ).toFixed(2),

                tr: (
                  (store.tr1D < 10 ? parseFloat(store.tr1D) : 0) +
                  (store.tr2D < 10 ? parseFloat(store.tr2D) : 0) +
                  (store.tr3D < 10 ? parseFloat(store.tr3D) : 0)
                ).toFixed(2),
                bp: (
                  (store.bp1D < 10 ? parseFloat(store.bp1D) : 0) +
                  (store.bp2D < 10 ? parseFloat(store.bp2D) : 0) +
                  (store.bp3D < 10 ? parseFloat(store.bp3D) : 0)
                ).toFixed(2),
              },
              {
                idz1: "privacy1",
                idz2: "privacy2",

                id: "5",
                name: "Privacy Risk Assessment",

                cs: (
                  (store.CS1E < 10 ? parseFloat(store.CS1E) : 0) +
                  (store.CS2E < 10 ? parseFloat(store.CS2E) : 0) +
                  (store.CS3E < 10 ? parseFloat(store.CS3E) : 0)
                ).toFixed(2),

                tr: (
                  (store.tr1E < 10 ? parseFloat(store.tr1E) : 0) +
                  (store.tr2E < 10 ? parseFloat(store.tr2E) : 0) +
                  (store.tr3E < 10 ? parseFloat(store.tr3E) : 0)
                ).toFixed(2),

                bp: (
                  (store.bp1E < 10 ? parseFloat(store.bp1E) : 0) +
                  (store.bp2E < 10 ? parseFloat(store.bp2E) : 0) +
                  (store.bp3E < 10 ? parseFloat(store.bp3E) : 0)
                ).toFixed(2),
              },
              {
                idz1: "prog1",
                idz2: "prog2",
                id: "6",
                name: "Privacy Programme",

                cs: (
                  (store.CS1F < 10 ? parseFloat(store.CS1F) : 0) +
                  (store.CS2F < 10 ? parseFloat(store.CS2F) : 0) +
                  (store.CS3F < 10 ? parseFloat(store.CS3F) : 0) +
                  (store.CS3F < 10 ? parseFloat(store.CS3F) : 0)
                ).toFixed(2),

                tr: (
                  (store.tr1F < 10 ? parseFloat(store.tr1F) : 0) +
                  (store.tr2F < 10 ? parseFloat(store.tr2F) : 0) +
                  (store.tr3F < 10 ? parseFloat(store.tr3F) : 0) +
                  (store.tr4F < 10 ? parseFloat(store.tr4F) : 0)
                ).toFixed(2),

                bp: (
                  (store.bp1F < 10 ? parseFloat(store.bp1F) : 0) +
                  (store.bp2F < 10 ? parseFloat(store.bp2F) : 0) +
                  (store.bp3F < 10 ? parseFloat(store.bp3F) : 0) +
                  (store.bp3F < 10 ? parseFloat(store.bp3F) : 0)
                ).toFixed(2),
              },
              {
                idz1: "business1",
                idz2: "business2",
                id: "7",
                name: "Business Processes",

                cs: (
                  (store.CS1G < 10 ? parseFloat(store.CS1G) : 0) +
                  (store.CS2G < 10 ? parseFloat(store.CS2G) : 0) +
                  (store.CS3G < 10 ? parseFloat(store.CS3G) : 0) +
                  (store.CS4G < 10 ? parseFloat(store.CS4G) : 0) +
                  (store.CS5G < 10 ? parseFloat(store.CS5G) : 0) +
                  (store.CS6G < 10 ? parseFloat(store.CS6G) : 0)
                ).toFixed(2),

                tr: (
                  (store.tr1G < 10 ? parseFloat(store.tr1G) : 0) +
                  (store.tr2G < 10 ? parseFloat(store.tr2G) : 0) +
                  (store.tr3G < 10 ? parseFloat(store.tr3G) : 0) +
                  (store.tr4G < 10 ? parseFloat(store.tr4G) : 0) +
                  (store.tr5G < 10 ? parseFloat(store.tr5G) : 0) +
                  (store.tr6G < 10 ? parseFloat(store.tr6G) : 0)
                ).toFixed(2),
                bp: (
                  (store.bp1G < 10 ? parseFloat(store.bp1G) : 0) +
                  (store.bp2G < 10 ? parseFloat(store.bp2G) : 0) +
                  (store.bp3G < 10 ? parseFloat(store.bp3G) : 0) +
                  (store.bp4G < 10 ? parseFloat(store.bp4G) : 0) +
                  (store.bp5G < 10 ? parseFloat(store.bp5G) : 0) +
                  (store.bp6G < 10 ? parseFloat(store.bp6G) : 0)
                ).toFixed(2),
              },
              {
                idz1: "ipp1",
                idz2: "ipp2",
                id: "8",
                name: "Implementation of the IPPs",

                cs: (
                  (store.CS1H < 10 ? parseFloat(store.CS1H) : 0) +
                  (store.CS2H < 10 ? parseFloat(store.CS2H) : 0) +
                  (store.CS3H < 10 ? parseFloat(store.CS3H) : 0) +
                  (store.CS4H < 10 ? parseFloat(store.CS4H) : 0) +
                  (store.CS5H < 10 ? parseFloat(store.CS5H) : 0) +
                  (store.CS6H < 10 ? parseFloat(store.CS6H) : 0) +
                  (store.CS7H < 10 ? parseFloat(store.CS7H) : 0)
                ).toFixed(2),

                tr: (
                  (store.tr1H < 10 ? parseFloat(store.tr1H) : 0) +
                  (store.tr2H < 10 ? parseFloat(store.tr2H) : 0) +
                  (store.tr3H < 10 ? parseFloat(store.tr3H) : 0) +
                  (store.tr4H < 10 ? parseFloat(store.tr4H) : 0) +
                  (store.tr5H < 10 ? parseFloat(store.tr5H) : 0) +
                  (store.tr6H < 10 ? parseFloat(store.tr6H) : 0) +
                  (store.tr7H < 10 ? parseFloat(store.tr7H) : 0)
                ).toFixed(2),
                bp: (
                  (store.bp1H < 10 ? parseFloat(store.bp1H) : 0) +
                  (store.bp2H < 10 ? parseFloat(store.bp2H) : 0) +
                  (store.bp3H < 10 ? parseFloat(store.bp3H) : 0) +
                  (store.bp4H < 10 ? parseFloat(store.bp4H) : 0) +
                  (store.bp5H < 10 ? parseFloat(store.bp5H) : 0) +
                  (store.bp6H < 10 ? parseFloat(store.bp6H) : 0) +
                  (store.bp7H < 10 ? parseFloat(store.bp7H) : 0)
                ).toFixed(2),
              },
              {
                idz1: "breach1",
                idz2: "breach2",
                id: "9",
                name: "Breach and Incident Management",

                cs: (
                  (store.CS1I < 10 ? parseFloat(store.CS1I) : 0) +
                  (store.CS2I < 10 ? parseFloat(store.CS2I) : 0)
                ).toFixed(2),

                tr: (
                  (store.tr1I < 10 ? parseFloat(store.tr1I) : 0) +
                  (store.tr2I < 10 ? parseFloat(store.tr2I) : 0)
                ).toFixed(2),
                bp: (
                  (store.bp1I < 10 ? parseFloat(store.bp1I) : 0) +
                  (store.bp2I < 10 ? parseFloat(store.bp2I) : 0)
                ).toFixed(2),
              },
            ].map((x, index) => (
              <tr>
                <td id={x.idz1}>{index + 1}</td>
                <td id={x.idz2}>{x.name}</td>
                <td>{x.cs}</td>
                <td>{x.tr}</td>
                <td>{x.bp}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* <ToolkitProvider keyField="id" data={Data} columns={Col} search>
          {(props) => (
            <div>
              <hr />
              <BootstrapTable {...props.baseProps} />
             
            </div>
          )}
        </ToolkitProvider> */}
        <div style={{ marginTop: "3%" }} class="line"></div>

        <div hidden={true} class="row" id="lbl">
          <div class="col-md-1"></div>
          <div class="col-md-3">
            <label
              class="form-control"
              style={{
                color: "white",
                fontWeight: "bold",
                backgroundColor: "grey",
                marginRight: "-1px",
              }}
            >
              Enter Approver's Email
            </label>
          </div>
          <div class="col-md-8">
            <input
              type="email"
              class="form-control"
              id="supervisor_mail"
              placeholder=""
              onChange={handleApprover}
            />
          </div>
        </div>
        {store.readOnly === false ? (
          <div class="float-left" id="sbmt" style={{ marginLeft: "9%" }}>
            <button
              style={{ backgroundColor: "#3f51b5", width: "100px" }}
              onClick={() => {
                postData(store, 1, 1, userMessage, approverMessage);
                store.setSnackOpen(true);
                setModalShow(true);
              }}
              class="btn btn-primary"
            >
              Submit
            </button>
          </div>
        ) : (
          <>
            <div class="float-left" id="sbmt" style={{ marginLeft: "9%" }}>
              <button
                style={{ backgroundColor: "#3f51b5", width: "150px" }}
                onClick={() => {
                  postData(store, 1, 2);
                  history.push("/approvals");
                }}
                class="btn btn-primary"
              >
                Approve
              </button>
            </div>
            <div class="float-left" id="sbmt" style={{ marginLeft: "0%" }}>
              <button
                style={{ backgroundColor: "#3f51b5", width: "150px" }}
                onClick={() => {
                  postData(store, 1, 3);
                  history.push("/approvals");
                }}
                class="btn btn-primary"
              >
                Disapprove
              </button>
            </div>
          </>
        )}
        <div
          class="float-right"
          id="down"
          style={{ marginRight: "0%", marginBottom: "5%" }}
        >
          <button
            style={{ backgroundColor: "#3f51b5", width: "200px" }}
            onClick={hide}
            class="btn btn-primary"
          >
            Download Summary
          </button>
        </div>
        <div
          class="float-right"
          id="down"
          style={{ marginRight: "2%", marginBottom: "5%" }}
        >
          <button
            style={{ backgroundColor: "#3f51b5", width: "200px" }}
            onClick={() => {
              store.setHeader("block");
              store.setExpanded();
              props.history.push("/print");
            }}
            class="btn btn-primary"
            id="full_report"
          >
            Download Full Report
          </button>
        </div>
        {/* <Button hidden variant="primary" onClick={() => setModalShow(true)}>
          Launch demo modal
        </Button> */}
        <Example show={modalShow} onHide={() => setModalShow(false)} />
        <SuccessSnack />
        <div style={{ marginLeft: "-10%" }}>
          <Spider />
        </div>
        <Divider variant="middle" style={{ marginTop: "10%" }} />
      </Box>
    </div>
  ));
}

function Example(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Privacy Maturity Assessment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4></h4>
          <p>
            Thank you for submitting your assessment. It has been sent for
            approval. You will receive an email as soon as it is approved.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

//render(<Example />);
