import React from "react";
// import { useLocalStore, useObserver } from "mobx-react";
import { useLocalObservable } from "mobx-react-lite";
import { action } from "mobx";
//import getData from '../functions/getData'

export const StoreContext = React.createContext();


export const StoreProvider = (props) => {
   
  const store = useLocalObservable(() => ({


//   /// stepper ///
//   activeStep:0,
//   setActiveStep:action((e) => {store.activeStep = e }),
//   completed:{},
//   setCompleted:action((e) => {store.completed = e }),

//   steps:[
//    "Governance",
//    "Culture",
//    "Assurance",
//    "Management",
//    "Privacy",
//    "Programme",
//    "Processes",
//    "Implementation",
//    "Breaches",
//  ],

//   totalSteps : action(() => {
//    return store.steps.length;
//  }),

//   completedSteps : action(() => {
//    return Object.keys(store.completed).length;
//  }),

//   isLastStep : action(() => {
//    return store.activeStep === store.totalSteps() - 1;
//  }),

//  allStepsCompleted : action(() => {
//    return store.completedSteps() === store.totalSteps();
//  }),

//  handleNext : action(() => {
//     //let newActiveStep=1;
//    const newActiveStep =
//      store.isLastStep() && !store.allStepsCompleted()
//        ? // It's the last step, but not all steps have been completed,
//          // find the first step that has been completed
//          store.steps.findIndex((step, i) => !(i in store.completed))
//        :  store.setActiveStep(newActiveStep);
//       // props.history.push("/e" + parseInt(store.activeStep + 1));
//  }),
//  handleBack : action(() => {
//    store.setActiveStep((prevActiveStep) => prevActiveStep - 1);
//    props.history.push("/e" + parseInt(store.activeStep - 1));
//  }),

//  handleStep : (step) => action(() => {
//    store.setActiveStep(step);
//  }),

//  handleComplete : action(() => {
//    const newCompleted = store.completed;
//    newCompleted[store.activeStep] = true;
//    store.setCompleted(newCompleted);
//    store.handleNext();
//  }),

//  handleReset : action(() => {
//    store.setActiveStep(0);
//    store.setCompleted({});
//  })
//  ,




header:"none",
setHeader:action((e) => {store.header = e; }),

expanded: false,
setExpanded:action((e) => { store.header==="block"? store.expanded = true: store.expanded = false  }),

year: 0,
setYear:action((e) => {store.year = e; }),

quarter:0,
setQuarter:action((e) => {store.quarter = e; }),

isAdmin:false,
setIsAdmin:action((e) => {store.isAdmin = e; }),

userKey: localStorage.getItem("userKey")!==null && localStorage.getItem("userKey").length>0?localStorage.getItem("userKey"):"",
setUserKey:action((e) => {store.userKey = localStorage.getItem("uid")+"-"+store.year+"-"+store.quarter; }),

viewUser:undefined,
setViewUser:action((e) => {store.viewUser = e; }),

userToBeApproved:"",
setUserToBeApproved:action((e) => {store.userToBeApproved = e; }),

userToBeApprovedMail:"",
setUserToBeApprovedMail:action((e) => {store.userToBeApprovedMail = e; }),

userToBeApprovedAppr:"",
setUserToBeApprovedAppr:action((e) => {store.userToBeApprovedAppr = e; }),


decide:0,
setDecide:action((e) => {store.decide = e; }),

isApproved:-1,
setApprove:action((e) => {store.isApproved = e; }),

SnackOpen:false,
setSnackOpen:action((e) => {store.SnackOpen = e; }),



   user: localStorage.getItem("user")!==""
         && localStorage.getItem("user")!=="null"
          && localStorage.getItem("user")!==null?
           localStorage.getItem("user"):"",
           setUser:action((e) => {store.user = e; localStorage.setItem("user",e) }),
 
appr:"",
setAppr:action((e) => {store.appr = e }),
appr_email:"",
setApprEmail:action((e) => {store.appr = e }),

readOnly: false, // 
setReadOnly: action((e) => {store.readOnly = e }),

readOnlyUser: true, // 
setReadOnlyUser: action((e) => {store.readOnlyUser = e }),

userComment: false,
setUserComment: action((e) => {store.userComment = e }),

// --- element 1---//
   CS1A:10,
   setCS1A: action((e) => (store.CS1A = e)),
 
   CS2A:10,
   setCS2A: action((e) => (store.CS2A = e)),

   CS3A:10,
   setCS3A: action((e) => (store.CS3A = e)),

   CS4A:10,
   setCS4A: action((e) => (store.CS4A = e)),

   CS5A:10,
   setCS5A: action((e) => (store.CS5A = e)),

   CS6A:10,
   setCS6A: action((e) => (store.CS6A = e)),

// ------------------- //

   tr1A:10,
   setTr1A: action((e) => (store.tr1A = e)),

   tr2A:10,
   setTr2A: action((e) => (store.tr2A = e)),

   
   tr3A:10,
   setTr3A: action((e) => (store.tr3A = e)),

   tr4A:10,
   setTr4A: action((e) => (store.tr4A = e)),

   tr5A:10,
   setTr5A: action((e) => (store.tr5A = e)),

   tr6A:10,
   setTr6A: action((e) => (store.tr6A = e)),

   //------------------------------------//

   bp1A:10,
   setBp1A: action((e) => (store.bp1A = e)),

   bp2A:10,
   setBp2A: action((e) => (store.bp2A = e)),

   bp3A:10,
   setBp3A: action((e) => (store.bp3A = e)),

   bp4A:10,
   setBp4A: action((e) => (store.bp4A = e)),

   bp5A:10,
   setBp5A: action((e) => (store.bp5A = e)),

   bp6A:10,
   setBp6A: action((e) => (store.bp6A = e)),

   // ----------------------------- //

   comment1A:"",
   setComment1A: action((e) => (store.comment1A = e)),
  
   comment2A:"",
   setComment2A: action((e) => (store.comment2A = e)),

   comment3A:"",
   setComment3A: action((e) => (store.comment3A = e)),

   comment4A:"",
   setComment4A: action((e) => (store.comment4A = e)),



   comment5A:"",
   setComment5A: action((e) => (store.comment5A = e)),

   comment6A:"",
   setComment6A: action((e) => (store.comment6A = e)),


   // ----------------------------- //

   Apcomment1A:"",
   setApComment1A: action((e) => (store.Apcomment1A = e)),
  
   Apcomment2A:"",
   setApComment2A: action((e) => (store.Apcomment2A = e)),

   Apcomment3A:"",
   setApComment3A: action((e) => (store.Apcomment3A = e)),

   Apcomment4A:"",
   setApComment4A: action((e) => (store.Apcomment4A = e)),



   Apcomment5A:"",
   setApComment5A: action((e) => (store.Apcomment5A = e)),

   Apcomment6A:"",
   setApComment6A: action((e) => (store.Apcomment6A = e)),


// -------- element 2 ------//




CS1B:10,
setCS1B: action((e) => (store.CS1B = e)),

CS2B:10,
setCS2B: action((e) => (store.CS2B = e)),
// ------------------- //

tr1B:10,
setTr1B: action((e) => (store.tr1B = e)),

tr2B:10,
setTr2B: action((e) => (store.tr2B = e)),



//------------------------------------//

bp1B:10,
setBp1B: action((e) => (store.bp1B = e)),

bp2B:10,
setBp2B: action((e) => (store.bp2B = e)),
// ----------------------------- //

comment1B:"",
setComment1B: action((e) => (store.comment1B = e)),

comment2B:"",
setComment2B: action((e) => (store.comment2B = e)),

   // ----------------------------- //

   Apcomment1B:"",
   setApComment1B: action((e) => (store.Apcomment1B = e)),
  
   Apcomment2B:"",
   setApComment2B: action((e) => (store.Apcomment2B = e)),




// ------element 3 ----//

CS1C:10,
setCS1C: action((e) => (store.CS1C = e)),

CS2C:10,
setCS2C: action((e) => (store.CS2C = e)),
// ------------------- //

tr1C:10,
setTr1C: action((e) => (store.tr1C = e)),

tr2C:10,
setTr2C: action((e) => (store.tr2C = e)),



//------------------------------------//

bp1C:10,
setBp1C: action((e) => (store.bp1C = e)),

bp2C:10,
setBp2C: action((e) => (store.bp2C = e)),
// ----------------------------- //

comment1C:"",
setComment1C: action((e) => (store.comment1C = e)),

comment2C:"",
setComment2C: action((e) => (store.comment2C = e)),
// --------------------- //

Apcomment1C:"",
setApComment1C: action((e) => (store.Apcomment1C = e)),

Apcomment2C:"",
setApComment2C: action((e) => (store.Apcomment2C = e)),

// --------- element 4 ------ //

CS1D:10,
setCS1D: action((e) => (store.CS1D = e)),

CS2D:10,
setCS2D: action((e) => (store.CS2D = e)),

CS3D:10,
setCS3D: action((e) => (store.CS3D = e)),
// ------------------- //

tr1D:10,
setTr1D: action((e) => (store.tr1D = e)),

tr2D:10,
setTr2D: action((e) => (store.tr2D = e)),

tr3D:10,
setTr3D: action((e) => (store.tr3D = e)),



//------------------------------------//

bp1D:10,
setBp1D: action((e) => (store.bp1D = e)),

bp2D:10,
setBp2D: action((e) => (store.bp2D = e)),

bp3D:10,
setBp3D: action((e) => (store.bp3D = e)),
// ----------------------------- //

comment1D:"",
setComment1D: action((e) => (store.comment1D = e)),

comment2D:"",
setComment2D: action((e) => (store.comment2D = e)),

comment3D:"",
setComment3D: action((e) => (store.comment3D = e)),


   // ----------------------------- //

   Apcomment1D:"",
   setApComment1D: action((e) => (store.Apcomment1D = e)),
  
   Apcomment2D:"",
   setApComment2D: action((e) => (store.Apcomment2D = e)),

   Apcomment3D:"",
   setApComment3D: action((e) => (store.Apcomment3D = e)),





//---- ELEMENT 5 ----//


CS1E:10,
setCS1E: action((e) => (store.CS1E = e)),

CS2E:10,
setCS2E: action((e) => (store.CS2E = e)),

CS3E:10,
setCS3E: action((e) => (store.CS3E = e)),
// ------------------- //

tr1E:10,
setTr1E: action((e) => (store.tr1E = e)),

tr2E:10,
setTr2E: action((e) => (store.tr2E = e)),

tr3E:10,
setTr3E: action((e) => (store.tr3E = e)),



//------------------------------------//

bp1E:10,
setBp1E: action((e) => (store.bp1E = e)),

bp2E:10,
setBp2E: action((e) => (store.bp2E = e)),

bp3E:10,
setBp3E: action((e) => (store.bp3E = e)),
// ----------------------------- //

comment1E:"",
setComment1E: action((e) => (store.comment1E = e)),

comment2E:"",
setComment2E: action((e) => (store.comment2E = e)),

comment3E:"",
setComment3E: action((e) => (store.comment3E = e)),


   // ----------------------------- //

   Apcomment1E:"",
   setApComment1E: action((e) => (store.Apcomment1E = e)),
  
   Apcomment2E:"",
   setApComment2E: action((e) => (store.Apcomment2E = e)),

   Apcomment3E:"",
   setApComment3E: action((e) => (store.Apcomment3E = e)),




// ------- Element 6-----//



CS1F:10,
setCS1F: action((e) => (store.CS1F = e)),

CS2F:10,
setCS2F: action((e) => (store.CS2F = e)),

CS3F:10,
setCS3F: action((e) => (store.CS3F = e)),

CS4F:10,
setCS4F: action((e) => (store.CS4F = e)),
// ------------------- //

tr1F:10,
setTr1F: action((e) => (store.tr1F = e)),

tr2F:10,
setTr2F: action((e) => (store.tr2F = e)),

tr3F:10,
setTr3F: action((e) => (store.tr3F = e)),

tr4F:10,
setTr4F: action((e) => (store.tr4F = e)),

//------------------------------------//

bp1F:10,
setBp1F: action((e) => (store.bp1F = e)),

bp2F:10,
setBp2F: action((e) => (store.bp2F = e)),

bp3F:10,
setBp3F: action((e) => (store.bp3F = e)),

bp4F:10,
setBp4F: action((e) => (store.bp4F = e)),
// ----------------------------- //

comment1F:"",
setComment1F: action((e) => (store.comment1F = e)),

comment2F:"",
setComment2F: action((e) => (store.comment2F = e)),

comment3F:"",
setComment3F: action((e) => (store.comment3F = e)),

comment4F:"",
setComment4F: action((e) => (store.comment4F = e)),

   // ----------------------------- //

   Apcomment1F:"",
   setApComment1F: action((e) => (store.Apcomment1F = e)),
  
   Apcomment2F:"",
   setApComment2F: action((e) => (store.Apcomment2F = e)),

   Apcomment3F:"",
   setApComment3F: action((e) => (store.Apcomment3F = e)),

   Apcomment4F:"",
   setApComment4F: action((e) => (store.Apcomment4F = e)),


// ---- Element 7 ---- //


CS1G:10,
   setCS1G: action((e) => (store.CS1G = e)),
 
   CS2G:10,
   setCS2G: action((e) => (store.CS2G = e)),

   CS3G:10,
   setCS3G: action((e) => (store.CS3G = e)),

   CS4G:10,
   setCS4G: action((e) => (store.CS4G = e)),

   CS5G:10,
   setCS5G: action((e) => (store.CS5G = e)),

   CS6G:10,
   setCS6G: action((e) => (store.CS6G = e)),

// ------------------- //

   tr1G:10,
   setTr1G: action((e) => (store.tr1G = e)),

   tr2G:10,
   setTr2G: action((e) => (store.tr2G = e)),

   
   tr3G:10,
   setTr3G: action((e) => (store.tr3G = e)),

   tr4G:10,
   setTr4G: action((e) => (store.tr4G = e)),

   tr5G:10,
   setTr5G: action((e) => (store.tr5G = e)),

   tr6G:10,
   setTr6G: action((e) => (store.tr6G = e)),

   //------------------------------------//

   bp1G:10,
   setBp1G: action((e) => (store.bp1G = e)),

   bp2G:10,
   setBp2G: action((e) => (store.bp2G = e)),

   bp3G:10,
   setBp3G: action((e) => (store.bp3G = e)),

   bp4G:10,
   setBp4G: action((e) => (store.bp4G = e)),

   bp5G:10,
   setBp5G: action((e) => (store.bp5G = e)),

   bp6G:10,
   setBp6G: action((e) => (store.bp6G = e)),

   // ----------------------------- //

   comment1G:"",
   setComment1G: action((e) => (store.comment1G = e)),
  
   comment2G:"",
   setComment2G: action((e) => (store.comment2G = e)),

   comment3G:"",
   setComment3G: action((e) => (store.comment3G = e)),

   comment4G:"",
   setComment4G: action((e) => (store.comment4G = e)),



   comment5G:"",
   setComment5G: action((e) => (store.comment5G = e)),

   comment6G:"",
   setComment6G: action((e) => (store.comment6G = e)),


      // ----------------------------- //

      Apcomment1G:"",
      setApComment1G: action((e) => (store.Apcomment1G = e)),
     
      Apcomment2G:"",
      setApComment2G: action((e) => (store.Apcomment2G = e)),
   
      Apcomment3G:"",
      setApComment3G: action((e) => (store.Apcomment3G = e)),
   
      Apcomment4G:"",
      setApComment4G: action((e) => (store.Apcomment4G = e)),
   
   
   
      Apcomment5G:"",
      setApComment5G: action((e) => (store.Apcomment5G = e)),
   
      Apcomment6G:"",
      setApComment6G: action((e) => (store.Apcomment6G = e)),
   

//------- element 8 -----//

CS1H:10,
   setCS1H: action((e) => (store.CS1H = e)),
 
   CS2H:10,
   setCS2H: action((e) => (store.CS2H = e)),

   CS3H:10,
   setCS3H: action((e) => (store.CS3H = e)),

   CS4H:10,
   setCS4H: action((e) => (store.CS4H = e)),

   CS5H:10,
   setCS5H: action((e) => (store.CS5H = e)),

   CS6H:10,
   setCS6H: action((e) => (store.CS6H = e)),

   CS7H:10,
   setCS7H: action((e) => (store.CS7H = e)),

// ------------------- //

   tr1H:10,
   setTr1H: action((e) => (store.tr1H = e)),

   tr2H:10,
   setTr2H: action((e) => (store.tr2H = e)),

   
   tr3H:10,
   setTr3H: action((e) => (store.tr3H = e)),

   tr4H:10,
   setTr4H: action((e) => (store.tr4H = e)),

   tr5H:10,
   setTr5H: action((e) => (store.tr5H = e)),

   tr6H:10,
   setTr6H: action((e) => (store.tr6H = e)),

   tr7H:10,
   setTr7H: action((e) => (store.tr7H = e)),

   //------------------------------------//

   bp1H:10,
   setBp1H: action((e) => (store.bp1H = e)),

   bp2H:10,
   setBp2H: action((e) => (store.bp2H = e)),

   bp3H:10,
   setBp3H: action((e) => (store.bp3H = e)),

   bp4H:10,
   setBp4H: action((e) => (store.bp4H = e)),

   bp5H:10,
   setBp5H: action((e) => (store.bp5H = e)),

   bp6H:10,
   setBp6H: action((e) => (store.bp6H = e)),

   bp7H:10,
   setBp7H: action((e) => (store.bp7H = e)),

   // ----------------------------- //

   comment1H:"",
   setComment1H: action((e) => (store.comment1H = e)),
  
   comment2H:"",
   setComment2H: action((e) => (store.comment2H = e)),

   comment3H:"",
   setComment3H: action((e) => (store.comment3H = e)),

   comment4H:"",
   setComment4H: action((e) => (store.comment4H = e)),



   comment5H:"",
   setComment5H: action((e) => (store.comment5H = e)),

   comment6H:"",
   setComment6H: action((e) => (store.comment6H = e)),

   comment7H:"",
   setComment7H: action((e) => (store.comment7H = e)),

   // ----------------------------- //

   Apcomment1H:"",
   setApComment1H: action((e) => (store.Apcomment1H = e)),
  
   Apcomment2H:"",
   setApComment2H: action((e) => (store.Apcomment2H = e)),

   Apcomment3H:"",
   setApComment3H: action((e) => (store.Apcomment3H = e)),

   Apcomment4H:"",
   setApComment4H: action((e) => (store.Apcomment4H = e)),



   Apcomment5H:"",
   setApComment5H: action((e) => (store.Apcomment5H = e)),

   Apcomment6H:"",
   setApComment6H: action((e) => (store.Apcomment6H = e)),

   Apcomment7H:"",
   setApComment7H: action((e) => (store.Apcomment7H = e)),


///------ element 9 -----///
CS1I:10,
setCS1I: action((e) => (store.CS1I = e)),

CS2I:10,
setCS2I: action((e) => (store.CS2I = e)),
// ------------------- //

tr1I:10,
setTr1I: action((e) => (store.tr1I = e)),

tr2I:10,
setTr2I: action((e) => (store.tr2I = e)),



//------------------------------------//

bp1I:10,
setBp1I: action((e) => (store.bp1I = e)),

bp2I:10,
setBp2I: action((e) => (store.bp2I = e)),
// ----------------------------- //

comment1I:"",
setComment1I: action((e) => (store.comment1I = e)),

comment2I:"",
setComment2I: action((e) => (store.comment2I = e)),


Apcomment1I:"",
setApComment1I: action((e) => (store.Apcomment1I = e)),
Apcomment2I:"",
setApComment1I: action((e) => (store.Apcomment1I = e)),

// ---- summary ---- //

initializeData: action(user_data1=>{
    // --- element 1---//
  //store.setUser(user_data1.user_email)
  
  store.setCS1A(user_data1.CS1A)
  store.setCS2A(user_data1.CS2A)
  store.setCS3A(user_data1.CS31A)
  store.setCS4A(user_data1.CS4A)
  store.setCS5A(user_data1.CS5A)
  store.setCS6A(user_data1.CS6A)

// ------------------- //

  store.setTr1A(user_data1.tr1A)
  store.setTr2A(user_data1.tr2A)
  store.setTr3A(user_data1.tr3A)
  store.setTr4A(user_data1.tr4A)
  store.setTr5A(user_data1.tr5A)
  store.setTr6A(user_data1.tr6A)

  //------------------------------------//

  store.setBp1A(user_data1.bp1A)
  store.setBp2A(user_data1.bp2A)
  store.setBp3A(user_data1.bp3A)
  store.setBp4A(user_data1.bp4A)
  store.setBp5A(user_data1.bp5A)
  store.setBp6A(user_data1.bp6A)

  // ----------------------------- //

  store.setComment1A(user_data1.comment1A)
  store.setComment2A(user_data1.comment2A)
  store.setComment3A(user_data1.comment3A)
  store.setComment4A(user_data1.comment4A)
  store.setComment5A(user_data1.comment5A)
  store.setComment6A(user_data1.comment6A)

/// --------------------------------//
store.setApComment1A(user_data1.Apcomment1A)
store.setApComment2A(user_data1.Apcomment2A)
store.setApComment3A(user_data1.Apcomment3A)
store.setApComment4A(user_data1.Apcomment4A)
store.setApComment5A(user_data1.Apcomment5A)
store.setApComment6A(user_data1.Apcomment6A)

// -------- element 2 ------//


store.setCS1B(user_data1.CS1B)
store.setCS2B(user_data1.CS2B)
// ------------------- //

store.setTr1B(user_data1.tr1B)
store.setTr2B(user_data1.tr2B)



//------------------------------------//

store.setBp1B(user_data1.bp1B)
store.setBp2B(user_data1.bp2B)
// ----------------------------- //

store.setComment1B(user_data1.comment1B)
store.setComment2B(user_data1.comment2B)
//---------------------------///

store.setApComment1B(user_data1.Apcomment1B)
store.setApComment2B(user_data1.Apcomment2B)

// ------element 3 ----//

store.setCS1C(user_data1.CS1C)
store.setCS2C(user_data1.CS2C)
// ------------------- //

store.setTr1C(user_data1.tr1C)
store.setTr2C(user_data1.tr2C)



//------------------------------------//

store.setBp1C(user_data1.bp1C)
store.setBp2C(user_data1.bp2C)
// ----------------------------- //

store.setComment1C(user_data1.comment1C)
store.setComment2C(user_data1.comment2C)

//----------------------------'//
store.setApComment1C(user_data1.Apcomment1C)
store.setApComment2C(user_data1.Apcomment2C)

// --------- element 4 ------ //

store.setCS1D(user_data1.CS1D)
store.setCS2D(user_data1.CS2D)
store.setCS3D(user_data1.CS3D)
// ------------------- //

store.setTr1D(user_data1.tr1D)
store.setTr2D(user_data1.tr2D)
store.setTr3D(user_data1.tr3D)


//------------------------------------//

store.setBp1D(user_data1.bp1D)
store.setBp2D(user_data1.bp2D)
store.setBp3D(user_data1.bp3D)
// ----------------------------- //

store.setComment1D(user_data1.comment1D)
store.setComment2D(user_data1.comment2D)
store.setComment3D(user_data1.comment3D)

//---------------//
store.setApComment1D(user_data1.Apcomment1D)
store.setApComment2D(user_data1.Apcomment2D)
store.setApComment3D(user_data1.Apcomment3D)

//---- ELEMENT 5 ----//

store.setCS1E(user_data1.CS1E)
store.setCS2E(user_data1.CS2E)
store.setCS3E(user_data1.CS3E)
// ------------------- //

store.setTr1E(user_data1.tr1E)
store.setTr2E(user_data1.tr2E)
store.setTr3E(user_data1.tr3E)


//------------------------------------//

store.setBp1E(user_data1.bp1E)
store.setBp2E(user_data1.bp2E)
store.setBp3E(user_data1.bp3E)
// ----------------------------- //

store.setComment1E(user_data1.comment1E)
store.setComment2E(user_data1.comment2E)
store.setComment3E(user_data1.comment3E)

store.setApComment1E(user_data1.Apcomment1E)
store.setApComment2E(user_data1.Apcomment2E)
store.setApComment3E(user_data1.Apcomment3E)

// ------- Element 6-----//

store.setCS1F(user_data1.CS1F)
store.setCS2F(user_data1.CS2F)
store.setCS3F(user_data1.CS3F)
store.setCS4F(user_data1.CS4F)
// ------------------- //

store.setTr1F(user_data1.tr1F)
store.setTr2F(user_data1.tr2F)
store.setTr3F(user_data1.tr3F)
store.setTr4F(user_data1.tr4F)
//------------------------------------//

store.setBp1F(user_data1.bp1F)
store.setBp2F(user_data1.bp2F)
store.setBp3F(user_data1.bp3F)
store.setBp4F(user_data1.bp4F)
// ----------------------------- //

store.setComment1F(user_data1.comment1F)
store.setComment2F(user_data1.comment2F)
store.setComment3F(user_data1.comment3F)
store.setComment4F(user_data1.comment4F)

/// ------------------ ///
store.setApComment1F(user_data1.Apcomment1F)
store.setApComment2F(user_data1.Apcomment2F)
store.setApComment3F(user_data1.Apcomment3F)
store.setApComment4F(user_data1.Apcomment4F)


// ---- Element 7 ---- //


store.setCS1G(user_data1.CS1G)
  store.setCS2G(user_data1.CS2G)
  store.setCS3G(user_data1.CS3G)
  store.setCS4G(user_data1.CS4G)
  store.setCS5G(user_data1.CS5G)
  store.setCS6G(user_data1.CS6G)

// ------------------- //

  store.setTr1G(user_data1.tr1G)
  store.setTr2G(user_data1.tr2G)
  store.setTr3G(user_data1.tr3G)
  store.setTr4G(user_data1.tr4G)
  store.setTr5G(user_data1.tr5G)
  store.setTr6G(user_data1.tr6G)
  //------------------------------------//

  store.setBp1G(user_data1.bp1G)
  store.setBp2G(user_data1.bp2G)
  store.setBp3G(user_data1.bp3G)
  store.setBp4G(user_data1.bp4G)
  store.setBp5G(user_data1.bp5G)
  store.setBp6G(user_data1.bp6G)
  // ----------------------------- //

  store.setComment1G(user_data1.comment1G)
  store.setComment2G(user_data1.comment2G)
  store.setComment3G(user_data1.comment3G)
  store.setComment4G(user_data1.comment4G)
  store.setComment5G(user_data1.comment5G)
  store.setComment6G(user_data1.comment6G)

  // ----------------------//
  store.setApComment1G(user_data1.Apcomment1G)
  store.setApComment2G(user_data1.Apcomment2G)
  store.setApComment3G(user_data1.Apcomment3G)
  store.setApComment4G(user_data1.Apcomment4G)
  store.setApComment5G(user_data1.Apcomment5G)
  store.setApComment6G(user_data1.Apcomment6G)

//------- element 8 -----//

  store.setCS1H(user_data1.CS1H)
  store.setCS2H(user_data1.CS2H)
  store.setCS3H(user_data1.CS3H)
  store.setCS4H(user_data1.CS4H)
  store.setCS5H(user_data1.CS5H)
  store.setCS6H(user_data1.CS6H)
  store.setCS7H(user_data1.CS7H)
// ------------------- //

  store.setTr1H(user_data1.tr1H)
  store.setTr2H(user_data1.tr2H)
  store.setTr3H(user_data1.tr3H)
  store.setTr4H(user_data1.tr4H)
  store.setTr5H(user_data1.tr5H)
  store.setTr6H(user_data1.tr6H)
  store.setTr7H(user_data1.tr7H)
  //------------------------------------//

  store.setBp1H(user_data1.bp1H)
  store.setBp2H(user_data1.bp2H)
  store.setBp3H(user_data1.bp3H)
  store.setBp4H(user_data1.bp4H)
  store.setBp5H(user_data1.bp5H)
  store.setBp6H(user_data1.bp6H)
  store.setBp7H(user_data1.bp7H)
     // ----------------------------- //

  store.setComment1H(user_data1.comment1H)
  store.setComment2H(user_data1.comment2H)
  store.setComment3H(user_data1.comment3H)
  store.setComment4H(user_data1.comment4H)
  store.setComment5H(user_data1.comment5H)
  store.setComment6H(user_data1.comment6H)
  store.setComment7H(user_data1.comment7H)
// -----------------------------//
  store.setApComment1H(user_data1.Apcomment1H)
  store.setApComment2H(user_data1.Apcomment2H)
  store.setApComment3H(user_data1.Apcomment3H)
  store.setApComment4H(user_data1.Apcomment4H)
  store.setApComment5H(user_data1.Apcomment5H)
  store.setApComment6H(user_data1.Apcomment6H)
  store.setApComment7H(user_data1.Apcomment7H)

  ///------ element 9 -----///
store.setCS1I(user_data1.CS1I)
store.setCS2I(user_data1.CS2I)
store.setTr1I(user_data1.tr1I)
store.setTr2I(user_data1.tr2I)
//------------------------------------//

store.setBp1I(user_data1.bp1I)
store.setBp2I(user_data1.bp2I)
// ----------------------------- //

store.setComment1I(user_data1.comment1I)

store.setComment2I(user_data1.COmment2I)
  //------//

  store.setApComment1G(user_data1.Apcomment1I)
  store.setApComment2G(user_data1.Apcomment2I)

})









}));
return (
  <StoreContext.Provider value={store}>
    {props.children}
  </StoreContext.Provider>
);

};