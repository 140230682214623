import React, { useEffect, useContext } from "react";
import HighCharts from "highcharts";
import HighChartReact from "highcharts-react-official";
//var highcharts3d =require("highcharts-3d");
//require("highcharts/modules/highcharts-3d");
import firebase from "firebase";
import { StoreContext } from "../store/store";
import SuccessSnack from "./SuccessSnack";
export default function Donought(props) {
  const store = useContext(StoreContext);
  if (store.user === "") {
    // alert(localStorage.getItem("user"));

    props.history.push("/");
  }

  document.getElementById("root").style.backgroundColor = "white";
  function getData() {
    var Config = {
      apiKey: "AIzaSyADTVCa2hUtYT1iYe0W8U-NZY6NyFQJacQ",
      authDomain: "pma-survey.firebaseapp.com",
      databaseURL: "https://pma-survey-default-rtdb.firebaseio.com",
      projectId: "pma-survey",
      storageBucket: "pma-survey.appspot.com",
      messagingSenderId: "531631815606",
      appId: "1:531631815606:web:ae74c86ce905bddc95df17",
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(Config);
    } else {
      firebase.app(); // if already initialized, use that one
    }

    console.log(localStorage.getItem("uid"));
    let user_data = {};
    let user_data1 = {};
    let flag = false;
    firebase
      .database()
      .ref()
      .child("users")
      .orderByChild("userKey")
      .startAt(localStorage.getItem("uid"))
      .endAt(localStorage.getItem("uid") + "\uf8ff")
      // .equalTo(0)
      .on("value", function (snapshot) {
        user_data = snapshot.val();
        let userKEY = "";
        for (let i in user_data) {
          if (user_data[i].isApproved === 3) {
            user_data1 = user_data[i];
            flag = true;
            userKEY = user_data[i].userKey;
            break;
          }
          console.log(i);
        }
        if (flag === false)
          for (let i in user_data) {
            if (user_data[i].isApproved === 0) {
              user_data1 = user_data[i];
              flag = true;
              userKEY = user_data[i].userKey;
              break;
            }
            console.log(i);
          }
        console.log(user_data1);

        // if (user_data1.user_email !== store.user) {
        //   //console.log("inside if");
        //   // props.history.push("/");
        // } else
        {
          if (flag === true) {
            store.setYear(user_data1.year);
            store.setQuarter(user_data1.quarter);
            store.setUserKey(userKEY);
            store.setAppr(user_data1.appr);
            // --- element 1---//
            store.setApprove(user_data1.isApproved);
            store.setCS1A(user_data1.CS1A);
            store.setCS2A(user_data1.CS2A);
            store.setCS3A(user_data1.CS3A);
            store.setCS4A(user_data1.CS4A);
            store.setCS5A(user_data1.CS5A);
            store.setCS6A(user_data1.CS6A);

            // ------------------- //

            store.setTr1A(user_data1.tr1A);
            store.setTr2A(user_data1.tr2A);
            store.setTr3A(user_data1.tr3A);
            store.setTr4A(user_data1.tr4A);
            store.setTr5A(user_data1.tr5A);
            store.setTr6A(user_data1.tr6A);

            //------------------------------------//

            store.setBp1A(user_data1.bp1A);
            store.setBp2A(user_data1.bp2A);
            store.setBp3A(user_data1.bp3A);
            store.setBp4A(user_data1.bp4A);
            store.setBp5A(user_data1.bp5A);
            store.setBp6A(user_data1.bp6A);

            // ----------------------------- //

            store.setComment1A(user_data1.comment1A);
            store.setComment2A(user_data1.comment2A);
            store.setComment3A(user_data1.comment3A);
            store.setComment4A(user_data1.comment4A);
            store.setComment5A(user_data1.comment5A);
            store.setComment6A(user_data1.comment6A);

            /// --------------------------------//
            store.setApComment1A(user_data1.Apcomment1A);
            store.setApComment2A(user_data1.Apcomment2A);
            store.setApComment3A(user_data1.Apcomment3A);
            store.setApComment4A(user_data1.Apcomment4A);
            store.setApComment5A(user_data1.Apcomment5A);
            store.setApComment6A(user_data1.Apcomment6A);

            // -------- element 2 ------//

            store.setCS1B(user_data1.CS1B);
            store.setCS2B(user_data1.CS2B);
            // ------------------- //

            store.setTr1B(user_data1.tr1B);
            store.setTr2B(user_data1.tr2B);

            //------------------------------------//

            store.setBp1B(user_data1.bp1B);
            store.setBp2B(user_data1.bp2B);
            // ----------------------------- //

            store.setComment1B(user_data1.comment1B);
            store.setComment2B(user_data1.comment2B);

            //---------------------------///

            store.setApComment1B(user_data1.Apcomment1B);
            store.setApComment2B(user_data1.Apcomment2B);

            // ------element 3 ----//

            store.setCS1C(user_data1.CS1C);
            store.setCS2C(user_data1.CS2C);
            // ------------------- //

            store.setTr1C(user_data1.tr1C);
            store.setTr2C(user_data1.tr2C);

            //------------------------------------//

            store.setBp1C(user_data1.bp1C);
            store.setBp2C(user_data1.bp2C);
            // ----------------------------- //

            store.setComment1C(user_data1.comment1C);
            store.setComment2C(user_data1.comment2C);

            //----------------------------'//
            store.setApComment1C(user_data1.Apcomment1C);
            store.setApComment2C(user_data1.Apcomment2C);

            // --------- element 4 ------ //

            store.setCS1D(user_data1.CS1D);
            store.setCS2D(user_data1.CS2D);
            store.setCS3D(user_data1.CS3D);
            // ------------------- //

            store.setTr1D(user_data1.tr1D);
            store.setTr2D(user_data1.tr2D);
            store.setTr3D(user_data1.tr3D);

            //------------------------------------//

            store.setBp1D(user_data1.bp1D);
            store.setBp2D(user_data1.bp2D);
            store.setBp3D(user_data1.bp3D);
            // ----------------------------- //

            store.setComment1D(user_data1.comment1D);
            store.setComment2D(user_data1.comment2D);
            store.setComment3D(user_data1.comment3D);

            //---------------//
            store.setApComment1D(user_data1.Apcomment1D);
            store.setApComment2D(user_data1.Apcomment2D);
            store.setApComment3D(user_data1.Apcomment3D);

            //---- ELEMENT 5 ----//

            store.setCS1E(user_data1.CS1E);
            store.setCS2E(user_data1.CS2E);
            store.setCS3E(user_data1.CS3E);
            // ------------------- //

            store.setTr1E(user_data1.tr1E);
            store.setTr2E(user_data1.tr2E);
            store.setTr3E(user_data1.tr3E);

            //------------------------------------//

            store.setBp1E(user_data1.bp1E);
            store.setBp2E(user_data1.bp2E);
            store.setBp3E(user_data1.bp3E);
            // ----------------------------- //

            store.setComment1E(user_data1.comment1E);
            store.setComment2E(user_data1.comment2E);
            store.setComment3E(user_data1.comment3E);

            store.setApComment1E(user_data1.Apcomment1E);
            store.setApComment2E(user_data1.Apcomment2E);
            store.setApComment3E(user_data1.Apcomment3E);

            // ------- Element 6-----//

            store.setCS1F(user_data1.CS1F);
            store.setCS2F(user_data1.CS2F);
            store.setCS3F(user_data1.CS3F);
            store.setCS4F(user_data1.CS4F);
            // ------------------- //

            store.setTr1F(user_data1.tr1F);
            store.setTr2F(user_data1.tr2F);
            store.setTr3F(user_data1.tr3F);
            store.setTr4F(user_data1.tr4F);
            //------------------------------------//

            store.setBp1F(user_data1.bp1F);
            store.setBp2F(user_data1.bp2F);
            store.setBp3F(user_data1.bp3F);
            store.setBp4F(user_data1.bp4F);
            // ----------------------------- //

            store.setComment1F(user_data1.comment1F);
            store.setComment2F(user_data1.comment2F);
            store.setComment3F(user_data1.comment3F);
            store.setComment4F(user_data1.comment4F);

            /// ------------------ ///
            store.setApComment1F(user_data1.Apcomment1F);
            store.setApComment2F(user_data1.Apcomment2F);
            store.setApComment3F(user_data1.Apcomment3F);
            store.setApComment4F(user_data1.Apcomment4F);
            // ---- Element 7 ---- //

            store.setCS1G(user_data1.CS1G);
            store.setCS2G(user_data1.CS2G);
            store.setCS3G(user_data1.CS3G);
            store.setCS4G(user_data1.CS4G);
            store.setCS5G(user_data1.CS5G);
            store.setCS6G(user_data1.CS6G);

            // ------------------- //

            store.setTr1G(user_data1.tr1G);
            store.setTr2G(user_data1.tr2G);
            store.setTr3G(user_data1.tr3G);
            store.setTr4G(user_data1.tr4G);
            store.setTr5G(user_data1.tr5G);
            store.setTr6G(user_data1.tr6G);
            //------------------------------------//

            store.setBp1G(user_data1.bp1G);
            store.setBp2G(user_data1.bp2G);
            store.setBp3G(user_data1.bp3G);
            store.setBp4G(user_data1.bp4G);
            store.setBp5G(user_data1.bp5G);
            store.setBp6G(user_data1.bp6G);
            // ----------------------------- //

            store.setComment1G(user_data1.comment1G);
            store.setComment2G(user_data1.comment2G);
            store.setComment3G(user_data1.comment3G);
            store.setComment4G(user_data1.comment4G);
            store.setComment5G(user_data1.comment5G);
            store.setComment6G(user_data1.comment6G);

            // ----------------------//
            store.setApComment1G(user_data1.Apcomment1G);
            store.setApComment2G(user_data1.Apcomment2G);
            store.setApComment3G(user_data1.Apcomment3G);
            store.setApComment4G(user_data1.Apcomment4G);
            store.setApComment5G(user_data1.Apcomment5G);
            store.setApComment6G(user_data1.Apcomment6G);
            //------- element 8 -----//

            store.setCS1H(user_data1.CS1H);
            store.setCS2H(user_data1.CS2H);
            store.setCS3H(user_data1.CS3H);
            store.setCS4H(user_data1.CS4H);
            store.setCS5H(user_data1.CS5H);
            store.setCS6H(user_data1.CS6H);
            store.setCS7H(user_data1.CS7H);
            // ------------------- //

            store.setTr1H(user_data1.tr1H);
            store.setTr2H(user_data1.tr2H);
            store.setTr3H(user_data1.tr3H);
            store.setTr4H(user_data1.tr4H);
            store.setTr5H(user_data1.tr5H);
            store.setTr6H(user_data1.tr6H);
            store.setTr7H(user_data1.tr7H);
            //------------------------------------//

            store.setBp1H(user_data1.bp1H);
            store.setBp2H(user_data1.bp2H);
            store.setBp3H(user_data1.bp3H);
            store.setBp4H(user_data1.bp4H);
            store.setBp5H(user_data1.bp5H);
            store.setBp6H(user_data1.bp6H);
            store.setBp7H(user_data1.bp7H);
            // ----------------------------- //

            store.setComment1H(user_data1.comment1H);
            store.setComment2H(user_data1.comment2H);
            store.setComment3H(user_data1.comment3H);
            store.setComment4H(user_data1.comment4H);
            store.setComment5H(user_data1.comment5H);
            store.setComment6H(user_data1.comment6H);
            store.setComment7H(user_data1.comment7H);

            // -----------------------------//
            store.setApComment1H(user_data1.Apcomment1H);
            store.setApComment2H(user_data1.Apcomment2H);
            store.setApComment3H(user_data1.Apcomment3H);
            store.setApComment4H(user_data1.Apcomment4H);
            store.setApComment5H(user_data1.Apcomment5H);
            store.setApComment6H(user_data1.Apcomment6H);
            store.setApComment7H(user_data1.Apcomment7H);

            ///------ element 9 -----///
            store.setCS1I(user_data1.CS1I);
            store.setCS2I(user_data1.CS2I);
            store.setTr1I(user_data1.tr1I);
            store.setTr2I(user_data1.tr2I);
            //------------------------------------//

            store.setBp1I(user_data1.bp1I);
            store.setBp2I(user_data1.bp2I);
            // ----------------------------- //

            store.setComment1I(user_data1.comment1I);

            store.setComment2I(user_data1.comment2I);

            //------//

            store.setApComment1G(user_data1.Apcomment1I);
            store.setApComment2G(user_data1.Apcomment2I);
            localStorage.setItem(
              "userKey",
              localStorage.getItem("uid") +
                "-" +
                store.year +
                "-" +
                store.quarter
            );
            props.history.push("/e1");
          } else {
            // alert("body");
            store.setSnackOpen(true);
            //alert("body");
          }
        }
      }); //console.log("abcd"+messagesRef)
  }

  const displayHandler = (key) => {
    switch (key) {
      case "1": {
        props.history.push("/e1");
        break;
      }
      case "2": {
        props.history.push("/e2");
        break;
      }
      case "3": {
        props.history.push("/e3");
        break;
      }
      case "4": {
        props.history.push("/e4");
        break;
      }
      case "5": {
        props.history.push("/e5");
        break;
      }
      case "6": {
        props.history.push("/e6");
        break;
      }
      case "7": {
        props.history.push("/e7");
        break;
      }
      case "8": {
        props.history.push("/e8");
        break;
      }
      case "9": {
        props.history.push("/e9");
        break;
      }

      default:
      // code block
    }
  };

  const options = {
    chart: {
      type: "pie",
      options3d: {
        enabled: true,
        alpha: 45,
      },
    },
    credits: {
      enabled: false,
    },

    title: {
      text: " Privacy<br>    Maturity<br> <sapn>   Assessment<span>",
      align: "center",
      verticalAlign: "middle",
      y: 15,
    },
    xAxis: {
      type: "category",
    },
    plotOptions: {
      series: {
        innerSize: "65%",
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              displayHandler(this.options.key);
            },
          },
        },
      },
    },

    series: [
      {
        dataLabels: {
          style: {
            fontSize: "17px",
            fontFamily: "roboto",
          },
        },
        tooltip: {
          pointFormat: "",
        },
        data: [
          {
            y: 120.4,
            name: "Governance, Leadership & Accountability",
            key: "1",
          },
          {
            y: 120.4,
            name: "Culture",
            key: "2",
          },
          {
            y: 120.4,
            name: "Assurance",
            key: "3",
          },
          {
            y: 120.4,
            name: "Information Management",
            key: "4",
          },
          {
            y: 120.4,
            name: "Privacy Risk Assessment",
            key: "5",
          },
          {
            y: 120.4,
            name: "Privacy Programme",
            key: "6",
          },
          {
            y: 120.4,
            name: "Business Processes",
            key: "7",
          },
          {
            y: 120.4,
            name: "Implementation of IPPs",
            key: "8",
          },
          {
            y: 120.4,
            name: "Breach & Incident Management",
            key: "9",
          },
        ],
      },
    ],
  };
  // useEffect(() =>
  //  getData(), []);
  return (
    <>
      <SuccessSnack
        content="You do not have an incomplete or a dissaproved assessment to continue. Please begin a new assessment."
        type="warning"
      />
      <div style={{ marginTop: "8%" }}>
        <HighChartReact highcharts={HighCharts} options={options} />
      </div>
      <div className="row" style={{ marginTop: "5%", marginBottom: "5%" }}>
        <div className="col-md-3"></div>
        <div className="col-md-4" style={{ marginLeft: "4%" }}>
          {" "}
          <button
            onClick={() => {
              getData();
            }}
            style={{ backgroundColor: "#3f51b5" }}
            className="btn btn-primary"
          >
            Continue Assessment
          </button>
        </div>
        <div className="col-md-4">
          <button
            onClick={() => {
              props.history.push("/start");
            }}
            style={{ backgroundColor: "#3f51b5" }}
            className="btn btn-primary"
          >
            Begin New Assesment
          </button>
        </div>
      </div>
    </>
  );
}
