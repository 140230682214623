import { React, useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import firebase from "firebase";
import { StoreContext } from "../store/store";
import SuccessSnack from "./SuccessSnack";
//import Alert from "@material-ui/lab/Alert";
//import Snackbar from "@material-ui/core/Snackbar";
//import MuiAlert from "@material-ui/lab/Alert";
// import axios from "axios";

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

var Config = {
  apiKey: "AIzaSyADTVCa2hUtYT1iYe0W8U-NZY6NyFQJacQ",
  authDomain: "pma-survey.firebaseapp.com",
  databaseURL: "https://pma-survey-default-rtdb.firebaseio.com",
  projectId: "pma-survey",
  storageBucket: "pma-survey.appspot.com",
  messagingSenderId: "531631815606",
  appId: "1:531631815606:web:ae74c86ce905bddc95df17",
};
if (!firebase.apps.length) {
  firebase.initializeApp(Config);
} else {
  firebase.app(); // if already initialized, use that one
}

export default function SignIn(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("user");
  const cc = urlParams.get("appr");
  const store = useContext(StoreContext);
  if (
    myParam !== null &&
    myParam !== undefined
    //myParam.length !== "undefined"
  ) {
    //alert("shit");
    localStorage.setItem("user", myParam);
    store.setUser(myParam);
    store.setReadOnly(true);
    store.setReadOnlyUser(false);

    store.setApprEmail(cc);
    props.history.push("/start");
  }

  // console.log(myParam + "dskljfkl");

  if (
    localStorage.getItem("user") !== "" &&
    localStorage.getItem("user") !== "null" &&
    localStorage.getItem("user") !== null &&
    localStorage.getItem("user") !== undefined
  ) {
    // alert(localStorage.getItem("user"));
    props.history.push("/index");
  }
  const classes = useStyles();
  let data = {};
  //console.log(props);

  function login(email, pass) {
    //alert(passwordz);

    firebase
      .auth()
      .signInWithEmailAndPassword(email, pass)
      .then((userCredential) => {
        // Signed in
        let user = userCredential.user;
        console.log(user);
        let user1 = firebase.auth().currentUser;

        if (user1 != null) {
          user1.providerData.forEach(function (profile) {
            if (user.email === "admin@octure.com") {
              // store.setUser(user.email);
              store.setIsAdmin(true);
              store.setUserKey(user.uid);
              props.history.push("/admin");
            } else {
              //alert("else");
              localStorage.setItem("user", user.email);
              localStorage.setItem("uid", user.uid);
              store.setUser(user.email);
              // alert(localStorage.getItem("uid"));
              //  firebase.delete();
              props.history.push("/index");
            }
          });
        }
      })
      .catch((error) => {
        store.setSnackOpen(true);
        //alert("");
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorMessage);
        // var user = firebase.auth().currentUser;
      });
  }

  const [uname, setName] = useState("");

  const nameSetter = (event) => {
    setName(event.target.value);
  };

  const [pass, setPass] = useState("");
  const passSetter = (event) => {
    setPass(event.target.value);
  };

  const submitHandler = () => {
    login(uname, pass);
  };

  return (
    <Container component="main" style={{ marginTop: "11%" }} maxWidth="xs">
      {/* <Alert hidden severity="error">
        This is an error message!
      </Alert> */}
      {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          email or password not correct
        </Alert>
      </Snackbar>
      <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
        <Alert onClose={handleClose1} severity="success">
          login successfull
        </Alert>
      </Snackbar> */}

      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" dir="ltr">
          Login
        </Typography>
        {/* <form className={classes.form} onSubmit={submitHandler} noValidate> */}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={nameSetter}
          dir="ltr"
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          dir="ltr"
          onChange={passSetter}
          onKeyUp={(e) => {
            if (e.keyCode == 13) {
              // alert("invoked");
              submitHandler();
              //  validate(); doesn't need to be called from here
            }
          }}
          autoComplete="current-password"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember Me"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={submitHandler}
          className={classes.submit}
          dir="ltr"
        >
          Login
        </Button>
        <Grid container>
          <Grid item xs>
            <Link to="/forget" dir="ltr">
              {" "}
              Forgot Password?
            </Link>
          </Grid>
          <Grid item>
            <Link
              to="/Register"
              variant="body2"
              dir={localStorage.getItem("lang") == 0 ? "ltr" : "rtl"}
            >
              Create New Account ? Sign Up
            </Link>
          </Grid>
        </Grid>
        {/* </form> */}
      </div>
      <Box mt={8}></Box>
      <SuccessSnack
        content="The email address/password provided is incorrect."
        type="error"
      />
    </Container>
  );
}
