import React, { useContext, useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { StoreContext } from "../store/store";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import firebase from "firebase";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import SuccessSnack from "./SuccessSnack";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    marginLeft: "15%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
}));

export default function PreStart(props) {
  const classes = useStyles();
  const store = useContext(StoreContext);
  if (store.user === "") {
    // alert(localStorage.getItem("user"));

    props.history.push("/");
  }

  // ---- Current State -----//

  const [OpenCS1, setOpenCS1] = React.useState(false);

  const handleChangeYear = (event) => {
    // setCS1(event.target.value);
    store.setYear(event.target.value);
  };
  const handleCloseCS1 = () => {
    setOpenCS1(false);
  };
  const handleOpenCS1 = () => {
    setOpenCS1(true);
  };

  const [Open, setOpen] = React.useState(false);

  const handleChangeQuarter = (event) => {
    //setCS2(event.target.value);
    store.setQuarter(event.target.value);
  };
  const handleCloseQuarter = () => {
    setOpen(false);
  };
  const handleOpenQuarter = () => {
    setOpen(true);
  };

  const handleApprover = (event) => {
    store.setAppr(event.target.value);
  };

  const [message, setMessage] = React.useState("");

  const [DissapproveFlag, setDissapproveFlag] = React.useState(false);
  const [IncompleteFlag, setIncompleteFlag] = React.useState(false);
  const [PendingFlag, setPendingFlag] = React.useState(false);

  function getData(validate) {
    var Config = {
      apiKey: "AIzaSyADTVCa2hUtYT1iYe0W8U-NZY6NyFQJacQ",
      authDomain: "pma-survey.firebaseapp.com",
      databaseURL: "https://pma-survey-default-rtdb.firebaseio.com",
      projectId: "pma-survey",
      storageBucket: "pma-survey.appspot.com",
      messagingSenderId: "531631815606",
      appId: "1:531631815606:web:ae74c86ce905bddc95df17",
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(Config);
    } else {
      firebase.app(); // if already initialized, use that one
    }

    console.log(localStorage.getItem("uid"));
    let user_data = {};
    let user_data1 = {};

    const messageDissapproved = `You have a disapproved assessment in your account. Please review, make necessary changes, and resubmit the disapproved assessment before starting a new assessment.`;
    const messagePending = `You have an assessment pending for approval. You cannot start a new assessment while you have an assessment pending for approval. Please wait for it to be approved.`;
    const messageIncomplete = `Are you sure you want to start a new assessment? You have an incomplete assessment in your account. Your incomplete assessment will be discarded if you continue.`;
    const messageApproved = `You already have an approved assessment for quarter ${store.quarter} of year ${store.year}. Please select a different quarter to continue.`;

    let ApprovedFlag = false;
    let Key =
      validate === true
        ? localStorage.getItem("userKey")
        : localStorage.getItem("uid");
    firebase
      .database()
      .ref()
      .child("users")
      .orderByChild("userKey")
      .startAt(Key)
      .endAt(Key + "\uf8ff")
      // .equalTo(0)
      .on("value", function (snapshot) {
        user_data = snapshot.val();

        for (let i in user_data) {
          if (validate === true) {
            if (user_data[i].isApproved === 2) {
              // user_data1 = user_data[i];
              setDissapproveFlag(true);
              setMessage(messageApproved);
              setModalShow(true);
              ApprovedFlag = true;
              localStorage.setItem("userKey", "");
              break;
            }
          }
          {
            /// disapproved ///
            if (user_data[i].isApproved === 3) {
              // user_data1 = user_data[i];
              setDissapproveFlag(true);
              setMessage(messageDissapproved);
              setModalShow(true);
              break;
            }
            /// incomplete ///
            if (user_data[i].isApproved === 0) {
              // user_data1 = user_data[i];
              setIncompleteFlag(true);
              setMessage(messageIncomplete);
              setModalShow(true);
              break;
            }
            // pending approval //
            if (user_data[i].isApproved === 1) {
              // user_data1 = user_data[i];
              setPendingFlag(true);
              setMessage(messagePending);
              setModalShow(true);

              break;
            }
          }
          console.log(i);
        }
        console.log(user_data1);
        if (ApprovedFlag === false && validate === true) {
          store.setUserKey();

          //alert(store.userKey);
          props.history.push("/e1");
        }
        // if (
        // PendingFlag === false &&
        //   IncompleteFlag === false &&
        //   DissapproveFlag == false;
        // ) {
        //   // props.history.push("/e1");
        // } //else props.history.push("/start");
      }); //console.log("abcd"+messagesRef)
  }

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  const [modalShow, setModalShow] = React.useState(false);
  const [continueIncomplete, setContinue] = React.useState(false);

  return useObserver(() => (
    <div id="Element3" className={classes.root} style={{ marginTop: "8%" }}>
      <div style={{ marginBottom: "3%" }}>
        <h3 style={{ marginLeft: "-10%" }} className="text-center"></h3>
        <p></p>
      </div>{" "}
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Select Year</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS1}
            onClose={handleCloseCS1}
            onOpen={handleOpenCS1}
            value={store.year}
            onChange={handleChangeYear}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={0}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={2021}>2021</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Select Quarter</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={Open}
            onClose={handleCloseQuarter}
            onOpen={handleOpenQuarter}
            value={store.quarter}
            onChange={handleChangeQuarter}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={0}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={1}>1st</MenuItem>
            <MenuItem value={2}>2nd</MenuItem>
            <MenuItem value={3}>3rd</MenuItem>
            <MenuItem value={4}>4th</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      ></Box>
      <div class="row" id="lbl">
        <div class="col-md-3">
          <label
            class="form-control"
            style={{
              color: "white",
              fontWeight: "bold",
              backgroundColor: "grey",
              //   marginRight: "-1px",
            }}
          >
            Enter Approver's Email
          </label>
        </div>
        <div class="col-md-8">
          <input
            type="email"
            class="form-control"
            id="supervisor_mail"
            placeholder=""
            onChange={handleApprover}
          />
        </div>
      </div>
      <div className="row">
        <div className="float-left" style={{ marginLeft: 14, marginBottom: 4 }}>
          <button
            onClick={() => {
              localStorage.setItem(
                "userKey",
                localStorage.getItem("uid") +
                  "-" +
                  store.year +
                  "-" +
                  store.quarter
              );
              localStorage.setItem("year", store.year);
              localStorage.setItem("quarter", store.quarter);
              //store.setUserKey();
              getData(true);
              //alert(store.userKey);
              // props.history.push("/e1");
            }}
            style={{ backgroundColor: "#3f51b5" }}
            className="btn btn-primary"
          >
            Begin Assessment
          </button>
        </div>
      </div>
      <Example
        show={modalShow}
        msg={message}
        incomplete={IncompleteFlag}
        onHide={(check) => {
          setModalShow(false);
          if (check === true) props.history.push("/index");
        }}
      />
    </div>
  ));
}

function Example(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Privacy Maturity Assessment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4></h4>
          <p>{props.msg}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.onHide(true);
            }}
          >
            Close
          </Button>
          <button
            style={{ backgroundColor: "#3f51b5" }}
            className="btn btn-primary"
            onClick={() => {
              props.onHide(false);
            }}
            hidden={!props.incomplete}
          >
            Continue
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
