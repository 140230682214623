import React, { useContext, useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { StoreContext } from "../store/store";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import firebase from "firebase";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import SuccessSnack from "./SuccessSnack";
import postData from "../functions/postData";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Spider4 from "./spider4";
import Header from "./Header";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    marginLeft: "11%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
}));

const AccordionSummary = withStyles({
  content: {
    flexGrow: 0,
  },
})(MuiAccordionSummary);

export default function Element4(props) {
  const classes = useStyles();
  const store = useContext(StoreContext);
  if (store.userKey === "") {
    // alert(localStorage.getItem("user"));

    props.history.push("/");
  }
  function getData() {
    var Config = {
      apiKey: "AIzaSyADTVCa2hUtYT1iYe0W8U-NZY6NyFQJacQ",
      authDomain: "pma-survey.firebaseapp.com",
      databaseURL: "https://pma-survey-default-rtdb.firebaseio.com",
      projectId: "pma-survey",
      storageBucket: "pma-survey.appspot.com",
      messagingSenderId: "531631815606",
      appId: "1:531631815606:web:ae74c86ce905bddc95df17",
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(Config);
    } else {
      firebase.app(); // if already initialized, use that one
    }

    console.log(localStorage.getItem("uid"));
    let user_data = {};
    let user_data1 = {};
    let user;
    if (store.decide === 1) {
      user = store.userToBeApproved;
      store.setReadOnly(false);
      store.setReadOnlyUser(true);
    } else {
      user = store.userKey;
      store.setReadOnly(false);
      store.setReadOnlyUser(true);
    }
    firebase
      .database()
      .ref()
      .child("users")
      .orderByChild("userKey")
      .equalTo(user)
      .on("value", function (snapshot) {
        user_data = snapshot.val();

        for (let i in user_data) {
          user_data1 = user_data[i];
          break;
        }
        console.log(user_data1);

        if (user_data1.user_email !== store.user) {
          console.log("inside if");
        } else {
          // --- element 1---//
          store.setApprove(user_data1.isApproved);
          store.setAppr(user_data1.appr);

          store.setCS1A(user_data1.CS1A);
          store.setCS2A(user_data1.CS2A);
          store.setCS3A(user_data1.CS3A);
          store.setCS4A(user_data1.CS4A);
          store.setCS5A(user_data1.CS5A);
          store.setCS6A(user_data1.CS6A);

          // ------------------- //

          store.setTr1A(user_data1.tr1A);
          store.setTr2A(user_data1.tr2A);
          store.setTr3A(user_data1.tr3A);
          store.setTr4A(user_data1.tr4A);
          store.setTr5A(user_data1.tr5A);
          store.setTr6A(user_data1.tr6A);

          //------------------------------------//

          store.setBp1A(user_data1.bp1A);
          store.setBp2A(user_data1.bp2A);
          store.setBp3A(user_data1.bp3A);
          store.setBp4A(user_data1.bp4A);
          store.setBp5A(user_data1.bp5A);
          store.setBp6A(user_data1.bp6A);

          // ----------------------------- //

          store.setComment1A(user_data1.comment1A);
          store.setComment2A(user_data1.comment2A);
          store.setComment3A(user_data1.comment3A);
          store.setComment4A(user_data1.comment4A);
          store.setComment5A(user_data1.comment5A);
          store.setComment6A(user_data1.comment6A);

          /// --------------------------------//
          store.setApComment1A(user_data1.Apcomment1A);
          store.setApComment2A(user_data1.Apcomment2A);
          store.setApComment3A(user_data1.Apcomment3A);
          store.setApComment4A(user_data1.Apcomment4A);
          store.setApComment5A(user_data1.Apcomment5A);
          store.setApComment6A(user_data1.Apcomment6A);

          // -------- element 2 ------//

          store.setCS1B(user_data1.CS1B);
          store.setCS2B(user_data1.CS2B);
          // ------------------- //

          store.setTr1B(user_data1.tr1B);
          store.setTr2B(user_data1.tr2B);

          //------------------------------------//

          store.setBp1B(user_data1.bp1B);
          store.setBp2B(user_data1.bp2B);
          // ----------------------------- //

          store.setComment1B(user_data1.comment1B);
          store.setComment2B(user_data1.comment2B);

          //---------------------------///

          store.setApComment1B(user_data1.Apcomment1B);
          store.setApComment2B(user_data1.Apcomment2B);

          // ------element 3 ----//

          store.setCS1C(user_data1.CS1C);
          store.setCS2C(user_data1.CS2C);
          // ------------------- //

          store.setTr1C(user_data1.tr1C);
          store.setTr2C(user_data1.tr2C);

          //------------------------------------//

          store.setBp1C(user_data1.bp1C);
          store.setBp2C(user_data1.bp2C);
          // ----------------------------- //

          store.setComment1C(user_data1.comment1C);
          store.setComment2C(user_data1.comment2C);

          //----------------------------'//
          store.setApComment1C(user_data1.Apcomment1C);
          store.setApComment2C(user_data1.Apcomment2C);

          // --------- element 4 ------ //

          store.setCS1D(user_data1.CS1D);
          store.setCS2D(user_data1.CS2D);
          store.setCS3D(user_data1.CS3D);
          // ------------------- //

          store.setTr1D(user_data1.tr1D);
          store.setTr2D(user_data1.tr2D);
          store.setTr3D(user_data1.tr3D);

          //------------------------------------//

          store.setBp1D(user_data1.bp1D);
          store.setBp2D(user_data1.bp2D);
          store.setBp3D(user_data1.bp3D);
          // ----------------------------- //

          store.setComment1D(user_data1.comment1D);
          store.setComment2D(user_data1.comment2D);
          store.setComment3D(user_data1.comment3D);

          //---------------//
          store.setApComment1D(user_data1.Apcomment1D);
          store.setApComment2D(user_data1.Apcomment2D);
          store.setApComment3D(user_data1.Apcomment3D);

          //---- ELEMENT 5 ----//

          store.setCS1E(user_data1.CS1E);
          store.setCS2E(user_data1.CS2E);
          store.setCS3E(user_data1.CS3E);
          // ------------------- //

          store.setTr1E(user_data1.tr1E);
          store.setTr2E(user_data1.tr2E);
          store.setTr3E(user_data1.tr3E);

          //------------------------------------//

          store.setBp1E(user_data1.bp1E);
          store.setBp2E(user_data1.bp2E);
          store.setBp3E(user_data1.bp3E);
          // ----------------------------- //

          store.setComment1E(user_data1.comment1E);
          store.setComment2E(user_data1.comment2E);
          store.setComment3E(user_data1.comment3E);

          store.setApComment1E(user_data1.Apcomment1E);
          store.setApComment2E(user_data1.Apcomment2E);
          store.setApComment3E(user_data1.Apcomment3E);

          // ------- Element 6-----//

          store.setCS1F(user_data1.CS1F);
          store.setCS2F(user_data1.CS2F);
          store.setCS3F(user_data1.CS3F);
          store.setCS4F(user_data1.CS4F);
          // ------------------- //

          store.setTr1F(user_data1.tr1F);
          store.setTr2F(user_data1.tr2F);
          store.setTr3F(user_data1.tr3F);
          store.setTr4F(user_data1.tr4F);
          //------------------------------------//

          store.setBp1F(user_data1.bp1F);
          store.setBp2F(user_data1.bp2F);
          store.setBp3F(user_data1.bp3F);
          store.setBp4F(user_data1.bp4F);
          // ----------------------------- //

          store.setComment1F(user_data1.comment1F);
          store.setComment2F(user_data1.comment2F);
          store.setComment3F(user_data1.comment3F);
          store.setComment4F(user_data1.comment4F);

          /// ------------------ ///
          store.setApComment1F(user_data1.Apcomment1F);
          store.setApComment2F(user_data1.Apcomment2F);
          store.setApComment3F(user_data1.Apcomment3F);
          store.setApComment4F(user_data1.Apcomment4F);
          // ---- Element 7 ---- //

          store.setCS1G(user_data1.CS1G);
          store.setCS2G(user_data1.CS2G);
          store.setCS3G(user_data1.CS3G);
          store.setCS4G(user_data1.CS4G);
          store.setCS5G(user_data1.CS5G);
          store.setCS6G(user_data1.CS6G);

          // ------------------- //

          store.setTr1G(user_data1.tr1G);
          store.setTr2G(user_data1.tr2G);
          store.setTr3G(user_data1.tr3G);
          store.setTr4G(user_data1.tr4G);
          store.setTr5G(user_data1.tr5G);
          store.setTr6G(user_data1.tr6G);
          //------------------------------------//

          store.setBp1G(user_data1.bp1G);
          store.setBp2G(user_data1.bp2G);
          store.setBp3G(user_data1.bp3G);
          store.setBp4G(user_data1.bp4G);
          store.setBp5G(user_data1.bp5G);
          store.setBp6G(user_data1.bp6G);
          // ----------------------------- //

          store.setComment1G(user_data1.comment1G);
          store.setComment2G(user_data1.comment2G);
          store.setComment3G(user_data1.comment3G);
          store.setComment4G(user_data1.comment4G);
          store.setComment5G(user_data1.comment5G);
          store.setComment6G(user_data1.comment6G);

          // ----------------------//
          store.setApComment1G(user_data1.Apcomment1G);
          store.setApComment2G(user_data1.Apcomment2G);
          store.setApComment3G(user_data1.Apcomment3G);
          store.setApComment4G(user_data1.Apcomment4G);
          store.setApComment5G(user_data1.Apcomment5G);
          store.setApComment6G(user_data1.Apcomment6G);
          //------- element 8 -----//

          store.setCS1H(user_data1.CS1H);
          store.setCS2H(user_data1.CS2H);
          store.setCS3H(user_data1.CS3H);
          store.setCS4H(user_data1.CS4H);
          store.setCS5H(user_data1.CS5H);
          store.setCS6H(user_data1.CS6H);
          store.setCS7H(user_data1.CS7H);
          // ------------------- //

          store.setTr1H(user_data1.tr1H);
          store.setTr2H(user_data1.tr2H);
          store.setTr3H(user_data1.tr3H);
          store.setTr4H(user_data1.tr4H);
          store.setTr5H(user_data1.tr5H);
          store.setTr6H(user_data1.tr6H);
          store.setTr7H(user_data1.tr7H);
          //------------------------------------//

          store.setBp1H(user_data1.bp1H);
          store.setBp2H(user_data1.bp2H);
          store.setBp3H(user_data1.bp3H);
          store.setBp4H(user_data1.bp4H);
          store.setBp5H(user_data1.bp5H);
          store.setBp6H(user_data1.bp6H);
          store.setBp7H(user_data1.bp7H);
          // ----------------------------- //

          store.setComment1H(user_data1.comment1H);
          store.setComment2H(user_data1.comment2H);
          store.setComment3H(user_data1.comment3H);
          store.setComment4H(user_data1.comment4H);
          store.setComment5H(user_data1.comment5H);
          store.setComment6H(user_data1.comment6H);
          store.setComment7H(user_data1.comment7H);

          // -----------------------------//
          store.setApComment1H(user_data1.Apcomment1H);
          store.setApComment2H(user_data1.Apcomment2H);
          store.setApComment3H(user_data1.Apcomment3H);
          store.setApComment4H(user_data1.Apcomment4H);
          store.setApComment5H(user_data1.Apcomment5H);
          store.setApComment6H(user_data1.Apcomment6H);
          store.setApComment7H(user_data1.Apcomment7H);

          ///------ element 9 -----///
          store.setCS1I(user_data1.CS1I);
          store.setCS2I(user_data1.CS2I);
          store.setTr1I(user_data1.tr1I);
          store.setTr2I(user_data1.tr2I);
          //------------------------------------//

          store.setBp1I(user_data1.bp1I);
          store.setBp2I(user_data1.bp2I);
          // ----------------------------- //

          store.setComment1I(user_data1.comment1I);

          store.setComment2I(user_data1.comment2I);

          //------//

          store.setApComment1G(user_data1.Apcomment1I);
          store.setApComment2G(user_data1.Apcomment2I);
        }
      }); //console.log("abcd"+messagesRef)
  }
  // ---- Current State -----//

  const [CS1, setCS1] = React.useState("");
  const [OpenCS1, setOpenCS1] = React.useState(false);

  const handleChangeCS1 = (event) => {
    setCS1(event.target.value);
    store.setCS1D(event.target.value);
  };
  const handleCloseCS1 = () => {
    setOpenCS1(false);
  };
  const handleOpenCS1 = () => {
    setOpenCS1(true);
  };

  const [CS2, setCS2] = React.useState("");
  const [OpenCS2, setOpenCS2] = React.useState(false);

  const handleChangeCS2 = (event) => {
    setCS2(event.target.value);
    store.setCS2D(event.target.value);
  };
  const handleCloseCS2 = () => {
    setOpenCS2(false);
  };
  const handleOpenCS2 = () => {
    setOpenCS2(true);
  };

  const [CS3, setCS3] = React.useState("");
  const [OpenCS3, setOpenCS3] = React.useState(false);

  const handleChangeCS3 = (event) => {
    setCS3(event.target.value);
    store.setCS3D(event.target.value);
  };
  const handleCloseCS3 = () => {
    setOpenCS3(false);
  };
  const handleOpenCS3 = () => {
    setOpenCS3(true);
  };

  // ------ Target ------//

  const [tr1, setTr1] = React.useState("");
  const [OpenTr1, setOpenTr1] = React.useState(false);

  const handleOpenTr1 = () => {
    setOpenTr1(true);
  };
  const handleCloseTr1 = () => {
    setOpenTr1(false);
  };
  const handleChangeTr1 = (event) => {
    setTr1(event.target.value);
    store.setTr1D(event.target.value);
  };

  const [tr2, setTr2] = React.useState("");
  const [OpenTr2, setOpenTr2] = React.useState(false);

  const handleOpenTr2 = () => {
    setOpenTr2(true);
  };
  const handleCloseTr2 = () => {
    setOpenTr2(false);
  };
  const handleChangeTr2 = (event) => {
    setTr2(event.target.value);
    store.setTr2D(event.target.value);
  };

  const [tr3, setTr3] = React.useState("");
  const [OpenTr3, setOpenTr3] = React.useState(false);

  const handleOpenTr3 = () => {
    setOpenTr3(true);
  };
  const handleCloseTr3 = () => {
    setOpenTr3(false);
  };
  const handleChangeTr3 = (event) => {
    setTr3(event.target.value);
    store.setTr3D(event.target.value);
  };

  // ----- Better practice -----//

  const [bp1, setBp1] = React.useState("");
  const [OpenBp1, setOpenBp1] = React.useState(false);

  const handleOpenBp1 = () => {
    setOpenBp1(true);
  };
  const handleCloseBp1 = () => {
    setOpenBp1(false);
  };
  const handleChangeBp1 = (event) => {
    setBp1(event.target.value);
    store.setBp1D(event.target.value);
  };

  const [bp2, setBp2] = React.useState("");
  const [OpenBp2, setOpenBp2] = React.useState(false);

  const handleOpenBp2 = () => {
    setOpenBp2(true);
  };
  const handleCloseBp2 = () => {
    setOpenBp2(false);
  };
  const handleChangeBp2 = (event) => {
    setBp2(event.target.value);
    store.setBp2D(event.target.value);
  };

  const [bp3, setBp3] = React.useState("");
  const [OpenBp3, setOpenBp3] = React.useState(false);

  const handleOpenBp3 = () => {
    setOpenBp3(true);
  };
  const handleCloseBp3 = () => {
    setOpenBp3(false);
  };
  const handleChangeBp3 = (event) => {
    setBp3(event.target.value);
    store.setBp3D(event.target.value);
  };

  // ------ Comments------//
  const [comment1, setComment1] = React.useState("");
  const [OpenComment1, setOpenComment1] = React.useState(false);
  const handleOpenComment1 = () => {
    setOpenComment1(true);
  };
  const handleCloseComment1 = () => {
    setOpenComment1(false);
  };
  const handleChangeComment1 = (event) => {
    setComment1(event.target.value);
    store.setComment1D(event.target.value);
  };

  const [comment2, setComment2] = React.useState("");
  const [OpenComment2, setOpenComment2] = React.useState(false);

  const handleOpenComment2 = () => {
    setOpenComment2(true);
  };
  const handleCloseComment2 = () => {
    setOpenComment2(false);
  };
  const handleChangeComment2 = (event) => {
    setComment2(event.target.value);
    store.setComment2D(event.target.value);
  };

  const [comment3, setComment3] = React.useState("");
  const [OpenComment3, setOpenComment3] = React.useState(false);

  const handleOpenComment3 = () => {
    setOpenComment3(true);
  };
  const handleCloseComment3 = () => {
    setOpenComment3(false);
  };
  const handleChangeComment3 = (event) => {
    setComment3(event.target.value);
    store.setComment3D(event.target.value);
  };

  /// approver's comments ///

  // ------ Comments------//
  const [Apcomment1, setApComment1] = React.useState("");

  const handleChangeApComment1 = (event) => {
    setApComment1(event.target.value);
    store.setApComment1D(event.target.value);
  };

  const [Apcomment2, setApComment2] = React.useState("");

  const handleChangeApComment2 = (event) => {
    setApComment2(event.target.value);
    store.setApComment2D(event.target.value);
  };

  const [Apcomment3, setApComment3] = React.useState("");

  const handleChangeApComment3 = (event) => {
    setApComment3(event.target.value);
    store.setApComment3D(event.target.value);
  };

  useEffect(() => window.scrollTo(0, 0), []);
  return useObserver(() => (
    <div id="Element4" className={classes.root} style={{ marginTop: "8%" }}>
      <div style={{ marginBottom: "3%" }}>
        <div
          style={{ marginTop: store.header === "block" ? "90%" : "0%" }}
        ></div>
        <div style={{ display: store.header }}>
          {" "}
          <Header content="" color="white" />
          <Header content="Informanagement" />
        </div>
        <div
          style={{ marginTop: store.header === "block" ? "10%" : "0%" }}
        ></div>

        <h3 className="text-center"> Information Management</h3>
      </div>{" "}
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">
            Principles for Managing Data and Information
          </h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                No formal documentation or guidance clarifying the principles
                for managing information.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Awareness exists at management level of how to properly manage
                information and/or documented policies or processes. However,
                there is no implemented programme(s) or resourcing to
                effectively manage information.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Information management principles and associated policies are in
                place. Defined principles include: • Information is managed
                throughout its life-cycle, including long-term preservation and
                access and caters for technological obsolescence. •Collaboration
                with other agencies and the public, facilitating access,
                strengthening awareness, and supporting international
                collaboration. •Data and information support the purposes for
                which they were collected •Information is only collected for
                specified public policy, operational business, or legislative
                purposes. •Data and information are accurate, relevant, timely,
                consistent, and without bias in that context. •Personal,
                confidential and classified data and information are protected.
                Staff apply these principles as part of business-as-usual.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Staff and management proactively contribute to the continuous
                improvement of practices in place to support and complement the
                information management principles and associated policies. They
                identify and communicate gaps and opportunities for improvement.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                A formal information management structure covering the entire
                agency is in place. This structure is actively supported by all
                management and staff.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS1}
            onClose={handleCloseCS1}
            onOpen={handleOpenCS1}
            value={store.CS1D}
            onChange={handleChangeCS1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr1}
            onClose={handleCloseTr1}
            onOpen={handleOpenTr1}
            value={store.tr1D}
            onChange={handleChangeTr1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            OpenCS1={OpenBp1}
            onClose={handleCloseBp1}
            onOpenCS1={handleOpenBp1}
            value={store.bp1D}
            onChange={handleChangeBp1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.comment1D}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.Apcomment1D}</p>
        </Box>
      </Box>
      {/*  Q2  */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Information Management" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">Information Management Strategy</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                No consideration for or integration of information management in
                strategy planning. Tolerance levels in relation to individual
                information management risks are not specified. No consideration
                for information management strategy by the governance board /
                committee(s) / executive leadership team.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Information management strategy has been developed and approved,
                including consideration of the agency’s risk appetite. No
                meaningful definition of information risk appetite exists other
                than in the corporate strategy.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Regular review and revision of information management strategy
                and programme to confirm their suitability with the internal and
                external environment (including regulatory requirements).
                Defined information management risk appetite is used to assess
                resource requirements and timelines, which deal with information
                management risks at an operational level. Information management
                strategy formally adopted by the governance board / committee(s)
                / the executive leadership team.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Results of information risk assessments are used to inform and
                update the information management strategy and programme.
                Governance board and/or committee(s), and/or the executive
                leadership team know what risk appetite means in relation to
                information management and to the objectives and strategies set
                by executive management.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Information management considerations are integrated into the
                overall business strategy, with risk appetite considered in
                decisions around future strategic options. The context for
                setting the information management risk appetite is clearly
                defined and understood by the governance board / committee(s) /
                the executive leadership team.This context includes
                consideration of external and internal trends and expectations.
                The information management risk appetite is regularly reviewed,
                taking into account this context.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS2}
            onClose={handleCloseCS2}
            onOpen={handleOpenCS2}
            value={store.CS2D}
            onChange={handleChangeCS2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr2}
            onClose={handleCloseTr2}
            onOpen={handleOpenTr2}
            value={store.tr2D}
            onChange={handleChangeTr2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp2}
            onClose={handleCloseBp2}
            onOpen={handleOpenBp2}
            value={store.bp2D}
            onChange={handleChangeBp2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.comment2D}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.Apcomment2D}</p>
        </Box>
      </Box>
      {/*  Q3  */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Information Management" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">
            Information Management Business Processes
          </h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                Processes and controls may not be formal, documented, or
                consistent. No effective process to ensure that changes to
                business processes, or that new business processes, are assessed
                for their impact on information management requirements.
                Existing information management processes do not specifically
                address privacy risks or management. Information is shared with
                other agencies without explicit consideration of privacy
                implications.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Implications of ineffective information management processes are
                only realised when privacy breaches or other issues occur.
                Remedial action is either system or business unit specific.
                Informal processes for dealing with requests to share personal
                information with other agencies are in place. These processes
                consider the privacy risks if information is to be shared with
                other agencies. In some cases, processes include documented
                agreements between agencies.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Personal information and privacy management is part of overall
                information management processes. Personal information
                considerations are included in the IT strategy. Personal
                information shared with other agencies is subject to documented
                agreements that comply with relevant legislative requirements,
                including the Privacy Act 1993. Before information is shared, it
                is reviewed to ensure privacy implications are considered.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Personal information and privacy management is integrated into
                business processes and is considered when changing or collecting
                new data. Emerging risks on information management are reviewed
                by management and changes are made proactively to policies and
                procedures as required. Training is developed / revised in
                response to these risks. Information, including personal
                information, is managed as an enterprise asset. Well-developed
                organisation and governance processes and organisational
                structures exist.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Personal information and privacy management is a strategic
                initiative. Issues are either prevented or corrected at the
                source, and best practice architecture is implemented.
                Information obtained through risk assessment or review of
                response to any identified breach is used to inform updates to
                information management business processes and design.There is a
                strong focus on continual improvement.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS3}
            onClose={handleCloseCS3}
            onOpen={handleOpenCS3}
            value={store.CS3D}
            onChange={handleChangeCS3}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr3}
            onClose={handleCloseTr3}
            onOpen={handleOpenTr3}
            value={store.tr3D}
            onChange={handleChangeTr3}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp3}
            onClose={handleCloseBp3}
            onOpen={handleOpenBp3}
            value={store.bp3D}
            onChange={handleChangeBp3}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.comment3D}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>
          <p className={classes.formControl}>{store.Apcomment3D}</p>
        </Box>
      </Box>
      <div className="float-left" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          onClick={() => {
            props.history.push("/e3");
          }}
          style={{ backgroundColor: "#3f51b5", width: "100px" }}
          className="btn btn-primary"
        >
          Previous
        </button>
      </div>
      <div className="float-right" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          onClick={() => {
            props.history.push("/e5");
          }}
          style={{ backgroundColor: "#3f51b5", width: "100px" }}
          className="btn btn-primary"
        >
          Next
        </button>
      </div>
      <div className="float-right" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          style={{ backgroundColor: "#3f51b5", width: "100px" }}
          onClick={() => {
            postData(store, 0, 0);
            store.setSnackOpen(true);
            store.setApprove(0);
          }}
          className="btn btn-primary"
        >
          Save
        </button>
      </div>{" "}
    </div>
  ));
}
