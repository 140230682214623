import React, { useContext } from "react";
import { Radar } from "react-chartjs-2";
import { StoreContext } from "../store/store";
export default function Spider2(props) {
  const store = useContext(StoreContext);

  const data1 = {
    labels: [
      "Personal Information Inventory and Classification",
      "Privacy Analysis Integrated into Policy Advice",
      "Privacy Management Designed into Business Processes",
      "Privacy Impact Assessments",
      "Third Party Contracts and Relationships",
      "Control Activities",
    ],
    datasets: [
      {
        label: "Current State Assessment",
        data: [
          store.CS1G < 10 ? parseFloat(store.CS1G) : 0,
          store.CS2G < 10 ? parseFloat(store.CS2G) : 0,
          store.CS3G < 10 ? parseFloat(store.CS3G) : 0,
          store.CS4G < 10 ? parseFloat(store.CS4G) : 0,
          store.CS5G < 10 ? parseFloat(store.CS5G) : 0,
          store.CS6G < 10 ? parseFloat(store.CS6G) : 0,
        ],
        fill: true,
        backgroundColor: "rgba(3,171,87, 0.2)",
        borderColor: "rgb(3,171,87)", //"black",
        pointBackgroundColor: "rgb(3,171,87)",
        pointBorderColor: "rgb(3,171,87)", //"#fff",
        pointHoverBackgroundColor: "rgb(3,171,87)", //"#fff",
        pointHoverBorderColor: "rgb(3,171,87)",
      },
      {
        label: "Better Practice",
        data: [
          store.bp1G < 10 ? parseFloat(store.bp1G) : 0,
          store.bp2G < 10 ? parseFloat(store.bp2G) : 0,
          store.bp3G < 10 ? parseFloat(store.bp3G) : 0,
          store.bp4G < 10 ? parseFloat(store.bp4G) : 0,
          store.bp5G < 10 ? parseFloat(store.bp5G) : 0,
          store.bp6G < 10 ? parseFloat(store.bp6G) : 0,
        ],
        fill: true,
        backgroundColor: "rgba(25,144,254, 0.2)",
        borderColor: "rgb(25,144,254)", //"black",
        pointBackgroundColor: "rgb(25,144,254)",
        pointBorderColor: "rgb(25,144,254)", //"#fff",
        pointHoverBackgroundColor: "rgb(25,144,254)", //"#fff",
        pointHoverBorderColor: "rgb(25,144,254)",
      },
      {
        label: "Target",
        data: [
          store.tr1G < 10 ? parseFloat(store.tr1G) : 0,
          store.tr2G < 10 ? parseFloat(store.tr2G) : 0,
          store.tr3G < 10 ? parseFloat(store.tr3G) : 0,
          store.tr4G < 10 ? parseFloat(store.tr4G) : 0,
          store.tr5G < 10 ? parseFloat(store.tr5G) : 0,
          store.tr6G < 10 ? parseFloat(store.tr6G) : 0,
        ],
        fill: true,
        backgroundColor: "rgba(252,74,69, 0.2)",
        borderColor: "rgb(252,74,69)", //"black",
        pointBackgroundColor: "rgb(252,74,69)",
        pointBorderColor: "rgb(252,74,69)", //"#fff",
        pointHoverBackgroundColor: "rgb(252,74,69)", //"#fff",
        pointHoverBorderColor: "rgb(252,74,69)",
      },
    ],
  };

  const data = {
    labels: ["Current State Assessment", "Better Practice", "Target"],
    datasets: [
      {
        // label: ["# of Votes"],
        data: [
          (
            (store.CS1G < 10 ? parseFloat(store.CS1G) : 0) +
            (store.CS2G < 10 ? parseFloat(store.CS2G) : 0) +
            (store.CS3G < 10 ? parseFloat(store.CS3G) : 0) +
            (store.CS4G < 10 ? parseFloat(store.CS4G) : 0) +
            (store.CS5G < 10 ? parseFloat(store.CS5G) : 0) +
            (store.CS6G < 10 ? parseFloat(store.CS6G) : 0)
          ).toFixed(2),
          (
            (store.bp1G < 10 ? parseFloat(store.bp1G) : 0) +
            (store.bp2G < 10 ? parseFloat(store.bp2G) : 0) +
            (store.bp3G < 10 ? parseFloat(store.bp3G) : 0) +
            (store.bp4G < 10 ? parseFloat(store.bp4G) : 0) +
            (store.bp5G < 10 ? parseFloat(store.bp5G) : 0) +
            (store.bp6G < 10 ? parseFloat(store.bp6G) : 0)
          ).toFixed(2),
          (
            (store.tr1G < 10 ? parseFloat(store.tr1G) : 0) +
            (store.tr2G < 10 ? parseFloat(store.tr2G) : 0) +
            (store.tr3G < 10 ? parseFloat(store.tr3G) : 0) +
            (store.tr4G < 10 ? parseFloat(store.tr4G) : 0) +
            (store.tr5G < 10 ? parseFloat(store.tr5G) : 0) +
            (store.tr6G < 10 ? parseFloat(store.tr6G) : 0)
          ).toFixed(2),
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ marginTop: "8%" }}>
      <Radar data={data1} height={150} width={300} />
    </div>
  );
}
