import { React, useState, useContext } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import firebase from "firebase";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { StoreContext } from "../store/store";
import SuccessSnack from "./SuccessSnack";
const base_url = "http://";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
var Config = {
  apiKey: "AIzaSyADTVCa2hUtYT1iYe0W8U-NZY6NyFQJacQ",
  authDomain: "pma-survey.firebaseapp.com",
  databaseURL: "https://pma-survey-default-rtdb.firebaseio.com",
  projectId: "pma-survey",
  storageBucket: "pma-survey.appspot.com",
  messagingSenderId: "531631815606",
  appId: "1:531631815606:web:ae74c86ce905bddc95df17",
};

export default function Register(props) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [message, setMessage] = useState("");
  const [typez, setType] = useState("success");

  const register = (name, pass) => {
    if (!firebase.apps.length) {
      firebase.initializeApp(Config);
    } else {
      firebase.app(); // if already initialized, use that one
    }

    firebase
      .auth()
      .createUserWithEmailAndPassword(name, pass)
      .then((userCredential) => {
        // Signed in
        let user = userCredential.user;
        // console.log(user);
        setType("success");
        setMessage("Successfully Registered");
        store.setSnackOpen(true);
        // props.history.push("/");

        // ...
        //  displayHandler("lgn");
      })
      .catch((error) => {
        setMessage("User already exists");
        setType("error");
        store.setSnackOpen(true);
        let errorCode = error.code;
        let errorMessage = error.message;
        // ..
      });
  };

  // const [unameCheck, setNameCheck] = useState("");
  const [emailCheck, setemailCheck] = useState("");
  const [passCheck1, setPassCheck1] = useState("");
  const [passCheck2, setPassCheck2] = useState("");

  const [pass, setPass] = useState("");
  const passSetter = (event) => {
    setPass(event.target.value);
    if (event.target.value.length >= 6) {
      setPassCheck1("");
    } else setPassCheck1("Password should be at least 6 characters long.");
  };

  const [Repass, ResetPass] = useState("");
  const RepassSetter = (event) => {
    ResetPass(event.target.value);
    if (event.target.value === pass) {
      setPassCheck2("");
    } else setPassCheck2("Both passwords should match.");
  };

  const [email, setEmail] = useState("");
  const emailSetter = (event) => {
    setEmail(event.target.value);
    if (re.test(event.target.value)) {
      setemailCheck("");
    } else
      setemailCheck("Please use the correct format for the email address.");
  };

  const submitHandler = () => {
    // detect_lang();

    if (
      emailCheck === "" &&
      passCheck1 === "" &&
      passCheck2 === "" &&
      email.length > 3 &&
      pass.length > 3
    ) {
      // if (pass === Repass) {
      register(email, pass);
      // } else {
      //   alert("both passwords should be same!");
      // }
    } else {
      setMessage("Please enter valid information to register.");
      setType("warning");
      store.setSnackOpen(true);
      //alert();
    }
  };
  return (
    <Container component="main" style={{ marginTop: "11%" }} maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        {/* <form className={classes.form} onSubmit={submitHandler} noValidate> */}

        <TextField
          variant="outlined"
          margin="normal"
          dir="ltr"
          required
          fullWidth
          value={email}
          id="email"
          label="Enter your email address"
          name="email"
          autoComplete="off"
          onChange={emailSetter}
        />
        <span className="text-danger">{emailCheck}</span>

        <TextField
          variant="outlined"
          margin="normal"
          dir="ltr"
          required
          fullWidth
          name="password"
          label="Enter password"
          type="password"
          id="password"
          value={pass}
          onChange={passSetter}
          autoComplete="current-password"
        />
        <span className="text-danger">{passCheck1}</span>
        <TextField
          variant="outlined"
          margin="normal"
          dir="ltr"
          required
          fullWidth
          name="password1"
          label="Confirm password"
          type="password"
          id="password1"
          value={Repass}
          onChange={RepassSetter}
          autoComplete="current-password"
          onKeyUp={(e) => {
            if (e.keyCode == 13) {
              // alert("invoked");
              submitHandler();
              //  validate(); doesn't need to be called from here
            }
          }}
        />
        <span className="text-danger">{passCheck2}</span>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={submitHandler}
          className={classes.submit}
          dir="ltr"
        >
          Register
        </Button>

        {/* </form> */}
      </div>
      <Box mt={8}></Box>
      <SuccessSnack content={message} type={typez} />
    </Container>
  );
}
