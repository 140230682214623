import { React, useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import firebase from "firebase";
import { StoreContext } from "../store/store";
import windows from "global";
//import Alert from "@material-ui/lab/Alert";
//import Snackbar from "@material-ui/core/Snackbar";
//import MuiAlert from "@material-ui/lab/Alert";
// import axios from "axios";

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

var Config = {
  apiKey: "AIzaSyADTVCa2hUtYT1iYe0W8U-NZY6NyFQJacQ",
  authDomain: "pma-survey.firebaseapp.com",
  databaseURL: "https://pma-survey-default-rtdb.firebaseio.com",
  projectId: "pma-survey",
  storageBucket: "pma-survey.appspot.com",
  messagingSenderId: "531631815606",
  appId: "1:531631815606:web:ae74c86ce905bddc95df17",
};
if (!firebase.apps.length) {
  firebase.initializeApp(Config);
} else {
  firebase.app(); // if already initialized, use that one
}

export default function SignIn(props) {
  const classes = useStyles();
  const forgotPassword = (Email) => {
    firebase
      .auth()
      .sendPasswordResetEmail(Email)
      .then(function () {
        alert("Please check your email...");
      })
      .catch(function (e) {
        console.log(e);
      });
  };

  const [uname, setName] = useState("");

  const nameSetter = (event) => {
    setName(event.target.value);
  };

  return (
    <Container component="main" style={{ marginTop: "11%" }} maxWidth="xs">
      {/* <Alert hidden severity="error">
        This is an error message!
      </Alert> */}
      {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          email or password not correct
        </Alert>
      </Snackbar>
      <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
        <Alert onClose={handleClose1} severity="success">
          login successfull
        </Alert>
      </Snackbar> */}

      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" dir="ltr">
          Reset Password
        </Typography>
        {/* <form className={classes.form} onSubmit={submitHandler} noValidate> */}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={nameSetter}
          onKeyUp={(e) => {
            if (e.keyCode == 13) {
              // alert("invoked");
              forgotPassword(uname);
              //  validate(); doesn't need to be called from here
            }
          }}
          dir="ltr"
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            forgotPassword(uname);
          }}
          className={classes.submit}
          dir="ltr"
        >
          Submit
        </Button>
        <Grid container>
          <Grid item>
            <Link
              to="/Register"
              variant="body2"
              dir={localStorage.getItem("lang") == 0 ? "ltr" : "rtl"}
            >
              Create New Account ? Sign Up
            </Link>
          </Grid>
        </Grid>
        {/* </form> */}
      </div>
      <Box mt={8}></Box>
    </Container>
  );
}
