import React, { useContext, useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { StoreContext } from "../store/store";

import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomizedSnackbars() {
  const classes = useStyles();
  const store = useContext(StoreContext);

  return useObserver(() => (
    <div className={classes.root}>
      {store.isApproved == 0 ? (
        <Alert severity="warning">Assessment Status: Incomplete</Alert>
      ) : store.isApproved == 1 ? (
        <Alert severity="info">Assessment Status: Pending Approval</Alert>
      ) : store.isApproved == 2 ? (
        <Alert severity="Success">Assessment Status: Approved</Alert>
      ) : store.isApproved == 3 ? (
        <Alert severity="error">Assessment Status: Disapproved</Alert>
      ) : (
        ""
      )}
    </div>
  ));
}
