import React from "react";
import logo from "../icons/logoGrey.svg";
export default function Header(props) {
  return (
    <div className="row" style={{ marginTop: "5%" }}>
      <div
        className="col-md-12"
        style={{
          backgroundColor: props.color !== undefined ? props.color : "#3f51b5",
          color: "white",
        }}
      >
        <h3 className="text-center">
          {props.img === true ? (
            <img
              width="80"
              // style={{ marginLeft: "-10%", marginRight: "5%" }}
              // height="90"
              src={logo}
            />
          ) : (
            ""
          )}{" "}
          {props.content}
        </h3>
      </div>
    </div>
  );
}
