import React, { useContext } from "react";
import { Radar } from "react-chartjs-2";
import { StoreContext } from "../store/store";
export default function Spider2(props) {
  const store = useContext(StoreContext);

  const data1 = {
    labels: [
      "Incident Handling and Escalation Process",
      "Breach/ incident reporting",
      "",
      "",
      "",
      "",
    ],
    datasets: [
      {
        label: "Current State Assessment",
        data: [
          store.CS1I < 10 ? parseFloat(store.CS1I) : 0,
          store.CS2I < 10 ? parseFloat(store.CS2I) : 0,
        ],
        fill: true,
        backgroundColor: "rgba(3,171,87, 0.2)",
        borderColor: "rgb(3,171,87)", //"black",
        pointBackgroundColor: "rgb(3,171,87)",
        pointBorderColor: "rgb(3,171,87)", //"#fff",
        pointHoverBackgroundColor: "rgb(3,171,87)", //"#fff",
        pointHoverBorderColor: "rgb(3,171,87)",
      },
      {
        label: "Better Practice",
        data: [
          store.bp1I < 10 ? parseFloat(store.bp1I) : 0,
          store.bp2I < 10 ? parseFloat(store.bp2I) : 0,
        ],
        fill: true,
        backgroundColor: "rgba(25,144,254, 0.2)",
        borderColor: "rgb(25,144,254)", //"black",
        pointBackgroundColor: "rgb(25,144,254)",
        pointBorderColor: "rgb(25,144,254)", //"#fff",
        pointHoverBackgroundColor: "rgb(25,144,254)", //"#fff",
        pointHoverBorderColor: "rgb(25,144,254)",
      },
      {
        label: "Target",
        data: [
          store.tr1I < 10 ? parseFloat(store.tr1I) : 0,
          store.tr2I < 10 ? parseFloat(store.tr2I) : 0,
        ],
        fill: true,
        backgroundColor: "rgba(252,74,69, 0.2)",
        borderColor: "rgb(252,74,69)", //"black",
        pointBackgroundColor: "rgb(252,74,69)",
        pointBorderColor: "rgb(252,74,69)", //"#fff",
        pointHoverBackgroundColor: "rgb(252,74,69)", //"#fff",
        pointHoverBorderColor: "rgb(252,74,69)",
      },
      // {
      //   label: "testing",
      //   data: [
      //     store.CS1I < 10 ? parseFloat(store.CS1I) : 0,
      //     store.CS2I < 10 ? parseFloat(store.CS2I) : 0,
      //   ],
      //   fill: true,
      //   backgroundColor: "rgba(255, 99, 132, 0.2)",
      //   borderColor: "black",
      //   pointBackgroundColor: "rgb(255, 99, 132)",
      //   pointBorderColor: "#fff",
      //   pointHoverBackgroundColor: "#fff",
      //   pointHoverBorderColor: "rgb(255, 99, 132)",
      // },
    ],
  };

  const data = {
    labels: ["Current State Assessment", "Better Practice", "Target"],
    datasets: [
      {
        // label: ["# of Votes"],
        data: [
          (
            (store.CS1I < 10 ? parseFloat(store.CS1I) : 0) +
            (store.CS2I < 10 ? parseFloat(store.CS2I) : 0)
          ).toFixed(2),
          (
            (store.bp1I < 10 ? parseFloat(store.bp1I) : 0) +
            (store.bp2I < 10 ? parseFloat(store.bp2I) : 0)
          ).toFixed(2),
          (
            (store.tr1I < 10 ? parseFloat(store.tr1I) : 0) +
            (store.tr2I < 10 ? parseFloat(store.tr2I) : 0)
          ).toFixed(2),
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ marginTop: "8%" }}>
      <Radar data={data1} height={150} width={300} />
    </div>
  );
}
