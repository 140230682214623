import React, { useState, useRef, useEffect, useContext } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import E1 from "./PrintE1";
import E2 from "./PrintE2";
import E3 from "./PrintE3";
import E4 from "./PrintE4";
import E5 from "./PrintE5";
import E6 from "./PrintE6";
import E7 from "./PrintE7";
import E8 from "./PrintE8";
import E9 from "./PrintE9";
import Spider from "./spider1";

import Summary from "./Summary";
import { useReactToPrint } from "react-to-print";
import { StoreContext } from "../store/store";

export default function FullPDF(props) {
  const componentRef = useRef();
  const store = useContext(StoreContext);
  if (store.user === "") {
    // alert(localStorage.getItem("user"));

    props.history.push("/");
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      store.setHeader("none");
      store.setExpanded();
      let divsToHide1 = document.getElementsByClassName("float-left");
      let divsToHide2 = document.getElementsByClassName("float-right");
      for (var i = 0; i < divsToHide1.length; i++) {
        divsToHide1[i].style.display = "block"; // depending on what you're doing
      }
      for (var i = 0; i < divsToHide2.length; i++) {
        divsToHide2[i].style.display = "block"; // depending on what you're doing
      }
      props.history.push("/summary");
    },
  });

  const hide = () => {
    let divsToHide1 = document.getElementsByClassName("float-left");
    let divsToHide2 = document.getElementsByClassName("float-right");
    for (var i = 0; i < divsToHide1.length; i++) {
      divsToHide1[i].style.display = "none"; // depending on what you're doing
    }
    for (var i = 0; i < divsToHide2.length; i++) {
      divsToHide2[i].style.display = "none"; // depending on what you're doing
    }

    handlePrint();
    // document.getElementById("down").style.display = "block";
  };
  const [margin, setMargin] = useState(0);

  useEffect(() => {
    // if (store.viewUser === undefined) {
    hide();
    setMargin(-10);

    //}
    // store.setUserToBeApproved("");
  }, []);
  return useObserver(() => (
    <div ref={componentRef} style={{ marginLeft: margin }}>
      <div>
        <Summary />
      </div>
      <div style={{ marginTop: "60%" }}>
        <E1 />
        {/* <div hidden>
          {" "}
          <Spider />
        </div> */}
      </div>
      <div>
        <E2 />
      </div>
      <div>
        <E3 />
      </div>
      <div>
        <E4 />
      </div>
      <div>
        <E5 />
      </div>
      <div>
        <E6 />
      </div>
      <div>
        <E7 />
      </div>
      <div>
        {" "}
        <E8 />
      </div>
      <div>
        <E9 />
      </div>
    </div>
  ));
}
