import React, { Component, useRef, useContext, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { StoreContext } from "../store/store";
import { useObserver, useLocalStore } from "mobx-react";
import Box from "@material-ui/core/Box";
import firebase from "firebase";

import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//let productz = {};

export default function Admin(props) {
  const store = useContext(StoreContext);
  const [productz, setPro] = React.useState([
    {
      user_email: "",
      sub_date: "",

      isApproved: "",
      link: "",
    },
  ]);
  const columns = [
    {
      dataField: "user_email",
      text: "User",
    },
    {
      dataField: "quarter",
      text: "Quarter",
    },
    {
      dataField: "sub_date",
      text: "Submission Date",
    },

    {
      dataField: "isApproved",
      text: "Status",
    },
    {
      dataField: "view_user",
      text: "View",
    },
  ];

  function getSecondPart(str) {
    return str.split("-")[1] + "-Q" + str.split("-")[2];
  }
  let user_data1;
  let user_data2 = [];
  const fetchData = () => {
    let x;
    var recentPostsRef = firebase.database().ref("/users");
    recentPostsRef
      .once("value")
      .then((snapshot) => {
        // snapshot.val() is the dictionary with all your keys/values from the '/users' path
        x = snapshot.val();
      })
      .then(() => {
        for (let i in x) {
          user_data1 = x[i];
          user_data1.user_key = i;
          user_data1.quarter = getSecondPart(i);
          console.log(user_data1.user_key);
          user_data1.isApproved =
            user_data1.isApproved == 0 ? (
              <Alert severity="warning"> incomplete</Alert>
            ) : user_data1.isApproved == 1 ? (
              <Alert severity="info"> Pending Approval</Alert>
            ) : user_data1.isApproved == 2 ? (
              <Alert severity="Success"> Approved</Alert>
            ) : user_data1.isApproved == 3 ? (
              <Alert severity="error"> Disapproved</Alert>
            ) : (
              ""
            );
          user_data1.view_user = (
            <button
              style={{ backgroundColor: "#3f51b5" }}
              className="btn btn-primary"
              onClick={(user_data1) => {
                // localStorage.setItem("uid", x[i].user_key);
                localStorage.setItem("user", x[i].user_email);
                store.setUser(x[i].user_email);

                // store.setViewUser(x[i].user_email);

                store.setUserToBeApproved(x[i].user_key);
                store.setUserToBeApprovedMail(x[i].user_email);
                store.setUserToBeApprovedAppr(x[i].appr);
                //store.setUser(user_data[i].user_email);

                store.setViewUser(x[i].user_email);
                store.setReadOnly(true);
                store.setReadOnlyUser(false);
                store.setDecide(1);
                props.history.push("/pdf");
                // props.history.push("/pdf");
              }}
            >
              View Assesment
            </button>
          );

          user_data2.push(user_data1);
          user_data1 = {};
        }
        setPro(user_data2);
      });

    // console.log(user_data2);
  };
  //fetchData();

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box width="75%" ml="15%" pt={10}>
      <h3 className="text-center"> Admin </h3>

      <ToolkitProvider keyField="id" data={productz} columns={columns}>
        {(props) => (
          <div>
            <hr />
            <BootstrapTable {...props.baseProps} />
            {/* <ExportCSVButton {...props.csvProps}>Export CSV!!</ExportCSVButton> */}
          </div>
        )}
      </ToolkitProvider>
    </Box>
  );
}
