import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch, browserHistory, IndexRoute } from 'react-router-dom'

import Nav from "./customComponents/Nav"
import Element1 from "./customComponents/Element1"
import Element2 from "./customComponents/Element2"
import Element3 from "./customComponents/Element3"
import Element4 from "./customComponents/Element4"
import Element5 from "./customComponents/Element5"
import Element6 from "./customComponents/Element6"
import Element7 from "./customComponents/Element7"
import Element8 from "./customComponents/Element8"
import Element9 from "./customComponents/Element9"
import Summary from "./customComponents/Summary"
import Login from "./customComponents/Login"
import Register from "./customComponents/Register"
import Home from "./customComponents/donought"
import PDF from "./customComponents/FullPDF"
import Admin from "./customComponents/Admin"
import Approvals from "./customComponents/Approvals"
import Start from "./customComponents/PreAsses"
import Previous from "./customComponents/PrevAsses"
import Print from "./customComponents/FullPrint"
import {StoreProvider} from './store/store'
import Forget from "./customComponents/Reset"
import './custom.css'

function App() {
  return (
   
    <StoreProvider>
    <Router>
    <Nav/>
    
   <Switch>
       <Route exact path="/index"  component={Home}/>
       <Route exact path="/e1"  component={Element1}/>
       <Route exact path="/e2"  component={Element2}/>
       <Route exact path="/e3"  component={Element3}/>
       <Route exact path="/e4"  component={Element4}/>
       <Route exact path="/e5"  component={Element5}/>
       <Route exact path="/e6"  component={Element6}/>
       <Route exact path="/e7"  component={Element7}/>
       <Route exact path="/e8"  component={Element8}/>
       <Route exact path="/e9"  component={Element9}/>
       <Route exact path="/pdf"  component={PDF}/>
       <Route exact path="/prev"  component={Previous}/>
       <Route exact path="/admin"  component={Admin}/>
       <Route exact path="/approvals"  component={Approvals}/>
       <Route exact path="/summary"  component={Summary}/>
       <Route exact path="/register"  component={Register}/>
       <Route exact path="/start"  component={Start}/>
       <Route exact path="/print"  component={Print}/>
       <Route exact path="/"  component={Login}/>
       <Route exact path="/forget"  component={Forget}/>
       {/*
      
       <Route  exact path="/records"  component={Table}/> 
       <Route  exact path="/data"  component={DataExp}/>  */}
       </Switch>
       </Router>
       </StoreProvider>
    
  );
}

export default App;
