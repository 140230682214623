import React, { useState, useContext, useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { StoreContext } from "../store/store";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
//import AccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import firebase from "firebase";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Sipder1 from "./spider1";
import postData from "../functions/postData";
// import Stepper from "./testing";
import Progress from "./progress";
import SuccessSnack from "./SuccessSnack";
import Header from "./Header";
//import getData from "../functions/getData";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    marginLeft: "11%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
}));

const AccordionSummary = withStyles({
  content: {
    flexGrow: 0,
  },
})(MuiAccordionSummary);

export default function Element1(props) {
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [progress, setProgress] = useState("");
  if (store.userKey === "") {
    // alert(localStorage.getItem("user"));

    props.history.push("/");
  }
  function getData() {
    //setProgress("block");
    //alert(progress);
    var Config = {
      apiKey: "AIzaSyADTVCa2hUtYT1iYe0W8U-NZY6NyFQJacQ",
      authDomain: "pma-survey.firebaseapp.com",
      databaseURL: "https://pma-survey-default-rtdb.firebaseio.com",
      projectId: "pma-survey",
      storageBucket: "pma-survey.appspot.com",
      messagingSenderId: "531631815606",
      appId: "1:531631815606:web:ae74c86ce905bddc95df17",
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(Config);
    } else {
      firebase.app(); // if already initialized, use that one
    }

    console.log(store.user + localStorage.getItem("uid"));
    let user_data = {};
    let user_data1 = {};
    let user;
    if (store.decide === 1) {
      user = store.userToBeApproved;
      store.setReadOnly(false);
      store.setReadOnlyUser(true);
    } else {
      user = store.userKey;
      store.setReadOnly(false);
      store.setReadOnlyUser(true);
    }
    firebase
      .database()
      .ref()
      .child("users")
      .orderByChild("userKey")
      .equalTo(user)
      .on("value", function (snapshot) {
        user_data = snapshot.val();
        if (user_data === null) {
          setProgress("none");
        } else {
          for (let i in user_data) {
            user_data1 = user_data[i];
            break;
          }
          console.log(user_data);

          // if (user_data1.user_email !== store.user) {
          //   console.log("inside if");
          // } else
          {
            // --- element 1---//
            store.setApprove(user_data1.isApproved);
            if (store.appr === "") store.setAppr(user_data1.appr);

            store.setCS1A(user_data1.CS1A);
            store.setCS2A(user_data1.CS2A);
            store.setCS3A(user_data1.CS3A);
            store.setCS4A(user_data1.CS4A);
            store.setCS5A(user_data1.CS5A);
            store.setCS6A(user_data1.CS6A);

            // ------------------- //

            store.setTr1A(user_data1.tr1A);
            store.setTr2A(user_data1.tr2A);
            store.setTr3A(user_data1.tr3A);
            store.setTr4A(user_data1.tr4A);
            store.setTr5A(user_data1.tr5A);
            store.setTr6A(user_data1.tr6A);

            //------------------------------------//

            store.setBp1A(user_data1.bp1A);
            store.setBp2A(user_data1.bp2A);
            store.setBp3A(user_data1.bp3A);
            store.setBp4A(user_data1.bp4A);
            store.setBp5A(user_data1.bp5A);
            store.setBp6A(user_data1.bp6A);

            // ----------------------------- //

            store.setComment1A(user_data1.comment1A);
            store.setComment2A(user_data1.comment2A);
            store.setComment3A(user_data1.comment3A);
            store.setComment4A(user_data1.comment4A);
            store.setComment5A(user_data1.comment5A);
            store.setComment6A(user_data1.comment6A);

            /// --------------------------------//
            store.setApComment1A(user_data1.Apcomment1A);
            store.setApComment2A(user_data1.Apcomment2A);
            store.setApComment3A(user_data1.Apcomment3A);
            store.setApComment4A(user_data1.Apcomment4A);
            store.setApComment5A(user_data1.Apcomment5A);
            store.setApComment6A(user_data1.Apcomment6A);

            // -------- element 2 ------//

            store.setCS1B(user_data1.CS1B);
            store.setCS2B(user_data1.CS2B);
            // ------------------- //

            store.setTr1B(user_data1.tr1B);
            store.setTr2B(user_data1.tr2B);

            //------------------------------------//

            store.setBp1B(user_data1.bp1B);
            store.setBp2B(user_data1.bp2B);
            // ----------------------------- //

            store.setComment1B(user_data1.comment1B);
            store.setComment2B(user_data1.comment2B);

            //---------------------------///

            store.setApComment1B(user_data1.Apcomment1B);
            store.setApComment2B(user_data1.Apcomment2B);

            // ------element 3 ----//

            store.setCS1C(user_data1.CS1C);
            store.setCS2C(user_data1.CS2C);
            // ------------------- //

            store.setTr1C(user_data1.tr1C);
            store.setTr2C(user_data1.tr2C);

            //------------------------------------//

            store.setBp1C(user_data1.bp1C);
            store.setBp2C(user_data1.bp2C);
            // ----------------------------- //

            store.setComment1C(user_data1.comment1C);
            store.setComment2C(user_data1.comment2C);

            //----------------------------'//
            store.setApComment1C(user_data1.Apcomment1C);
            store.setApComment2C(user_data1.Apcomment2C);

            // --------- element 4 ------ //

            store.setCS1D(user_data1.CS1D);
            store.setCS2D(user_data1.CS2D);
            store.setCS3D(user_data1.CS3D);
            // ------------------- //

            store.setTr1D(user_data1.tr1D);
            store.setTr2D(user_data1.tr2D);
            store.setTr3D(user_data1.tr3D);

            //------------------------------------//

            store.setBp1D(user_data1.bp1D);
            store.setBp2D(user_data1.bp2D);
            store.setBp3D(user_data1.bp3D);
            // ----------------------------- //

            store.setComment1D(user_data1.comment1D);
            store.setComment2D(user_data1.comment2D);
            store.setComment3D(user_data1.comment3D);

            //---------------//
            store.setApComment1D(user_data1.Apcomment1D);
            store.setApComment2D(user_data1.Apcomment2D);
            store.setApComment3D(user_data1.Apcomment3D);

            //---- ELEMENT 5 ----//

            store.setCS1E(user_data1.CS1E);
            store.setCS2E(user_data1.CS2E);
            store.setCS3E(user_data1.CS3E);
            // ------------------- //

            store.setTr1E(user_data1.tr1E);
            store.setTr2E(user_data1.tr2E);
            store.setTr3E(user_data1.tr3E);

            //------------------------------------//

            store.setBp1E(user_data1.bp1E);
            store.setBp2E(user_data1.bp2E);
            store.setBp3E(user_data1.bp3E);
            // ----------------------------- //

            store.setComment1E(user_data1.comment1E);
            store.setComment2E(user_data1.comment2E);
            store.setComment3E(user_data1.comment3E);

            store.setApComment1E(user_data1.Apcomment1E);
            store.setApComment2E(user_data1.Apcomment2E);
            store.setApComment3E(user_data1.Apcomment3E);

            // ------- Element 6-----//

            store.setCS1F(user_data1.CS1F);
            store.setCS2F(user_data1.CS2F);
            store.setCS3F(user_data1.CS3F);
            store.setCS4F(user_data1.CS4F);
            // ------------------- //

            store.setTr1F(user_data1.tr1F);
            store.setTr2F(user_data1.tr2F);
            store.setTr3F(user_data1.tr3F);
            store.setTr4F(user_data1.tr4F);
            //------------------------------------//

            store.setBp1F(user_data1.bp1F);
            store.setBp2F(user_data1.bp2F);
            store.setBp3F(user_data1.bp3F);
            store.setBp4F(user_data1.bp4F);
            // ----------------------------- //

            store.setComment1F(user_data1.comment1F);
            store.setComment2F(user_data1.comment2F);
            store.setComment3F(user_data1.comment3F);
            store.setComment4F(user_data1.comment4F);

            /// ------------------ ///
            store.setApComment1F(user_data1.Apcomment1F);
            store.setApComment2F(user_data1.Apcomment2F);
            store.setApComment3F(user_data1.Apcomment3F);
            store.setApComment4F(user_data1.Apcomment4F);
            // ---- Element 7 ---- //

            store.setCS1G(user_data1.CS1G);
            store.setCS2G(user_data1.CS2G);
            store.setCS3G(user_data1.CS3G);
            store.setCS4G(user_data1.CS4G);
            store.setCS5G(user_data1.CS5G);
            store.setCS6G(user_data1.CS6G);

            // ------------------- //

            store.setTr1G(user_data1.tr1G);
            store.setTr2G(user_data1.tr2G);
            store.setTr3G(user_data1.tr3G);
            store.setTr4G(user_data1.tr4G);
            store.setTr5G(user_data1.tr5G);
            store.setTr6G(user_data1.tr6G);
            //------------------------------------//

            store.setBp1G(user_data1.bp1G);
            store.setBp2G(user_data1.bp2G);
            store.setBp3G(user_data1.bp3G);
            store.setBp4G(user_data1.bp4G);
            store.setBp5G(user_data1.bp5G);
            store.setBp6G(user_data1.bp6G);
            // ----------------------------- //

            store.setComment1G(user_data1.comment1G);
            store.setComment2G(user_data1.comment2G);
            store.setComment3G(user_data1.comment3G);
            store.setComment4G(user_data1.comment4G);
            store.setComment5G(user_data1.comment5G);
            store.setComment6G(user_data1.comment6G);

            // ----------------------//
            store.setApComment1G(user_data1.Apcomment1G);
            store.setApComment2G(user_data1.Apcomment2G);
            store.setApComment3G(user_data1.Apcomment3G);
            store.setApComment4G(user_data1.Apcomment4G);
            store.setApComment5G(user_data1.Apcomment5G);
            store.setApComment6G(user_data1.Apcomment6G);
            //------- element 8 -----//

            store.setCS1H(user_data1.CS1H);
            store.setCS2H(user_data1.CS2H);
            store.setCS3H(user_data1.CS3H);
            store.setCS4H(user_data1.CS4H);
            store.setCS5H(user_data1.CS5H);
            store.setCS6H(user_data1.CS6H);
            store.setCS7H(user_data1.CS7H);
            // ------------------- //

            store.setTr1H(user_data1.tr1H);
            store.setTr2H(user_data1.tr2H);
            store.setTr3H(user_data1.tr3H);
            store.setTr4H(user_data1.tr4H);
            store.setTr5H(user_data1.tr5H);
            store.setTr6H(user_data1.tr6H);
            store.setTr7H(user_data1.tr7H);
            //------------------------------------//

            store.setBp1H(user_data1.bp1H);
            store.setBp2H(user_data1.bp2H);
            store.setBp3H(user_data1.bp3H);
            store.setBp4H(user_data1.bp4H);
            store.setBp5H(user_data1.bp5H);
            store.setBp6H(user_data1.bp6H);
            store.setBp7H(user_data1.bp7H);
            // ----------------------------- //

            store.setComment1H(user_data1.comment1H);
            store.setComment2H(user_data1.comment2H);
            store.setComment3H(user_data1.comment3H);
            store.setComment4H(user_data1.comment4H);
            store.setComment5H(user_data1.comment5H);
            store.setComment6H(user_data1.comment6H);
            store.setComment7H(user_data1.comment7H);

            // -----------------------------//
            store.setApComment1H(user_data1.Apcomment1H);
            store.setApComment2H(user_data1.Apcomment2H);
            store.setApComment3H(user_data1.Apcomment3H);
            store.setApComment4H(user_data1.Apcomment4H);
            store.setApComment5H(user_data1.Apcomment5H);
            store.setApComment6H(user_data1.Apcomment6H);
            store.setApComment7H(user_data1.Apcomment7H);

            ///------ element 9 -----///
            store.setCS1I(user_data1.CS1I);
            store.setCS2I(user_data1.CS2I);
            store.setTr1I(user_data1.tr1I);
            store.setTr2I(user_data1.tr2I);
            //------------------------------------//

            store.setBp1I(user_data1.bp1I);
            store.setBp2I(user_data1.bp2I);
            // ----------------------------- //

            store.setComment1I(user_data1.comment1I);

            store.setComment2I(user_data1.comment2I);

            //------//

            store.setApComment1G(user_data1.Apcomment1I);
            store.setApComment2G(user_data1.Apcomment2I);
          }
          setProgress("none");
        }
      }); //console.log("abcd"+messagesRef)
  }

  // ---- Current State -----//

  const [CS1, setCS1] = React.useState("");
  const [OpenCS1, setOpenCS1] = React.useState(false);

  const handleChangeCS1 = (event) => {
    setCS1(event.target.value);
    store.setCS1A(event.target.value);
  };
  const handleCloseCS1 = () => {
    setOpenCS1(false);
  };
  const handleOpenCS1 = () => {
    setOpenCS1(true);
  };

  const [CS2, setCS2] = React.useState("");
  const [OpenCS2, setOpenCS2] = React.useState(false);

  const handleChangeCS2 = (event) => {
    setCS2(event.target.value);
    store.setCS2A(event.target.value);
  };
  const handleCloseCS2 = () => {
    setOpenCS2(false);
  };
  const handleOpenCS2 = () => {
    setOpenCS2(true);
  };

  const [CS3, setCS3] = React.useState("");
  const [OpenCS3, setOpenCS3] = React.useState(false);

  const handleChangeCS3 = (event) => {
    setCS3(event.target.value);
    store.setCS3A(event.target.value);
  };
  const handleCloseCS3 = () => {
    setOpenCS3(false);
  };
  const handleOpenCS3 = () => {
    setOpenCS3(true);
  };

  const [CS4, setCS4] = React.useState("");
  const [OpenCS4, setOpenCS4] = React.useState(false);

  const handleChangeCS4 = (event) => {
    setCS4(event.target.value);
    store.setCS4A(event.target.value);
  };
  const handleCloseCS4 = () => {
    setOpenCS4(false);
  };
  const handleOpenCS4 = () => {
    setOpenCS4(true);
  };

  const [CS5, setCS5] = React.useState("");
  const [OpenCS5, setOpenCS5] = React.useState(false);

  const handleChangeCS5 = (event) => {
    setCS5(event.target.value);
    store.setCS5A(event.target.value);
  };
  const handleCloseCS5 = () => {
    setOpenCS5(false);
  };
  const handleOpenCS5 = () => {
    setOpenCS5(true);
  };

  const [CS6, setCS6] = React.useState("");
  const [OpenCS6, setOpenCS6] = React.useState(false);

  const handleChangeCS6 = (event) => {
    setCS6(event.target.value);
    store.setCS6A(event.target.value);
  };
  const handleCloseCS6 = () => {
    setOpenCS6(false);
  };
  const handleOpenCS6 = () => {
    setOpenCS6(true);
  };
  // ------ Target ------//

  const [tr1, setTr1] = React.useState("");
  const [OpenTr1, setOpenTr1] = React.useState(false);

  const handleOpenTr1 = () => {
    setOpenTr1(true);
  };
  const handleCloseTr1 = () => {
    setOpenTr1(false);
  };
  const handleChangeTr1 = (event) => {
    setTr1(event.target.value);
    store.setTr1A(event.target.value);
  };

  const [tr2, setTr2] = React.useState("");
  const [OpenTr2, setOpenTr2] = React.useState(false);

  const handleOpenTr2 = () => {
    setOpenTr2(true);
  };
  const handleCloseTr2 = () => {
    setOpenTr2(false);
  };
  const handleChangeTr2 = (event) => {
    setTr2(event.target.value);
    store.setTr2A(event.target.value);
  };

  const [tr3, setTr3] = React.useState("");
  const [OpenTr3, setOpenTr3] = React.useState(false);

  const handleOpenTr3 = () => {
    setOpenTr3(true);
  };
  const handleCloseTr3 = () => {
    setOpenTr3(false);
  };
  const handleChangeTr3 = (event) => {
    setTr3(event.target.value);
    store.setTr3A(event.target.value);
  };

  const [tr4, setTr4] = React.useState("");
  const [OpenTr4, setOpenTr4] = React.useState(false);

  const handleOpenTr4 = () => {
    setOpenTr4(true);
  };
  const handleCloseTr4 = () => {
    setOpenTr4(false);
  };
  const handleChangeTr4 = (event) => {
    setTr4(event.target.value);
    store.setTr4A(event.target.value);
  };

  const [tr5, setTr5] = React.useState("");
  const [OpenTr5, setOpenTr5] = React.useState(false);

  const handleOpenTr5 = () => {
    setOpenTr5(true);
  };
  const handleCloseTr5 = () => {
    setOpenTr5(false);
  };
  const handleChangeTr5 = (event) => {
    setTr5(event.target.value);
    store.setTr5A(event.target.value);
  };

  const [tr6, setTr6] = React.useState("");
  const [OpenTr6, setOpenTr6] = React.useState(false);

  const handleOpenTr6 = () => {
    setOpenTr6(true);
  };
  const handleCloseTr6 = () => {
    setOpenTr6(false);
  };
  const handleChangeTr6 = (event) => {
    setTr6(event.target.value);
    store.setTr6A(event.target.value);
  };

  // ----- Better practice -----//

  const [bp1, setBp1] = React.useState("");
  const [OpenBp1, setOpenBp1] = React.useState(false);

  const handleOpenBp1 = () => {
    setOpenBp1(true);
  };
  const handleCloseBp1 = () => {
    setOpenBp1(false);
  };
  const handleChangeBp1 = (event) => {
    setBp1(event.target.value);
    store.setBp1A(event.target.value);
  };

  const [bp2, setBp2] = React.useState("");
  const [OpenBp2, setOpenBp2] = React.useState(false);

  const handleOpenBp2 = () => {
    setOpenBp2(true);
  };
  const handleCloseBp2 = () => {
    setOpenBp2(false);
  };
  const handleChangeBp2 = (event) => {
    setBp2(event.target.value);
    store.setBp2A(event.target.value);
  };

  const [bp3, setBp3] = React.useState("");
  const [OpenBp3, setOpenBp3] = React.useState(false);

  const handleOpenBp3 = () => {
    setOpenBp3(true);
  };
  const handleCloseBp3 = () => {
    setOpenBp3(false);
  };
  const handleChangeBp3 = (event) => {
    setBp3(event.target.value);
    store.setBp3A(event.target.value);
  };

  const [bp4, setBp4] = React.useState("");
  const [OpenBp4, setOpenBp4] = React.useState(false);

  const handleOpenBp4 = () => {
    setOpenBp4(true);
  };
  const handleCloseBp4 = () => {
    setOpenBp4(false);
  };
  const handleChangeBp4 = (event) => {
    setBp4(event.target.value);
    store.setBp4A(event.target.value);
  };

  const [bp5, setBp5] = React.useState("");
  const [OpenBp5, setOpenBp5] = React.useState(false);

  const handleOpenBp5 = () => {
    setOpenBp5(true);
  };
  const handleCloseBp5 = () => {
    setOpenBp2(false);
  };
  const handleChangeBp5 = (event) => {
    setBp5(event.target.value);
    store.setBp5A(event.target.value);
  };

  const [bp6, setBp6] = React.useState("");
  const [OpenBp6, setOpenBp6] = React.useState(false);

  const handleOpenBp6 = () => {
    setOpenBp6(true);
  };
  const handleCloseBp6 = () => {
    setOpenBp6(false);
  };
  const handleChangeBp6 = (event) => {
    setBp6(event.target.value);
    store.setBp6A(event.target.value);
  };

  // ------ Comments------//
  const [comment1, setComment1] = React.useState("");
  const [OpenComment1, setOpenComment1] = React.useState(false);
  const handleOpenComment1 = () => {
    setOpenComment1(true);
  };
  const handleCloseComment1 = () => {
    setOpenComment1(false);
  };
  const handleChangeComment1 = (event) => {
    setComment1(event.target.value);
    store.setComment1A(event.target.value);
  };

  const [comment2, setComment2] = React.useState("");
  const [OpenComment2, setOpenComment2] = React.useState(false);

  const handleOpenComment2 = () => {
    setOpenComment2(true);
  };
  const handleCloseComment2 = () => {
    setOpenComment2(false);
  };
  const handleChangeComment2 = (event) => {
    setComment2(event.target.value);
    store.setComment2A(event.target.value);
  };

  const [comment3, setComment3] = React.useState("");
  const [OpenComment3, setOpenComment3] = React.useState(false);

  const handleOpenComment3 = () => {
    setOpenComment3(true);
  };
  const handleCloseComment3 = () => {
    setOpenComment3(false);
  };
  const handleChangeComment3 = (event) => {
    setComment3(event.target.value);
    store.setComment3A(event.target.value);
  };

  const [comment4, setComment4] = React.useState("");
  const [OpenComment4, setOpenComment4] = React.useState(false);

  const handleOpenComment4 = () => {
    setOpenComment4(true);
  };
  const handleCloseComment4 = () => {
    setOpenComment4(false);
  };
  const handleChangeComment4 = (event) => {
    setComment4(event.target.value);
    store.setComment4A(event.target.value);
  };

  const [comment5, setComment5] = React.useState("");
  const [OpenComment5, setOpenComment5] = React.useState(false);

  const handleOpenComment5 = () => {
    setOpenComment5(true);
  };
  const handleCloseComment5 = () => {
    setOpenComment5(false);
  };
  const handleChangeComment5 = (event) => {
    setComment5(event.target.value);
    store.setComment5A(event.target.value);
  };

  const [comment6, setComment6] = React.useState("");
  const [OpenComment6, setOpenComment6] = React.useState(false);

  const handleOpenComment6 = () => {
    setOpenComment6(true);
  };
  const handleCloseComment6 = () => {
    setOpenComment6(false);
  };
  const handleChangeComment6 = (event) => {
    setComment6(event.target.value);
    store.setComment6A(event.target.value);
  };

  /// approver's comments ///

  // ------ Comments------//
  const [Apcomment1, setApComment1] = React.useState("");

  const handleChangeApComment1 = (event) => {
    setApComment1(event.target.value);
    store.setApComment1A(event.target.value);
  };

  const [Apcomment2, setApComment2] = React.useState("");

  const handleChangeApComment2 = (event) => {
    setApComment2(event.target.value);
    store.setApComment2A(event.target.value);
  };

  const [Apcomment3, setApComment3] = React.useState("");

  const handleChangeApComment3 = (event) => {
    setApComment3(event.target.value);
    store.setApComment3A(event.target.value);
  };

  const [Apcomment4, setApComment4] = React.useState("");

  const handleChangeApComment4 = (event) => {
    setApComment4(event.target.value);
    store.setApComment4A(event.target.value);
  };

  const [Apcomment5, setApComment5] = React.useState("");

  const handleChangeApComment5 = (event) => {
    setApComment5(event.target.value);
    store.setApComment5A(event.target.value);
  };

  const [Apcomment6, setApComment6] = React.useState("");

  const handleChangeApComment6 = (event) => {
    setApComment6(event.target.value);
    store.setApComment6A(event.target.value);
  };

  //const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (store.userKey !== "") {
      //alert("wrong");
      setProgress("block");
      // getData();
    }
  }, []);
  return useObserver(() => (
    <div id="Element1" className={classes.root} style={{ marginTop: "12%" }}>
      {/* <Stepper /> */}
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Governance, Leadership & Accountability" img={false} />
      </div>
      <div style={{ display: progress, marginLeft: "20%" }}>
        {" "}
        {/* <Progress /> */}
      </div>
      <div style={{ marginTop: "10%" }}></div>
      <div style={{ marginBottom: "3%" }}>
        <h3 className="text-center">
          {" "}
          Governance, Leadership & Accountability
        </h3>
      </div>{" "}
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">Senior Leadership Commitment</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                Leadership commitment for comprehensive privacy management is
                not demonstrated. No support for or awareness of privacy
                initiatives. The privacy programme of work is not adequately
                resourced.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Leadership considers privacy as issues or breaches arise.
                Managers are aware of privacy initiatives within their business
                units. Management is aware of the resources and core skills
                needed to effectively deliver a privacy programme of work, but
                further resourcing is required
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Leadership is aware of the agency’s privacy management and
                actively promotes it e.g. through information available on the
                agency’s intranet, or regular agency-wide communications.
                Dedicated resources are allocated to deliver the privacy
                programme of work.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Leadership takes a proactive and integrated approach to leading
                privacy management. Resourcing for privacy is considered at a
                strategic level within the agency.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Leadership works collectively to seek innovative ways to
                continuously improve privacy management. Resources are deployed
                strategically to support the ongoing integration of the privacy
                framework into the business strategy. Information obtained
                through risk assessment or review of response to any identified
                breach is used to inform deployment of resources.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS1}
            onClose={handleCloseCS1}
            onOpen={handleOpenCS1}
            value={store.CS1A}
            onChange={handleChangeCS1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr1}
            onClose={handleCloseTr1}
            onOpen={handleOpenTr1}
            value={store.tr1A}
            onChange={handleChangeTr1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            OpenCS1={OpenBp1}
            onClose={handleCloseBp1}
            onOpenCS1={handleOpenBp1}
            value={store.bp1A}
            onChange={handleChangeBp1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.comment1A}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.Apcomment1A}</p>
        </Box>
      </Box>
      {/*  Q2  */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Governance, Leadership & Accountability" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">Governance Oversight</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                Limited or no reporting and access to the governance board
                /committee(s) / executive leadership team.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Established reporting lines to the governance board /
                committee(s), and access to the executive leadership team exist,
                but these are used largely in response to specific issues.
                Discussions on privacy are included in governance board /
                committee(s) / executive leadership team meetings, but these are
                largely in response to specific issues.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Governance board / committee(s) / executive leadership team
                receive regular updates on the privacy programme. Governance
                meetings include discussions on privacy issues and the
                effectiveness of the privacy programme.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Management proactively reports to the governance board /
                committee(s) / executive leadership team, to inform them of
                significant changes to the privacy risk profile. Governance
                meetings include discussions on the strategic direction of the
                privacy programme and privacy as an integral aspect of risk
                management.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Functional oversight of the privacy function and programme is
                included in the risk management organisational structure and is
                shown by setting policies and monitoring compliance. The
                governance board / committee(s) / executive leadership team
                actively informs business performance and improvements on
                privacy management.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS2}
            onClose={handleCloseCS2}
            onOpen={handleOpenCS2}
            value={store.CS2A}
            onChange={handleChangeCS2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr2}
            onClose={handleCloseTr2}
            onOpen={handleOpenTr2}
            value={store.tr2A}
            onChange={handleChangeTr2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp2}
            onClose={handleCloseBp2}
            onOpen={handleOpenBp2}
            value={store.bp2A}
            onChange={handleChangeBp2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.comment2A}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.Apcomment2A}</p>
        </Box>
      </Box>
      {/*  Q3  */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Governance, Leadership & Accountability" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">
            Management Structure, Roles and Responsibilities
          </h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                Limited or no defined reporting structures for privacy
                management, issues, or improvement. No senior executive
                responsible for privacy management.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Some existing line management and reporting structures are in
                place for privacy management, issues, and improvement. A senior
                executive is formally responsible for privacy management, but
                has limited oversight or involvement in the privacy programme.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Formal and structured line management and governance over
                privacy management exists. There is formal responsibility and
                accountability for each element of the privacy programme and for
                the implementation of the Information Privacy Principles into
                the business. A senior executive is authorised to make decisions
                on privacy management, including the programme's content,
                approach, and resourcing. This person is accountable for privacy
                management and maintains oversight of the agency’s privacy
                management.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Governance board / committee(s) / executive leadership team has
                a governance role in privacy (ie strategic decision-making and
                approval of policy) rather than undertaking day-to-day
                management of the programme. Senior management views privacy
                assessment and management as integral to their role.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Ongoing, regular, and formal discussions on privacy occur
                between the governance board / committee(s), and the executive
                management and senior management levels. A formal privacy
                management structure covering the entire agency is in place.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS3}
            onClose={handleCloseCS3}
            onOpen={handleOpenCS3}
            value={store.CS3A}
            onChange={handleChangeCS3}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr3}
            onClose={handleCloseTr3}
            onOpen={handleOpenTr3}
            value={store.tr3A}
            onChange={handleChangeTr3}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp3}
            onClose={handleCloseBp3}
            onOpen={handleOpenBp3}
            value={store.bp3A}
            onChange={handleChangeBp3}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.comment3A}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.Apcomment3A}</p>
        </Box>
      </Box>
      {/*  Q4  */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Governance, Leadership & Accountability" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">Privacy Officer</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                Non-existent or undocumented privacy officer role, and undefined
                privacy leadership structure. No centralised oversight or
                specific accountability for ensuring the privacy principles are
                adhered to. Where a privacy management function exists,
                communication between the privacy officer / privacy management
                function and other parts of the agency is limited.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Privacy officer / privacy management function responsibilities
                are documented and the role is known throughout the agency.
                Privacy officer’s role mainly consists of meeting the
                requirements of the Privacy Act 1993, eg dealing with privacy
                disclosures and complaints. Communication between privacy
                officer and other parts of the agency largely occurs in response
                to breaches.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Privacy officer / privacy management function oversees a privacy
                work programme and maintains central oversight of privacy
                initiatives and activities on an agency-wide basis. Privacy
                officer / privacy management function communicates regularly
                with other ‘second-line-of-defence’ functions (eg records
                management, security, risk management).
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Privacy officer / privacy management function contributes to
                business process design and risk assessment. Privacy officer /
                privacy management function has established ongoing
                communication and clear alignment (where applicable) with the
                work programmes of other second-line-of-defence functions.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Privacy officer / privacy management function is responsible for
                the operational and strategic elements of privacy management on
                an agency-wide basis. It also has the capability, capacity, and
                authority to introduce and implement privacy management better
                practices. Second-line-of-defence function proactively
                approaches the privacy function for input to their work
                programmes. This communication is open, honest, and ongoing.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS4}
            onClose={handleCloseCS4}
            onOpen={handleOpenCS4}
            value={store.CS4A}
            onChange={handleChangeCS4}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr4}
            onClose={handleCloseTr4}
            onOpen={handleOpenTr4}
            value={store.tr4A}
            onChange={handleChangeTr4}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp4}
            onClose={handleCloseBp4}
            onOpen={handleOpenBp4}
            value={store.bp4A}
            onChange={handleChangeBp4}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.comment4A}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.Apcomment4A}</p>
        </Box>
      </Box>
      {/* Q5 */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Governance, Leadership & Accountability" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">Privacy Strategy</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                No consideration for or integration of privacy management in
                strategy planning. No defined tolerance levels in relation to
                individual privacy risks. No consideration for privacy strategy
                by the governance board and/or committee(s), and/or the
                executive leadership team.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Privacy strategy and management programmes are in place,
                including consideration of the corporate level risk appetite. No
                meaningful definition of privacy risk appetite other than what
                is set as the corporate strategy. Privacy strategy is considered
                and approved by the governance board / committee(s) / executive
                leadership team.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Privacy strategy and management programme are reviewed and
                revised regularly to confirm their ongoing suitability with the
                internal and external environments (including regulatory
                requirements). Defined privacy risk appetite exists, which is
                used to assess resource requirements and timelines for dealing
                with privacy risks at an operational level. Privacy strategy
                formally adopted by the governance board / committee(s) /
                executive leadership team exists.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Results of privacy risk assessments are used to inform and
                update the privacy strategy and plan. Governance board /
                committee(s) / executive leadership team know what risk appetite
                means in relation to privacy and to the objectives and
                strategies set by executive management.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Privacy considerations are integrated into the overall business
                strategy, with risk appetite considered in future strategic
                options. Context for setting the privacy risk appetite is
                clearly defined and understood by the governance board /
                committee(s) / executive leadership team. The context includes
                external and internal trends and expectations. Privacy risk
                appetite is regularly reviewed, taking into account this
                context. Information obtained through risk assessment or review
                of response to any identified breach is used to inform updates
                to the privacy strategy.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS5}
            onClose={handleCloseCS5}
            onOpen={handleOpenCS5}
            value={store.CS5A}
            onChange={handleChangeCS5}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr5}
            onClose={handleCloseTr5}
            onOpen={handleOpenTr5}
            value={store.tr5A}
            onChange={handleChangeTr5}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp5}
            onClose={handleCloseBp5}
            onOpen={handleOpenBp5}
            value={store.bp5A}
            onChange={handleChangeBp5}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.comment5A}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.Apcomment5A}</p>
        </Box>
      </Box>
      {/* Q6 */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="" color="white" />
        <Header content="Governance, Leadership & Accountability" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">Accountability</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                Unclear or undocumented accountability for privacy management.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Privacy management is allocated to specialist individuals who
                are seen as accountable for privacy management within siloed
                areas of the agency. While the role may be included in a
                person’s job description, it is only considered meaningful when
                a significant breach is identified.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Accountability of individual employees in relation to privacy
                management is documented, known, and accepted. Processes are in
                place to evaluate staff performance against defined and
                communicated expectations.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                All staff and contractors are responsible for ensuring the
                principles of privacy management are adhered to. This
                responsibility is an integral part of their performance
                assessment and management.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                All staff and contractors are responsible for privacy management
                and consider it normal practice to identify opportunities for
                improvement.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS6}
            onClose={handleCloseCS6}
            onOpen={handleOpenCS6}
            value={store.CS6A}
            onChange={handleChangeCS6}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr6}
            onClose={handleCloseTr6}
            onOpen={handleOpenTr6}
            value={store.tr6A}
            onChange={handleChangeTr6}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp6}
            onClose={handleCloseBp6}
            onOpen={handleOpenBp6}
            value={store.bp6A}
            onChange={handleChangeBp6}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.comment6A}</p>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>

          <p className={classes.formControl}>{store.Apcomment6A}</p>
        </Box>
      </Box>
      <div className="float-left" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          style={{ width: "100px", backgroundColor: "#3f51b5" }}
          onClick={() => {
            postData(store, 0, 0);
            store.setSnackOpen(true);
            store.setApprove(0);
          }}
          className="btn btn-primary"
        >
          Save
        </button>
      </div>
      <div className="float-right" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          onClick={() => {
            // store.handleNext();
            props.history.push("/e2");
          }}
          style={{ backgroundColor: "#3f51b5", width: "100px" }}
          className="btn btn-primary"
        >
          Next
        </button>
      </div>
    </div>
  ));
}
