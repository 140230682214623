import React, { useContext, useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { StoreContext } from "../store/store";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import firebase from "firebase";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import SuccessSnack from "./SuccessSnack";
import postData from "../functions/postData";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Spider5 from "./spider5";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    marginLeft: "11%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
}));

const AccordionSummary = withStyles({
  content: {
    flexGrow: 0,
  },
})(MuiAccordionSummary);

export default function Element5(props) {
  const classes = useStyles();
  const store = useContext(StoreContext);
  if (store.userKey === "" && store.decide === 0) {
    // alert(localStorage.getItem("user"));

    props.history.push("/");
  }

  function getData() {
    var Config = {
      apiKey: "AIzaSyADTVCa2hUtYT1iYe0W8U-NZY6NyFQJacQ",
      authDomain: "pma-survey.firebaseapp.com",
      databaseURL: "https://pma-survey-default-rtdb.firebaseio.com",
      projectId: "pma-survey",
      storageBucket: "pma-survey.appspot.com",
      messagingSenderId: "531631815606",
      appId: "1:531631815606:web:ae74c86ce905bddc95df17",
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(Config);
    } else {
      firebase.app(); // if already initialized, use that one
    }

    console.log(localStorage.getItem("uid"));
    let user_data = {};
    let user_data1 = {};
    let user;
    if (store.decide === 1) {
      user = store.userToBeApproved;
      store.setReadOnly(false);
      store.setReadOnlyUser(true);
    } else {
      user = store.userKey;
      store.setReadOnly(false);
      store.setReadOnlyUser(true);
    }
    firebase
      .database()
      .ref()
      .child("users")
      .orderByChild("userKey")
      .equalTo(user)
      .on("value", function (snapshot) {
        user_data = snapshot.val();

        for (let i in user_data) {
          user_data1 = user_data[i];
          break;
        }
        console.log(user_data1);

        if (user_data1.user_email !== store.user) {
          console.log("inside if");
        } else {
          // --- element 1---//
          store.setApprove(user_data1.isApproved);
          store.setAppr(user_data1.appr);

          store.setCS1A(user_data1.CS1A);
          store.setCS2A(user_data1.CS2A);
          store.setCS3A(user_data1.CS3A);
          store.setCS4A(user_data1.CS4A);
          store.setCS5A(user_data1.CS5A);
          store.setCS6A(user_data1.CS6A);

          // ------------------- //

          store.setTr1A(user_data1.tr1A);
          store.setTr2A(user_data1.tr2A);
          store.setTr3A(user_data1.tr3A);
          store.setTr4A(user_data1.tr4A);
          store.setTr5A(user_data1.tr5A);
          store.setTr6A(user_data1.tr6A);

          //------------------------------------//

          store.setBp1A(user_data1.bp1A);
          store.setBp2A(user_data1.bp2A);
          store.setBp3A(user_data1.bp3A);
          store.setBp4A(user_data1.bp4A);
          store.setBp5A(user_data1.bp5A);
          store.setBp6A(user_data1.bp6A);

          // ----------------------------- //

          store.setComment1A(user_data1.comment1A);
          store.setComment2A(user_data1.comment2A);
          store.setComment3A(user_data1.comment3A);
          store.setComment4A(user_data1.comment4A);
          store.setComment5A(user_data1.comment5A);
          store.setComment6A(user_data1.comment6A);

          /// --------------------------------//
          store.setApComment1A(user_data1.Apcomment1A);
          store.setApComment2A(user_data1.Apcomment2A);
          store.setApComment3A(user_data1.Apcomment3A);
          store.setApComment4A(user_data1.Apcomment4A);
          store.setApComment5A(user_data1.Apcomment5A);
          store.setApComment6A(user_data1.Apcomment6A);

          // -------- element 2 ------//

          store.setCS1B(user_data1.CS1B);
          store.setCS2B(user_data1.CS2B);
          // ------------------- //

          store.setTr1B(user_data1.tr1B);
          store.setTr2B(user_data1.tr2B);

          //------------------------------------//

          store.setBp1B(user_data1.bp1B);
          store.setBp2B(user_data1.bp2B);
          // ----------------------------- //

          store.setComment1B(user_data1.comment1B);
          store.setComment2B(user_data1.comment2B);

          //---------------------------///

          store.setApComment1B(user_data1.Apcomment1B);
          store.setApComment2B(user_data1.Apcomment2B);

          // ------element 3 ----//

          store.setCS1C(user_data1.CS1C);
          store.setCS2C(user_data1.CS2C);
          // ------------------- //

          store.setTr1C(user_data1.tr1C);
          store.setTr2C(user_data1.tr2C);

          //------------------------------------//

          store.setBp1C(user_data1.bp1C);
          store.setBp2C(user_data1.bp2C);
          // ----------------------------- //

          store.setComment1C(user_data1.comment1C);
          store.setComment2C(user_data1.comment2C);

          //----------------------------'//
          store.setApComment1C(user_data1.Apcomment1C);
          store.setApComment2C(user_data1.Apcomment2C);

          // --------- element 4 ------ //

          store.setCS1D(user_data1.CS1D);
          store.setCS2D(user_data1.CS2D);
          store.setCS3D(user_data1.CS3D);
          // ------------------- //

          store.setTr1D(user_data1.tr1D);
          store.setTr2D(user_data1.tr2D);
          store.setTr3D(user_data1.tr3D);

          //------------------------------------//

          store.setBp1D(user_data1.bp1D);
          store.setBp2D(user_data1.bp2D);
          store.setBp3D(user_data1.bp3D);
          // ----------------------------- //

          store.setComment1D(user_data1.comment1D);
          store.setComment2D(user_data1.comment2D);
          store.setComment3D(user_data1.comment3D);

          //---------------//
          store.setApComment1D(user_data1.Apcomment1D);
          store.setApComment2D(user_data1.Apcomment2D);
          store.setApComment3D(user_data1.Apcomment3D);

          //---- ELEMENT 5 ----//

          store.setCS1E(user_data1.CS1E);
          store.setCS2E(user_data1.CS2E);
          store.setCS3E(user_data1.CS3E);
          // ------------------- //

          store.setTr1E(user_data1.tr1E);
          store.setTr2E(user_data1.tr2E);
          store.setTr3E(user_data1.tr3E);

          //------------------------------------//

          store.setBp1E(user_data1.bp1E);
          store.setBp2E(user_data1.bp2E);
          store.setBp3E(user_data1.bp3E);
          // ----------------------------- //

          store.setComment1E(user_data1.comment1E);
          store.setComment2E(user_data1.comment2E);
          store.setComment3E(user_data1.comment3E);

          store.setApComment1E(user_data1.Apcomment1E);
          store.setApComment2E(user_data1.Apcomment2E);
          store.setApComment3E(user_data1.Apcomment3E);

          // ------- Element 6-----//

          store.setCS1F(user_data1.CS1F);
          store.setCS2F(user_data1.CS2F);
          store.setCS3F(user_data1.CS3F);
          store.setCS4F(user_data1.CS4F);
          // ------------------- //

          store.setTr1F(user_data1.tr1F);
          store.setTr2F(user_data1.tr2F);
          store.setTr3F(user_data1.tr3F);
          store.setTr4F(user_data1.tr4F);
          //------------------------------------//

          store.setBp1F(user_data1.bp1F);
          store.setBp2F(user_data1.bp2F);
          store.setBp3F(user_data1.bp3F);
          store.setBp4F(user_data1.bp4F);
          // ----------------------------- //

          store.setComment1F(user_data1.comment1F);
          store.setComment2F(user_data1.comment2F);
          store.setComment3F(user_data1.comment3F);
          store.setComment4F(user_data1.comment4F);

          /// ------------------ ///
          store.setApComment1F(user_data1.Apcomment1F);
          store.setApComment2F(user_data1.Apcomment2F);
          store.setApComment3F(user_data1.Apcomment3F);
          store.setApComment4F(user_data1.Apcomment4F);
          // ---- Element 7 ---- //

          store.setCS1G(user_data1.CS1G);
          store.setCS2G(user_data1.CS2G);
          store.setCS3G(user_data1.CS3G);
          store.setCS4G(user_data1.CS4G);
          store.setCS5G(user_data1.CS5G);
          store.setCS6G(user_data1.CS6G);

          // ------------------- //

          store.setTr1G(user_data1.tr1G);
          store.setTr2G(user_data1.tr2G);
          store.setTr3G(user_data1.tr3G);
          store.setTr4G(user_data1.tr4G);
          store.setTr5G(user_data1.tr5G);
          store.setTr6G(user_data1.tr6G);
          //------------------------------------//

          store.setBp1G(user_data1.bp1G);
          store.setBp2G(user_data1.bp2G);
          store.setBp3G(user_data1.bp3G);
          store.setBp4G(user_data1.bp4G);
          store.setBp5G(user_data1.bp5G);
          store.setBp6G(user_data1.bp6G);
          // ----------------------------- //

          store.setComment1G(user_data1.comment1G);
          store.setComment2G(user_data1.comment2G);
          store.setComment3G(user_data1.comment3G);
          store.setComment4G(user_data1.comment4G);
          store.setComment5G(user_data1.comment5G);
          store.setComment6G(user_data1.comment6G);

          // ----------------------//
          store.setApComment1G(user_data1.Apcomment1G);
          store.setApComment2G(user_data1.Apcomment2G);
          store.setApComment3G(user_data1.Apcomment3G);
          store.setApComment4G(user_data1.Apcomment4G);
          store.setApComment5G(user_data1.Apcomment5G);
          store.setApComment6G(user_data1.Apcomment6G);
          //------- element 8 -----//

          store.setCS1H(user_data1.CS1H);
          store.setCS2H(user_data1.CS2H);
          store.setCS3H(user_data1.CS3H);
          store.setCS4H(user_data1.CS4H);
          store.setCS5H(user_data1.CS5H);
          store.setCS6H(user_data1.CS6H);
          store.setCS7H(user_data1.CS7H);
          // ------------------- //

          store.setTr1H(user_data1.tr1H);
          store.setTr2H(user_data1.tr2H);
          store.setTr3H(user_data1.tr3H);
          store.setTr4H(user_data1.tr4H);
          store.setTr5H(user_data1.tr5H);
          store.setTr6H(user_data1.tr6H);
          store.setTr7H(user_data1.tr7H);
          //------------------------------------//

          store.setBp1H(user_data1.bp1H);
          store.setBp2H(user_data1.bp2H);
          store.setBp3H(user_data1.bp3H);
          store.setBp4H(user_data1.bp4H);
          store.setBp5H(user_data1.bp5H);
          store.setBp6H(user_data1.bp6H);
          store.setBp7H(user_data1.bp7H);
          // ----------------------------- //

          store.setComment1H(user_data1.comment1H);
          store.setComment2H(user_data1.comment2H);
          store.setComment3H(user_data1.comment3H);
          store.setComment4H(user_data1.comment4H);
          store.setComment5H(user_data1.comment5H);
          store.setComment6H(user_data1.comment6H);
          store.setComment7H(user_data1.comment7H);

          // -----------------------------//
          store.setApComment1H(user_data1.Apcomment1H);
          store.setApComment2H(user_data1.Apcomment2H);
          store.setApComment3H(user_data1.Apcomment3H);
          store.setApComment4H(user_data1.Apcomment4H);
          store.setApComment5H(user_data1.Apcomment5H);
          store.setApComment6H(user_data1.Apcomment6H);
          store.setApComment7H(user_data1.Apcomment7H);

          ///------ element 9 -----///
          store.setCS1I(user_data1.CS1I);
          store.setCS2I(user_data1.CS2I);
          store.setTr1I(user_data1.tr1I);
          store.setTr2I(user_data1.tr2I);
          //------------------------------------//

          store.setBp1I(user_data1.bp1I);
          store.setBp2I(user_data1.bp2I);
          // ----------------------------- //

          store.setComment1I(user_data1.comment1I);

          store.setComment2I(user_data1.comment2I);

          //------//

          store.setApComment1G(user_data1.Apcomment1I);
          store.setApComment2G(user_data1.Apcomment2I);
        }
      }); //console.log("abcd"+messagesRef)
  }
  // ---- Current State -----//

  const [CS1, setCS1] = React.useState("");
  const [OpenCS1, setOpenCS1] = React.useState(false);

  const handleChangeCS1 = (event) => {
    setCS1(event.target.value);
    store.setCS1E(event.target.value);
  };
  const handleCloseCS1 = () => {
    setOpenCS1(false);
  };
  const handleOpenCS1 = () => {
    setOpenCS1(true);
  };

  const [CS2, setCS2] = React.useState("");
  const [OpenCS2, setOpenCS2] = React.useState(false);

  const handleChangeCS2 = (event) => {
    setCS2(event.target.value);
    store.setCS2E(event.target.value);
  };
  const handleCloseCS2 = () => {
    setOpenCS2(false);
  };
  const handleOpenCS2 = () => {
    setOpenCS2(true);
  };

  const [CS3, setCS3] = React.useState("");
  const [OpenCS3, setOpenCS3] = React.useState(false);

  const handleChangeCS3 = (event) => {
    setCS3(event.target.value);
    store.setCS3E(event.target.value);
  };
  const handleCloseCS3 = () => {
    setOpenCS3(false);
  };
  const handleOpenCS3 = () => {
    setOpenCS3(true);
  };

  // ------ Target ------//

  const [tr1, setTr1] = React.useState("");
  const [OpenTr1, setOpenTr1] = React.useState(false);

  const handleOpenTr1 = () => {
    setOpenTr1(true);
  };
  const handleCloseTr1 = () => {
    setOpenTr1(false);
  };
  const handleChangeTr1 = (event) => {
    setTr1(event.target.value);
    store.setTr1E(event.target.value);
  };

  const [tr2, setTr2] = React.useState("");
  const [OpenTr2, setOpenTr2] = React.useState(false);

  const handleOpenTr2 = () => {
    setOpenTr2(true);
  };
  const handleCloseTr2 = () => {
    setOpenTr2(false);
  };
  const handleChangeTr2 = (event) => {
    setTr2(event.target.value);
    store.setTr2E(event.target.value);
  };

  const [tr3, setTr3] = React.useState("");
  const [OpenTr3, setOpenTr3] = React.useState(false);

  const handleOpenTr3 = () => {
    setOpenTr3(true);
  };
  const handleCloseTr3 = () => {
    setOpenTr3(false);
  };
  const handleChangeTr3 = (event) => {
    setTr3(event.target.value);
    store.setTr3E(event.target.value);
  };

  // ----- Better practice -----//

  const [bp1, setBp1] = React.useState("");
  const [OpenBp1, setOpenBp1] = React.useState(false);

  const handleOpenBp1 = () => {
    setOpenBp1(true);
  };
  const handleCloseBp1 = () => {
    setOpenBp1(false);
  };
  const handleChangeBp1 = (event) => {
    setBp1(event.target.value);
    store.setBp1E(event.target.value);
  };

  const [bp2, setBp2] = React.useState("");
  const [OpenBp2, setOpenBp2] = React.useState(false);

  const handleOpenBp2 = () => {
    setOpenBp2(true);
  };
  const handleCloseBp2 = () => {
    setOpenBp2(false);
  };
  const handleChangeBp2 = (event) => {
    setBp2(event.target.value);
    store.setBp2E(event.target.value);
  };

  const [bp3, setBp3] = React.useState("");
  const [OpenBp3, setOpenBp3] = React.useState(false);

  const handleOpenBp3 = () => {
    setOpenBp3(true);
  };
  const handleCloseBp3 = () => {
    setOpenBp3(false);
  };
  const handleChangeBp3 = (event) => {
    setBp3(event.target.value);
    store.setBp3E(event.target.value);
  };

  // ------ Comments------//
  const [comment1, setComment1] = React.useState("");
  const [OpenComment1, setOpenComment1] = React.useState(false);
  const handleOpenComment1 = () => {
    setOpenComment1(true);
  };
  const handleCloseComment1 = () => {
    setOpenComment1(false);
  };
  const handleChangeComment1 = (event) => {
    setComment1(event.target.value);
    store.setComment1E(event.target.value);
  };

  const [comment2, setComment2] = React.useState("");
  const [OpenComment2, setOpenComment2] = React.useState(false);

  const handleOpenComment2 = () => {
    setOpenComment2(true);
  };
  const handleCloseComment2 = () => {
    setOpenComment2(false);
  };
  const handleChangeComment2 = (event) => {
    setComment2(event.target.value);

    store.setComment2E(event.target.value);
  };

  const [comment3, setComment3] = React.useState("");
  const [OpenComment3, setOpenComment3] = React.useState(false);

  const handleOpenComment3 = () => {
    setOpenComment3(true);
  };
  const handleCloseComment3 = () => {
    setOpenComment3(false);
  };
  const handleChangeComment3 = (event) => {
    setComment3(event.target.value);

    store.setComment3E(event.target.value);
  };

  /// approver's comments ///

  // ------ Comments------//
  const [Apcomment1, setApComment1] = React.useState("");

  const handleChangeApComment1 = (event) => {
    setApComment1(event.target.value);
    store.setApComment1E(event.target.value);
  };

  const [Apcomment2, setApComment2] = React.useState("");

  const handleChangeApComment2 = (event) => {
    setApComment2(event.target.value);
    store.setApComment2E(event.target.value);
  };

  const [Apcomment3, setApComment3] = React.useState("");

  const handleChangeApComment3 = (event) => {
    setApComment3(event.target.value);
    store.setApComment3E(event.target.value);
  };

  useEffect(() => window.scrollTo(0, 0), []);
  return useObserver(() => (
    <div id="Element5" className={classes.root} style={{ marginTop: "8%" }}>
      <div style={{ marginTop: store.header === "block" ? "50%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="Privace Risk Assessment" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <div style={{ marginBottom: "3%" }}>
        <h3 className="text-center">Privacy Risk Assessment</h3>
      </div>{" "}
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">
            Integration with Enterprise Risk Management
          </h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                No relationship between privacy function and wider
                risk-management function..
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Limited interaction between the privacy function and the risk
                function to mitigate specific identified risks (i.e.
                conversations only take place to identify future risks based on
                existing issues identified)
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Privacy risk assessment processes generally align with the
                agency’s risk-management approach.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Privacy risks are considered within the agency’s enterprise risk
                management programme. Privacy risks and issues are owned by the
                business units.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Considerations of privacy risk are firmly in place within the
                agency’s enterprise risk management function. The risk
                management function considers privacy risk identification as a
                business-as-usual activity.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS1}
            onClose={handleCloseCS1}
            onOpen={handleOpenCS1}
            value={store.CS1E}
            onChange={handleChangeCS1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr1}
            onClose={handleCloseTr1}
            onOpen={handleOpenTr1}
            value={store.tr1E}
            onChange={handleChangeTr1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            OpenCS1={OpenBp1}
            onClose={handleCloseBp1}
            onOpenCS1={handleOpenBp1}
            value={store.bp1E}
            onChange={handleChangeBp1}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>
          <textarea
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenComment1}
            onClose={handleCloseComment1}
            onOpen={handleOpenComment1}
            value={store.comment1E}
            onChange={handleChangeComment1}
            className={classes.formControl + " form-control"}
            readOnly={store.readOnly}
          ></textarea>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>
          <textarea
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            value={store.Apcomment1E}
            onChange={handleChangeApComment1}
            className={classes.formControl + " form-control"}
            readOnly={store.readOnlyUser}
          ></textarea>
        </Box>
      </Box>
      {/*  Q2  */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="Privacy Risk Assessment" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">Risk Identification and Assessment</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                No formal, structured, or consistent process for identifying and
                assessing privacy risks.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Incomplete or underdeveloped processes for privacy risk
                identification. Privacy risk management is issues-based. Regular
                or occasional risk identification and assessment is performed.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Privacy risk identification and assessment occur on a regular
                basis. However, these are often viewed as a compliance-based
                activity.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Proactive identification and assessment of privacy risks before
                issues occur, which most management and staff perceive as adding
                value.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Well defined, highly evolved, and efficient risk identification
                processes are in place, which are integrated into business
                activities across the agency. All management and staff believe
                these processes add value to the agency.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS2}
            onClose={handleCloseCS2}
            onOpen={handleOpenCS2}
            value={store.CS2E}
            onChange={handleChangeCS2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr2}
            onClose={handleCloseTr2}
            onOpen={handleOpenTr2}
            value={store.tr2E}
            onChange={handleChangeTr2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp2}
            onClose={handleCloseBp2}
            onOpen={handleOpenBp2}
            value={store.bp2E}
            onChange={handleChangeBp2}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>
          <textarea
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenComment2}
            onClose={handleCloseComment2}
            onOpen={handleOpenComment2}
            value={store.comment2E}
            onChange={handleChangeComment2}
            className={classes.formControl + " form-control"}
            readOnly={store.readOnly}
          ></textarea>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>
          <textarea
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            value={store.Apcomment2E}
            onChange={handleChangeApComment2}
            className={classes.formControl + " form-control"}
            readOnly={store.readOnlyUser}
          ></textarea>
        </Box>
      </Box>
      {/*  Q3  */}
      <div style={{ marginTop: store.header === "block" ? "90%" : "0%" }}></div>
      <div style={{ display: store.header }}>
        {" "}
        <Header content="Privacy Risk Assessment" />
      </div>
      <div style={{ marginTop: store.header === "block" ? "10%" : "0%" }}></div>
      <Accordion defaultExpanded={store.expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#00000019" }}
        >
          <h5 className="text-center">Privacy Risk Monitoring and Reporting</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-md-2">
              <h5>1- Ad Hoc</h5>
              <p>
                No formal process for monitoring privacy risks. No formal
                process for reporting on privacy risks and mitigations.
              </p>
            </div>
            <div class="col-md-3">
              <h5>2- Developing</h5>
              <p>
                Privacy risks are monitored on a siloed basis in business lines,
                with little if any cross-functional interaction. Privacy risk
                reporting is largely by exception and in response to identified
                issues.
              </p>
            </div>
            <div class="col-md-2">
              <h5>3- Defined</h5>
              <p>
                Privacy risks are monitored mainly on an operational level, with
                some information held at a central level regarding external
                trends and emerging risk areas. Agency reports on privacy risks
                proactively.
              </p>
            </div>

            <div class="col-md-2">
              <h5>4- Embedded</h5>
              <p>
                Monitoring includes analysing key privacy risks; whether risk
                levels have changed, controls are applied appropriately, and
                risk management improvement requirements are being implemented.
                Defined risk management reporting requirements in place,
                integrated with business-as-usual management reporting.
              </p>
            </div>

            <div class="col-md-3">
              <h5>5- Optimised</h5>
              <p>
                Analysis / monitoring of privacy risk information is conducted
                to review the trends from historic information and the
                effectiveness of controls. The privacy function supports the
                agency in improving controls and implementing best privacy
                management practice. Reporting on privacy risks includes the key
                risk indicators. Risks are linked to the privacy strategy and
                key performance indicators, so that risk information is
                integrated into reporting on privacy management performance.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        mb={5}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Current State Assessment</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenCS3}
            onClose={handleCloseCS3}
            onOpen={handleOpenCS3}
            value={store.CS3E}
            onChange={handleChangeCS3}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Target</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenTr3}
            onClose={handleCloseTr3}
            onOpen={handleOpenTr3}
            value={store.tr3E}
            onChange={handleChangeTr3}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Better Practice</b>
          </InputLabel>
          <Select
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenBp3}
            onClose={handleCloseBp3}
            onOpen={handleOpenBp3}
            value={store.bp3E}
            onChange={handleChangeBp3}
            className={classes.formControl}
            readOnly={store.readOnly}
          >
            <MenuItem value={10}>
              <em>Please Select</em>
            </MenuItem>

            <MenuItem value={0.17}>Ad Hoc</MenuItem>
            <MenuItem value={0.33}>Developing</MenuItem>
            <MenuItem value={0.5}>Defined</MenuItem>
            <MenuItem value={0.67}>Embedded</MenuItem>
            <MenuItem value={0.83}>Optimised</MenuItem>
            <MenuItem value={0.0}>Not Applicable</MenuItem>
          </Select>
        </Box>
        <Box p={1} ml={15} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b> Comment</b>
          </InputLabel>
          <textarea
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            Open={OpenComment3}
            onClose={handleCloseComment3}
            onOpen={handleOpenComment3}
            value={store.comment3E}
            onChange={handleChangeComment3}
            className={classes.formControl + " form-control"}
            readOnly={store.readOnly}
          ></textarea>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Box p={1} bgcolor="">
          <InputLabel
            id="demo-controlled-OpenCS1-select-label"
            className={classes.formControl}
          >
            <b>Approver's Comment</b>
          </InputLabel>
          <textarea
            labelId="demo-controlled-OpenCS1-select-label"
            id="demo-controlled-OpenCS1-select"
            value={store.Apcomment3E}
            onChange={handleChangeApComment3}
            className={classes.formControl + " form-control"}
            readOnly={store.readOnlyUser}
          ></textarea>
        </Box>
      </Box>
      <div className="float-left" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          onClick={() => {
            props.history.push("/e4");
          }}
          style={{ backgroundColor: "#3f51b5", width: "100px" }}
          className="btn btn-primary"
        >
          Previous
        </button>
      </div>
      <div className="float-right" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          onClick={() => {
            props.history.push("/e6");
          }}
          style={{ backgroundColor: "#3f51b5", width: "100px" }}
          className="btn btn-primary"
        >
          Next
        </button>
      </div>
      <div className="float-right" style={{ marginLeft: 8, marginBottom: 4 }}>
        <button
          style={{ backgroundColor: "#3f51b5", width: "100px" }}
          className="btn btn-primary"
          onClick={() => {
            postData(store, 0, 0);
            store.setSnackOpen(true);
            store.setApprove(0);
            props.history.push("/e5");
          }}
        >
          Save
        </button>
      </div>
      <SuccessSnack content=" Saved" type="success" />
      <Spider5 />
    </div>
  ));
}
