import React, { useContext } from "react";
import { Radar } from "react-chartjs-2";
import { StoreContext } from "../store/store";
export default function Spider2(props) {
  const store = useContext(StoreContext);
  const data1 = {
    labels: [
      "Collection ",
      " Security",
      "Access",
      "Correction",
      "Accuracy",
      "Use and Disclosure",
      "Retention",
    ],
    datasets: [
      {
        label: "Current State Assessment",
        data: [
          store.CS1H < 10 ? parseFloat(store.CS1H) : 0,
          store.CS2H < 10 ? parseFloat(store.CS2H) : 0,
          store.CS3H < 10 ? parseFloat(store.CS3H) : 0,
          store.CS4H < 10 ? parseFloat(store.CS4H) : 0,
          store.CS5H < 10 ? parseFloat(store.CS5H) : 0,
          store.CS6H < 10 ? parseFloat(store.CS6H) : 0,
          store.CS7H < 10 ? parseFloat(store.CS7H) : 0,
        ],
        fill: true,
        backgroundColor: "rgba(3,171,87, 0.2)",
        borderColor: "rgb(3,171,87)", //"black",
        pointBackgroundColor: "rgb(3,171,87)",
        pointBorderColor: "rgb(3,171,87)", //"#fff",
        pointHoverBackgroundColor: "rgb(3,171,87)", //"#fff",
        pointHoverBorderColor: "rgb(3,171,87)",
      },
      {
        label: "Better Practice",
        data: [
          store.bp1H < 10 ? parseFloat(store.bp1H) : 0,
          store.bp2H < 10 ? parseFloat(store.bp2H) : 0,
          store.bp3H < 10 ? parseFloat(store.bp3H) : 0,
          store.bp4H < 10 ? parseFloat(store.bp4H) : 0,
          store.bp5H < 10 ? parseFloat(store.bp5H) : 0,
          store.bp6H < 10 ? parseFloat(store.bp6H) : 0,
          store.bp7H < 10 ? parseFloat(store.bp7H) : 0,
        ],
        fill: true,
        backgroundColor: "rgba(25,144,254, 0.2)",
        borderColor: "rgb(25,144,254)", //"black",
        pointBackgroundColor: "rgb(25,144,254)",
        pointBorderColor: "rgb(25,144,254)", //"#fff",
        pointHoverBackgroundColor: "rgb(25,144,254)", //"#fff",
        pointHoverBorderColor: "rgb(25,144,254)",
      },
      {
        label: "Target",
        data: [
          store.tr1H < 10 ? parseFloat(store.tr1H) : 0,
          store.tr2H < 10 ? parseFloat(store.tr2H) : 0,
          store.tr3H < 10 ? parseFloat(store.tr3H) : 0,
          store.tr4H < 10 ? parseFloat(store.tr4H) : 0,
          store.tr5H < 10 ? parseFloat(store.tr5H) : 0,
          store.tr6H < 10 ? parseFloat(store.tr6H) : 0,
          store.tr7H < 10 ? parseFloat(store.tr7H) : 0,
        ],
        fill: true,
        backgroundColor: "rgba(252,74,69, 0.2)",
        borderColor: "rgb(252,74,69)", //"black",
        pointBackgroundColor: "rgb(252,74,69)",
        pointBorderColor: "rgb(252,74,69)", //"#fff",
        pointHoverBackgroundColor: "rgb(252,74,69)", //"#fff",
        pointHoverBorderColor: "rgb(252,74,69)",
      },
    ],
  };

  const data = {
    labels: ["Current State Assessment", "Better Practice", "Target"],
    datasets: [
      {
        // label: ["# of Votes"],
        data: [
          (
            (store.CS1H < 10 ? parseFloat(store.CS1H) : 0) +
            (store.CS2H < 10 ? parseFloat(store.CS2H) : 0) +
            (store.CS3H < 10 ? parseFloat(store.CS3H) : 0) +
            (store.CS4H < 10 ? parseFloat(store.CS4H) : 0) +
            (store.CS5H < 10 ? parseFloat(store.CS5H) : 0) +
            (store.CS6H < 10 ? parseFloat(store.CS6H) : 0) +
            (store.CS7H < 10 ? parseFloat(store.CS7H) : 0)
          ).toFixed(2),
          (
            (store.bp1H < 10 ? parseFloat(store.bp1H) : 0) +
            (store.bp2H < 10 ? parseFloat(store.bp2H) : 0) +
            (store.bp3H < 10 ? parseFloat(store.bp3H) : 0) +
            (store.bp4H < 10 ? parseFloat(store.bp4H) : 0) +
            (store.bp5H < 10 ? parseFloat(store.bp5H) : 0) +
            (store.bp6H < 10 ? parseFloat(store.bp6H) : 0) +
            (store.bp7H < 10 ? parseFloat(store.bp7H) : 0)
          ).toFixed(2),
          (
            (store.tr1H < 10 ? parseFloat(store.tr1H) : 0) +
            (store.tr2H < 10 ? parseFloat(store.tr2H) : 0) +
            (store.tr3H < 10 ? parseFloat(store.tr3H) : 0) +
            (store.tr4H < 10 ? parseFloat(store.tr4H) : 0) +
            (store.tr5H < 10 ? parseFloat(store.tr5H) : 0) +
            (store.tr6H < 10 ? parseFloat(store.tr6H) : 0) +
            (store.tr7H < 10 ? parseFloat(store.tr7H) : 0)
          ).toFixed(2),
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ marginTop: "8%" }}>
      <Radar data={data1} height={150} width={300} />
    </div>
  );
}
